<div class="container-fluid">
    <div>
        <div class="row">
            <div class="col col-sm-4 col-md-4 col-lg-4">
                <div style="padding-top: 20px">
                    <h4><b>Product</b> Select</h4>
                </div>
                <div>
                    <app-products></app-products>
                </div>
            </div>
            <div class="col col-sm-8 col-md-8 col-lg-8"> 
                <div style="padding-top:20px">
                    <h4><b>Loan</b></h4>
                </div>
                <div>
                    <app-loan class="force-smol"></app-loan>
                </div>
                <div class="row row-padding">
                    <button mat-fab class="form-button-right" style="box-shadow: none;" type="submit" (click)="apply()"><i
                            class="fa fa-arrow-right" ></i></button>
                </div>
            </div>
        </div>
    </div>
</div>