<div class="container-fluid">
    <div class="card primary-card" style="height: max-content;">
        <div>
            <div class="row p-4">
                <h3 class="card-title ml-3"><b>Report</b> Generator</h3>
            </div>
            <div class="card-body col-md-12">
                <div class="form-position">
                    <div class="container-fluid container-height">
                        <div class="row">
                            <div class="card-body col-md-3">
                                <mat-form-field appearance="standard">
                                    <mat-label class="mat-label-margin">Select a Report</mat-label>
                                    <mat-select (change)="selectChangeHandler($event)" [(ngModel)]="reportName" name="reportname">
                                        <mat-option *ngFor="let report of reports" [value]="report">
                                            {{report}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="reportName == 'Letter of Decline'" class="card-body col-md-3">
                                <mat-form-field appearance="standard">
                                    <mat-label>Id Number</mat-label>
                                    <input matInput class="mat-textbox" input name="name" input type="text" [(ngModel)]="idNumber">
                                </mat-form-field>
                            </div>
                            <div class="card-body col-md-2">
                                <button title="Generate Report" mat-fab class="form-button-relative" style="box-shadow: none;" type="submit" (click)="getReport()"><i
                                        class="fa fa-file-export fa-2x" style="padding-left: 0.5rem;"></i></button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card primary-card" style="height: max-content;" [hidden]="showResults">
        <div>
            <div class="row p-4">
                <h3 class="card-title ml-3"><b>Report</b> Results</h3>
                <button title="Download Report" mat-fab class="form-button-right-relative" style="box-shadow: none;" type="submit" (click)="onDownloadClick()"><i class="fa-solid fa-download fa-2x"></i></button>
            </div>
            <div class="card-body col-md-12 ml-3">
                <div class="row row-padding">
                    <mat-form-field appearance="standard" style="width: 50%;" class="ml-3">
                        <mat-label class="mat-label-margin">Search</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ID Number, Channel, Date, Note" #input>
                        <mat-icon matSuffix><i class="fa fa-search"></i></mat-icon>
                    </mat-form-field>
                </div>
                <div class="row row-padding">
                    <div class="col-md-12">
                        <div>
                            <div>
                                <table mat-table matSort class="mat-elevation-z8" [dataSource]="dataSource" style="box-shadow: none;">
                                    <ng-container [matColumnDef]="column.DbName" *ngFor="let column of columns">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef> {{column.DisplayName}}
                                        </th>
                                        <td mat-cell *matCellDef="let prevet">
                                            <span *ngIf="column.DbName == 'zaid' || column.DbName == 'external_id'">{{maskRSAID(prevet[column.DbName])}}</span>
                                            <span *ngIf="column.DbName != 'zaid' && column.DbName != 'external_id'">{{prevet[column.DbName]}}</span>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
                                </table>
                                <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event" showFirstLastButtons aria-label="Select page">
                                </mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>