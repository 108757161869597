import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingService } from 'app/data-sharing-service';
import { PendingApprovalLoansService } from 'app/ts/services/pending-approval-loans.service';
import { environment } from 'environments/environment';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  isVisible: boolean;
  subItem: boolean;
}

// declare interface RouteInfo {
//   path: string;
//   title: string;
//   icon: string;
//   class: string;
//   subPages: any[];
// }

const version = environment.version;

export const ROUTES: RouteInfo[] = [


  //{ path: 'dashboard', title: 'Dashboard', icon: 'dashboard', class: '', isVisible: true, subItem: false },
  // { path: '', title: 'Client', icon: 'face', class: '', isVisible: true, subItem: false },
  { path: 'client-capture/:staffId/:productId', title: 'Capture Client', icon: 'group_add', class: '', isVisible: false, subItem: false },
  { path: 'update-client', title: 'Client Profile', icon: 'person', class: '', isVisible: false, subItem: false },
  { path: 'find-client', title: 'Find Client', icon: 'search', class: '', isVisible: false, subItem: true },
  { path: 'batch', title: 'Batch Upload', icon: 'cloud_upload', class: '', isVisible: true, subItem: false },
  { path: 'loan-pending-approval', title: 'Pending Approval', icon: 'timer', class: '', isVisible: true, subItem: false },
  //{ path: 'reports', title: 'Reports', icon: 'folder_special', class: '', isVisible: true, subItem: false },
  { path: 'loans', title: 'Loans', icon: 'description', class: '', isVisible: true, subItem: false },
  { path: 'about', title: 'About Us', icon: 'infotwotone', class: '', isVisible: true, subItem: false },
  { path: 'cancel', title: 'Cancel Application', icon: 'cancel', class: '', isVisible: true, subItem: false },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterViewChecked {
  menuItems: any[];
  hasNotifications: boolean;
  version: string;
  toggleValue: number = 1;
  loanCount: number = 0;
  showMenuItem: boolean = false;
  clientProfile: any;
  pendingLoansCount: number;
  pendingLoans:boolean = false;
  

  constructor(
    private _pendingLoanService: PendingApprovalLoansService,
    private dataSharingService: DataSharingService,
    private router: Router,
  ) {
    // this._pendingLoanService.pendingLoanCount$.subscribe((x: number) => {
    //   if (x !== undefined || x !== null) {
    //     this.loanCount = x;
    //   }
    // });
  }

  ngAfterViewChecked(): void {
    if (localStorage.getItem("pendingLoanCount") !== undefined) {
      this.loanCount = JSON.parse(localStorage.getItem("pendingLoanCount"))
    }


     
    
    let staffId = Number(sessionStorage.getItem('staffId'));
    ROUTES.forEach(r => {

      if (r.title == 'Pending Approval') {
        //if (staffId != 630 && staffId != 607)
          r.isVisible = false;
        //else
        //  r.isVisible = true;
      }

      if (r.title == 'Batch Upload') {
        //if (staffId != 11 && staffId != 479 && staffId != 637 && staffId != 639)
          r.isVisible = false;
        //else
          //r.isVisible = true;
      }
      
      if (r.title == 'Client Profile') {
        if( this.clientProfile != null)
        {
            r.isVisible = true;
        }else{
          r.isVisible = false;
        }
      }

      if (r.title == 'Capture Client') {
        if( this.clientProfile == null)
        {
            r.isVisible = true;
        }else{
          r.isVisible = false;
        }
      }

      if (r.title == 'Client') {
        if( this.clientProfile == null)
        {
            r.isVisible = true;
        }else{
          r.isVisible = false;
        }
      }

      if (r.title == 'Loans') {
        if( this.clientProfile == null)
        {
            r.isVisible = false;
        }else{
          r.isVisible = true;
        }
      }

      if (r.title == 'Cancel Application') {
        if( this.clientProfile == null)
        {
            r.isVisible = true;
        }else{
          r.isVisible = false;
        }
      }

    });
  }

  hasUnconfirmedLoan(loans) {
    return loans.some(loan => loan.confirmed === false && loan.loanStatus == "200" || loan.viewPreAgreement == false && loan.loanStatus == "200");
  }

  ngOnInit() {

    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.hasNotifications = true;
    this.version = version;
    var stuffID = sessionStorage.getItem('staffId');
    if (stuffID == '630' || stuffID == '607') {
      this.showMenuItem = true
    }
    else {
      this.showMenuItem = false
    }

    this.dataSharingService.ViewedPreAgreement$.subscribe(viewed => {
      if(viewed)
      {
        this.pendingLoans = false;
      }else{
        this.pendingLoans = true
      }
     
    });

    this.dataSharingService.signedPreAgreement$.subscribe(signed => {
      if(signed)
      {
        this.pendingLoans = false;
      }else{
        this.pendingLoans = true
      }
     
    });

    this.clientProfile = JSON.parse(sessionStorage.getItem('ClientInfo'));

    if(this.clientProfile != null)
    {
      let pendingLoans = this.hasUnconfirmedLoan(this.clientProfile.loans);
      if(!pendingLoans)
      {
        this.dataSharingService.setViewedPreAgreement(true);
        this.dataSharingService.setsignedPreAgreement(true);
      }else{
        this.dataSharingService.setViewedPreAgreement(false);
        this.dataSharingService.setsignedPreAgreement(false);
      }
    }

  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  toggleClient(c) {
    if(c.title == 'Cancel Application')
    {
      const clientToken = JSON.parse(sessionStorage.getItem('ClientTokenDetails'));
      window.location.href = clientToken.cancelUrl;
    }
    return;
    let staffId = Number(sessionStorage.getItem('staffId'));
    if (this.toggleValue == 0) {
      if (c.title == 'Client') {

        this.toggleValue = 1;
        ROUTES.forEach(r => {

          if (r.subItem) {
            r.isVisible = false;
          }
        });
      }
    } else {
      if (c.title == 'Client') {

        this.toggleValue = 0;
        ROUTES.forEach(r => {

          if (r.subItem) {
            if (r.title == 'Find Client') {
              if (staffId != 630 && staffId != 607) {
                r.isVisible = false;
              } else {
                r.isVisible = true;
              }
            } else {
              r.isVisible = true;
            }
          }
        });
      }
    }
  }

  refreshPendingList() {
    this._pendingLoanService.updatePendingLoanList(Number(sessionStorage.getItem("staffId")));
  }
}
