import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  constructor() { }
  Error(title: string = 'Error', text: string = '', footer: string = ''): void {
    if (footer == null || footer === '') {
      Swal.fire({
        icon: 'error',
        title,
        text
      });
    }
    else {
      Swal.fire({
        icon: 'error',
        title,
        text,
        footer
      });
    }
  }
  Success(title: string = 'Success', text: string = '', footer: string = ''): void {
    if (footer == null || footer === '') {
      Swal.fire({
        icon: 'success',
        title,
        text
      });
    }
    else {
      Swal.fire({
        icon: 'success',
        title,
        text,
        footer
      });
    }
  }
  Confirmation(text: string = '', confirmButtonText: string = 'Ok', cancelButtonText: string = 'Cancel'): Observable<boolean> {
    let returnResult = new ReplaySubject<boolean>(1);
      Swal.fire({
        icon: 'question',
        title: 'Confirmation',
        text,
        confirmButtonText,
        cancelButtonText,
        showCancelButton: true,
        confirmButtonColor: '#042D4E',
        cancelButtonColor: '#042D4E'
      }).then((result) => {
        if (result.value) {
          returnResult.next(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          returnResult.next(false);
        }
      })
      return returnResult.asObservable();
  }
  Info(title: string = 'Information', text: string = '', footer: string = ''): void {
    if (footer == null || footer === '') {
      Swal.fire({
        icon: 'info',
        title,
        text
      });
    }
    else {
      Swal.fire({
        icon: 'info',
        title,
        text,
        footer
      });
    }
  }
  Warning(title: string = 'Warning', text: string = '', footer: string = ''): void {
    if (footer == null || footer === '') {
      Swal.fire({
        icon: 'warning',
        title,
        text
      });
    }
    else {
      Swal.fire({
        icon: 'warning',
        title,
        text,
        footer
      });
    }
  }
}