<div class="container-fluid">
  <div class="mb-5">

    <div class="form-position">
      <div class="container-fluid container-height">
        <div class="row row-direction-col">

          <div class="row p-2 pb-0 pl-4 pb-4">
            <button title="Download Report" mat-fab class="form-button-right-relative" style="box-shadow: none;"
              type="submit" (click)="exporter.exportTable('csv', {fileName:reportName})"><i
                class="fa-solid fa-download fa-2x"></i></button>
          </div>

          <!--<section>
            <mat-checkbox class="checkbox-margin" (change)="onChange($event)">History Results</mat-checkbox>
          </section>

          <div *ngIf="checkHistory" class="row p-4 ml-3">
            <mat-form-field appearance="standard" class="mat-field-margin">
              <mat-label class="mat-label-margin">Batch results Date</mat-label>
              <input matInput [matDatepicker]="picker1" [(ngModel)]="history_date" [disabled]="isLoading">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="standard" class="mat-field-margin">
              <mat-label class="mat-label-margin">Start time</mat-label>
              <input matInput class="mat-textbox" input name="startTime" [disabled]="isLoading" placeholder="14:00"
                input type="text" [(ngModel)]="startTime">
            </mat-form-field>
            <mat-form-field appearance="standard" class="mat-field-margin">
              <mat-label class="mat-label-margin">End time</mat-label>
              <input matInput class="mat-textbox" input name="endTime" #timeElem [disabled]="isLoading"
                placeholder="16:00" input type="text" [(ngModel)]="endTime" click="stopLoader()">
            </mat-form-field>

          </div>
        -->
          <section class="mb-4">
            <button mat-flat-button *ngIf="checkHistory" class="form-button-left mb-0 mat-field-margin-l-6"
              id="searchID" [disabled]="isLoading" (click)="openDialog()">Search<img *ngIf="isLoading"
                class="icon_margin mat-field-margin-l" style="height: 40px;" src="/assets/img/uloans/btn-loading.svg">
            </button>
            <!-- <i class="fa fa-close fa-spin"></i> -->
          </section>
          <section *ngIf="!isValidateSearch">
            <h6 class="clr-red">{{validateText}}</h6>
          </section>

          <div class="row p-4">
            <div *ngIf="searchHistory" class="mat-field-margin-l">
              {{recordsCount}} Records returned
            </div>
            <div class="col-md-12">
              <div class="mat-elevation-z8 col-md-12">
                <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter">
                  <ng-container [matColumnDef]="column.DbName" *ngFor="let column of columns">
                    <ng-container *ngIf="column.DisplayName != 'Prevet'">
                      <th mat-header-cell *matHeaderCellDef> {{column.DisplayName}} </th>
                      <td mat-cell *matCellDef="let element"> {{element[column.DbName]}} </td>
                    </ng-container>
                    <ng-container *ngIf="column.DisplayName == 'Prevet'">
                      <th mat-header-cell *matHeaderCellDef> {{column.DisplayName}} </th>
                      <td mat-cell *matCellDef="let element">
                        <i class="fa fa-close clr-red" *ngIf="element['state'] == 'False'"> </i>
                        <i class="fa fa-check clr-green" *ngIf="element['state'] == 'True'"> </i>
                      </td>
                      <!-- <td mat-cell *matCellDef="let element">
                        <mat-icon class="clr-green" *ngIf="element['state'] == 'True'">done</mat-icon> 
                        <mat-icon class="clr-red" *ngIf="element['state'] == 'False'">close</mat-icon>
                      </td> -->
                    </ng-container>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
                </table>
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                  aria-label="Select page of results">
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>