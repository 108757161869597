import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-batch-dialog',
  templateUrl: './batch-dialog.component.html',
  styleUrls: ['./batch-dialog.component.scss']
})
export class BatchDialogComponent implements OnInit {

  fromPage!: string;
  fromDialog!: string;

  constructor(
    public dialogRef: MatDialogRef<BatchDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public mydata: any
  ) { }

  ngOnInit(): void {
    this.fromPage = this.mydata;
    this.fromDialog = "I am from dialog land...";
  }

  yesDialog() {
    this.dialogRef.close({ event: 'yes-option', data: this.fromDialog });
  }
  cancelDialog() {
    this.dialogRef.close({ event: 'cancel-option', data: this.fromDialog });
  }

  closeDialog() { this.dialogRef.close({ event: 'close', data: this.fromDialog }); }
}