import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Acknowledgments, BankingDetails, FindBank } from 'app/ts/models/broker-classes';
import { ApiService } from 'app/ts/ApiService';
import { AccountTypes, BankEmployerDetailsResult, Banks } from 'app/ts/models/broker-interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { DataSharingService } from 'app/data-sharing-service';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss', '../../forms.shared.scss']
})

export class BankDetailsComponent implements OnInit {
  banks: Banks[];
  accountTypes: AccountTypes[];
  bankingDetails = new BankingDetails();
  acknowledgments = new Acknowledgments();
  bankFind = new FindBank();
  clientId: number;
  result = {} as BankEmployerDetailsResult;

  currentRoute: string;
  clientFind: any;
  SupportedBanks = [
    "absa",
    "african bank",
    "capitec",
    "discovery bank",
    "fNB",
    "investec",
    "nedbank",
    "sasfin",
    "standard bank",
    "randmerchant",
    "rmb",
    "tymebank"
  ];
  clientBankSet:boolean
  bankInfo: any[] = [
    { bankName: 'ABSA', branchCode: '632005', swiftCode: 'ABSAZAJJ'},
    { bankName: 'African Bank', branchCode: '430000', swiftCode: 'AFRCZAJJ'},
    { bankName: 'Capitec', branchCode: '470', swiftCode: 'CABLZAJJ'},
    { bankName: 'Discovery Bank', branchCode: '679000', swiftCode: 'DISCZAJJ'},
    { bankName: 'FNB', branchCode: '250655', swiftCode: 'FIRNZAJJ'},
    { bankName: 'Investec', branchCode: '580105', swiftCode: 'IVESZAJJ'},
    { bankName: 'Mercantile', branchCode: '450905', swiftCode: 'LISAZAJJ'},
    { bankName: 'Nedbank', branchCode: '198765', swiftCode: 'NEDSZAJJ'},
    { bankName: 'RMB', branchCode: '261251', swiftCode: 'FIRNZAJJ'},
    { bankName: 'Standard Bank', branchCode: '051001', swiftCode: 'SBZAZAJJ'},
    { bankName: 'TymeBank', branchCode: '678910', swiftCode: 'CBZAZAJJ'},
    
    ];

  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private dataSharingService: DataSharingService,
  ) {
    this.setBankDetailForm();

  }

  public bankDetails: FormGroup;

  @Output() updateStepEvent = new EventEmitter<number>();
  @Output() previousStepEvent = new EventEmitter<number>();
  @Output() sendBankDetailsResult = new EventEmitter<BankEmployerDetailsResult>();
  @Output() bankForm = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.api.getBanks().subscribe((data: Banks[]) => {


      const supportedBanksLower = this.SupportedBanks.map(bank => bank.toLowerCase());

      // Filter banks based on supported bank names
      const selectedBanks = data.filter(bank =>
          supportedBanksLower.includes(bank.bankName.toLowerCase())
      );

      this.banks = selectedBanks;

    });

    this.api.getAccountTypes().subscribe((data: AccountTypes[]) => {
      this.accountTypes = data;
    });

    // this.bankDetails.valueChanges.subscribe(() => {
    //   if (this.bankDetails.valid) {
    //     this.bankForm.emit(true);
    //   } else {
    //     this.bankForm.emit(false);
    //   }
    // })
    

    this.populateSearchBank();

    //this.bankForm.emit(this.bankDetails);
  }

  // ngAfterViewChecked(): void {
  //   this.paramsChanged();
  // }

  paramsChanged() {
    this.route.params.subscribe(params => {
      if (this.clientId != params['clientId']) {
        this.clientId = +params['clientId'];
        sessionStorage.setItem('staffId', this.clientId.toString());

        if (this.clientId != 0) {
          this.populateSearchBank();
        } else {
          this.setBankDetailForm();
        }
      }
    });

    this.dataSharingService.ClientInformation$.subscribe(details => {
      
      if(details != null  && !this.clientBankSet)
      {
        const clientFind = details;

        this.bankDetails.controls.bank.patchValue( parseInt(clientFind.bankDetails.banks_cd_Bank));
        this.bankDetails.controls.accountNumber.patchValue(clientFind.bankDetails.account_Number);
        this.bankDetails.controls.accountType.patchValue(parseInt(clientFind.bankDetails.account_Type_cd_Account_type));
        this.bankDetails.controls.branchCode.patchValue(clientFind.bankDetails.branch_Number);

        this.clientBankSet = true;
      }
    });
  }

  onBankChange(selectedBankId: any) {
    //this.banks.filter(bank => bank.bankName.toLowerCase().includes(this.searchString.toLowerCase()));
    const selectedBank = this.banks.filter(b => b.id == selectedBankId)[0];
    const bankSupported = this.SupportedBanks.filter(bank => bank.toLowerCase().includes(selectedBank.bankName.toLowerCase()))[0];

    

      const bank = this.bankInfo.find(bank => bank.bankName == selectedBank.bankName);
    if (selectedBank) {
      this.bankDetails.get('branchCode').setValue(bank.branchCode);
      // this.supplierForm.get('bankDetails.branchName').setValue(selectedBank.branchName);
      // this.supplierForm.get('bankDetails.swiftCode').setValue(selectedBank.swiftCode);
    }
    
    if(bankSupported == null)
    {
      this.openSnackBar("We do not support this bank please select a different one", 'error-snackbar');
      this.bankDetails.controls.bank.setValue("");
    }
  }

  

  populateSearchBank() {

    // this.paramsChanged();

    var clientIdSaved = sessionStorage.getItem('_staffId');

    this.clientFind = JSON.parse(sessionStorage.getItem('ClientInfo'));
    

    

    if (clientIdSaved != '0' && clientIdSaved != null) {
      this.bankFind = JSON.parse(sessionStorage.getItem('BankDetailsResult'));
      this.acknowledgments = JSON.parse(sessionStorage.getItem('AcknowledgementResult'));

      this.bankingDetails.clientId = Number(this.bankFind.ClientId);
      this.bankingDetails.account_Name = sessionStorage.getItem('accountHolder');
      this.bankingDetails.banks_cd_Bank = this.bankFind.BanksCdBank;
      this.bankingDetails.account_number = this.bankFind.AccountNumber;
      this.bankingDetails.account_Type_cd_account_Type = this.bankFind.AccountTypeCdAccountType;
      this.bankingDetails.branch_number = this.bankFind.BranchNumber;

      this.bankDetails.controls.bank.setValue(this.bankingDetails.banks_cd_Bank);
      this.bankDetails.controls.accountNumber.patchValue(this.bankingDetails.account_number);
      this.bankDetails.controls.accountType.setValue(this.bankingDetails.account_Type_cd_account_Type);
      this.bankDetails.controls.branchCode.patchValue(this.bankingDetails.branch_number);

      // this.bankDetails.controls.statementAge.setValue(true);
      // this.bankDetails.controls.salaries.setValue(true);
      // this.bankDetails.controls.owner.setValue(ttrue);

      var loanId = parseInt(sessionStorage.getItem('loanStatusId'));

      this.bankDetails.valueChanges.subscribe((x) => {
        if (this.bankDetails.valid) {
          this.bankForm.emit(this.bankDetails.valid);
        }
      })

      if (loanId == 300) {
        //this.bankDetails.disable();
      }
      else {
        this.bankDetails.controls.accountNumber.disable();
        this.bankDetails.controls.branchCode.disable();
      }

    }
    else {

      // this.api.getBanks().subscribe((data: Banks[]) => {
      
        // const supportedBanksLower = this.SupportedBanks.map(bank => bank.toLowerCase());

        // Filter banks based on supported bank names
        // const selectedBanks = data.filter(bank =>
        //     supportedBanksLower.includes(bank.bankName.toLowerCase())
        // );
  
        // this.banks = selectedBanks;

        // this.bankDetails.enable();

        // sessionStorage.removeItem('BankDetailsResult');
        // sessionStorage.removeItem('AcknowledgementResult');
  
        // this.bankDetails.controls.bank.setValue(0);
        // this.bankDetails.controls.accountNumber.patchValue('');
        // this.bankDetails.controls.accountType.setValue(0);
        // this.bankDetails.controls.branchCode.patchValue('');
  
        // this.bankDetails.controls.statementAge.setValue(0);
        // this.bankDetails.controls.salaries.setValue(0);
        // this.bankDetails.controls.owner.setValue(0);


        if(this.clientFind != null)
        {
          this.bankFind = this.clientFind.bankDetails;
          //account_Number
          this.bankDetails.controls.bank.setValue(this.clientFind.bankDetails.banks_cd_Bank);
          this.bankDetails.controls.accountNumber.patchValue(this.clientFind.bankDetails.account_Number);
          this.bankDetails.controls.accountType.patchValue(parseInt(this.clientFind.bankDetails.account_Type_cd_Account_type));
          this.bankDetails.controls.branchCode.patchValue(this.clientFind.bankDetails.branch_Number);

        }
  
      // });


    }

  }

  setBankDetailForm() {
    this.bankDetails = this.formBuilder.group({
      bank: ['', Validators.required],
      accountNumber: ['', Validators.required],
      accountType: ['', Validators.required],
      branchCode: ['', Validators.required],
      statementAge: [false],
      salaries: [false],
      owner: [false]
    });
    this.bankDetails.enable();
  }

  save() {

    if (this.bankDetails.valid) {

      // var olderThanThirtyDays = this.bankDetails.controls['statementAge'].value;
      // var reflectsThreeSalaries = this.bankDetails.controls['salaries'].value;
      // var belongsToClient = this.bankDetails.controls['owner'].value;

      var proceed = true;

      // if (olderThanThirtyDays == false || reflectsThreeSalaries == false || belongsToClient == false) {

      //   this.result.message = "Please check the bank statement confirmation boxes in order to continue";
      //   this.result.saved = false;
      //   this.sendBankDetailsResult.emit(this.result);
      //   //this.openSnackBar("Please check the bank statement confirmation boxes in order to continue", 'error-snackbar');
      //   proceed = false;
      // }

      if (proceed == true) {

        this.bankingDetails.clientId = Number(sessionStorage.getItem('clientId'));
        this.bankingDetails.account_Name = sessionStorage.getItem('accountHolder');
        this.bankingDetails.banks_cd_Bank = this.bankDetails.controls['bank'].value;
        this.bankingDetails.account_number = this.bankDetails.controls['accountNumber'].value;
        this.bankingDetails.account_Type_cd_account_Type = this.bankDetails.controls['accountType'].value;
        this.bankingDetails.branch_number = this.bankDetails.controls['branchCode'].value;


        this.api.saveBankingDetails(this.bankingDetails).subscribe((data: any) => {
          this.openSnackBar("Bank details saved!", 'success-snackbar');

          //todo: add error handling
  
          this.updateStepEvent.emit();

          // if (data.message == "Ok") {

          //   if (this.acknowledgments == null) {
          //     this.acknowledgments = new Acknowledgments();
          //   }

          //   this.acknowledgments.ClientId = Number(sessionStorage.getItem('clientId'));
          //   this.acknowledgments.StaffId = Number(sessionStorage.getItem('staffId'));
          //   this.acknowledgments.OlderThanThirtyDays = this.bankDetails.controls['statementAge'].value;
          //   this.acknowledgments.ReflectsThreeSalaries = this.bankDetails.controls['salaries'].value;
          //   this.acknowledgments.BelongsToClient = this.bankDetails.controls['owner'].value;

          //   this.api.saveAcknowledgments(this.acknowledgments).subscribe((result) => {
          //     this.result.message = result.message;
          //     this.result.saved = result.message == "Success" && data.message == "Ok" ? true : false;
          //     this.sendBankDetailsResult.emit(this.result);
          //   });

          // } else {
          //   this.result.message = data.message;
          //   this.result.saved = false;
          //   this.sendBankDetailsResult.emit(this.result);
          //   this.bankingDetails = new BankingDetails();
          // }
        });
      }
    }
    else 
    {
      this.openSnackBar("Please enter the correct Bank details and confirm all 3 checks", 'error-snackbar');
    }

  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  change(event: { value: number; }) {
    this.acknowledgments = new Acknowledgments();
  }

  // back(): void {
  //   this.previousStepEvent.emit();
  // }
}
