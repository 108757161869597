import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'app/ts/ApiService';
import { Approval, Decline, LoanApplication, Reject } from 'app/ts/models/loan-classes';
import { ProductSelectService } from 'app/ts/services/product-select.service';

@Component({
  selector: 'app-loan-dialog',
  templateUrl: './loan-dialog.component.html',
  styleUrls: ['./loan-dialog.component.scss']
})
export class LoanDialogComponent implements OnInit {
  loanAmount: number;
  loanTerm: number;
  loanType: string;
  loanId: number;
  clientId: number;
  actionType: string;

  loanApplication$ = this._productSelect.loanApplication;

  constructor(private dialogRef: MatDialogRef<LoanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private api: ApiService,
    private _productSelect: ProductSelectService) {
    this.loanAmount = data.loanAmount;
    this.loanTerm = data.term;
    this.loanId = data.loanId;
    this.clientId = data.clientId;
    this.actionType = data.actionType;


    this._productSelect.loanApplication$.subscribe((x: LoanApplication) => {
      if (x !== undefined || x !== null) {
        if (this.loanApplication$ != x) {
          this.loanApplication$ = x;
        }
      }
    });

  }

  ngOnInit() {
  }

  save() {
    if(this.actionType == 'reject'){
      let reject = new Reject();
      reject.clientId = this.clientId;
      reject.loanId = this.loanId;
      reject.userId = Number(sessionStorage.getItem('userId'));
      reject.brokerId = Number(sessionStorage.getItem('staffId'));
      this.api.appRepo.rejectLoan(reject).subscribe((data) => {
        this.dialogRef.close();
      })
    }
    else if(this.actionType == 'decline'){
      let decline = new Decline();
      decline.isApproved = false;
      decline.approvedOn = new Date();
      decline.clientId = this.clientId;
      decline.loanId = this.loanId;
      decline.userId = Number(sessionStorage.getItem('userId'));
      decline.brokerId = Number(sessionStorage.getItem('staffId'));
      decline.newTerm = this.loanApplication$.term;
      decline.newLoanAmount = this.loanApplication$.amount;
      
      if (this.loanApplication$.type == 'Debt Consolodation Loan') {
        decline.loanType = "individual-debt";
      }
      else if (this.loanApplication$.type == 'Personal Loan') {
        decline.loanType =  "individual-personal";
      }
      else if (this.loanApplication$.type == 'Short Term Loan') {
        decline.loanType = "individual-short";
      }
      
  
      this.api.declineLoan(decline).subscribe((data) => {
        this.dialogRef.close();
      })
    }
  }

  close() {
    this.dialogRef.close();
  }

}
