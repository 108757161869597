<form>
    <div>
        <div class="row loan-description">
            <div class="col" *ngIf="loanType =='Short Term Loan'" (click)="loanSelect('Short Term Loan')" size="12">
                Need cash urgently? Apply for a loan of up to <app-currency-formatter [value]="8000"></app-currency-formatter>
                over 6 months and get your loan approved the same day.
            </div>
            <div class="col" *ngIf="loanType =='Personal Loan'" (click)="loanSelect('Personal Loan')" size="12">
                Apply for a personal loan from
                <app-currency-formatter [value]="amountMin"></app-currency-formatter> up to
                <app-currency-formatter [value]="amountMax"></app-currency-formatter> knowing that your repayment amount
                is the same each month.
            </div>
            <div class="col" *ngIf="loanType =='Debt Consolodation Loan'" (click)="loanSelect('Debt Consolodation Loan')" size="12">
                Consolidate your debt and reduce your monthly
                debt obligations by easily managing one loan instalment.
            </div>
        </div>

        <div class="row loan-amount-row">
            <div class="col">
                <h3 class="text-start">Amount</h3>
            </div>
            <div class="col">
                <h3 class="loan-amount">
                    <app-currency-formatter [value]="loanAmount"></app-currency-formatter>
                </h3>
            </div>
        </div>

        <div class="row amount-slider-row">
            <div class="col">
                <mat-slider [(ngModel)]="loanAmount" [ngModelOptions]="{standalone: true}" min="{{amountMin}}" max="{{amountMax}}" step="{{step}}" (input)="onInputChange($event)"
                    class="amount-slider"></mat-slider>
            </div>
        </div>

        <div class="row amount-slider-labels-row">
            <div size="6" class="col form-text text-start">
                <app-currency-formatter [value]="amountMin"></app-currency-formatter>
            </div>
            <div size="6" class="col text-end form-text">
                <app-currency-formatter [value]="amountMax"></app-currency-formatter>
            </div>
        </div>

        <div class="row loan-amount-row">
            <div class="col">
                <h3 class="text-start">Term</h3>
            </div>
            <div class="col">
                <h3 class="loan-amount">
                    {{term}} Months
                </h3>
            </div>
        </div>

        <div class="row amount-slider-row">
            <div class="col">
                <mat-slider [(ngModel)]="term" [ngModelOptions]="{standalone: true}" min="{{termMin}}" max="{{termMax}}" step="{{termStep}}" (input)="onTermChange($event)"
                    class="amount-slider"></mat-slider>
            </div>
        </div>

        <div class="row amount-slider-labels-row">
            <div size="6" class="col form-text text-start">
                {{termMin}} Months
            </div>
            <div size="6" class="col text-end form-text">
                {{termMax}} Months
            </div>
        </div>

        <div class="row term-row mt-2">
            <div class="col" offsetXl="1" offsetLg="1" offsetMd="1" sizeXl="6" sizeLg="6" sizeMd="6" sizeSm="12"
                sizeXs="12">
                <div class="row">
                    <div class="col text-center mx-auto" size="6">
                        <h4 class="title">MONTHLY PAYMENT</h4>
                    </div>
                </div>
                <div class="row mx-auto" style="width: 70%;">
                    <div size="12" class="col term-payment-detail">
                        <h3 class="term-payment text-center">
                            <b>
                                <app-currency-formatter [value]="emiVal"></app-currency-formatter>
                            </b>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div size="12" class="col terms-conditions text-start">
                <p>
                    The monthly repayment must only be used as an estimate and will be confirmed upon the approval of
                    the loan.
                </p>
            </div>
        </div>
    </div>
</form>