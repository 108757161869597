import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { LoadLoansComponent } from 'app/forms/broker-portal/load-loans/load-loans.component';
import { LoanComponent } from '../loan/loan.component';

@Component({
  selector: 'app-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss']
})
export class ProductSelectorComponent implements OnInit {

  @ViewChild(LoanComponent) loan: LoanComponent;

  constructor() { }
  @Output() updateStepEvent = new EventEmitter<number>();
  ngOnInit() {
  }

  apply() {
    this.loan.save();
    this.updateStepEvent.emit();
  }

}
