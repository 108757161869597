<div *ngIf="actionType=='reject'">
  <h2 mat-dialog-title>Rejecting loan</h2>

  <mat-dialog-content>
    <p> 
      By selecting to reject a loan, you agree that we should cancel the application.  Are you sure you want to continue?
    </p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="form-button-right-close" (click)="close()"><i class="fa fa-x"></i></button>
    <button class="form-button-right" (click)="save()"><i class="fa fa-arrow-right"></i></button>
  </mat-dialog-actions>
</div>
<div *ngIf="actionType=='decline'">
  <h2 mat-dialog-title>Adjust loan amount and terms</h2>

  <mat-dialog-content>
    <mat-tab-group mat-stretch-tabs>
      <mat-tab #loadloans label="Loan Amendment">
        <app-load-loans></app-load-loans>
      </mat-tab>
      <mat-tab #notes label="Loan Notes">
        <app-notes [loanId]="loanId"></app-notes>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  
  <mat-dialog-actions>
    <button class="form-button-right-close" (click)="close()"><i class="fa fa-x"></i></button>
    <button class="form-button-right" (click)="save()"><i class="fa fa-arrow-right"></i></button>
  </mat-dialog-actions>
</div>
