<mat-form-field appearance="fill">
  <mat-label class="mat-label-margin">Select a Product</mat-label>
  <select matNativeControl [(ngModel)]="productSelected" (change)="onProductChange()">
    <option [value]="p" *ngFor="let p of productCategories">
      {{p}}
    </option>
  </select>
</mat-form-field>



<!-- <form>
  <mat-form-field appearance="standard">
    <mat-label class="mat-label-margin">Select a Product</mat-label>

   
    <mat-select (change)="onProductChange()" [(ngModel)]="productSelected" name="selectProduct">
      <mat-option *ngFor="let p of productCategories">
        {{p}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label class="mat-label-margin">Select a Report</mat-label>
    <mat-select (change)="selectChangeHandler($event)" [(ngModel)]="productSelected" name="reportname">
        <mat-option *ngFor="let report of productCategories" [value]="report">
            {{report}}
        </mat-option>
    </mat-select>
</mat-form-field>
</form> -->