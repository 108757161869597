<app-navbar-auth></app-navbar-auth>
<div class="wrapper col-md-6 col-lg-4 mx-auto">
    <div class="main-content">
        <div class="container-fluid">
            <div class="row text-center">
                <div class="col-12 card mx-auto" style="opacity: .95;">
                    <!-- <app-login></app-login> -->
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>