<div class="container-fluid capture-card">
    <div class="form-position">
        <form [formGroup]="empDetails" novalidate>
            <div class="card-body col-md-12">
                <div class="row mobile-padding">
                    <h3><b>Employment Details</b><img class="icon-size desktop-only" src="/assets/img/30day/icon2.png"></h3>
                </div>
                <div class="row mobile-padding">
                    <p>The following information will only be used by Lndr</p>
                  </div>

                <div class="row pl-4 pr-4 pt-4 mobile-padding">
                    <div class="col-6 center-container pr-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Employer Name</label>
                        <input class="custom-update-textbox pl-4" type="text" value="" placeholder="Employer Name" formControlName="employer_Name"> 
                        <div *ngIf="empDetails.get('employer_Name').invalid && empDetails.get('employer_Name').touched" class="error-message">
                            Employer Name is required
                        </div>
                    </div>
                    <div class="col-6 center-container pr-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Employer Contact Number</label>
                        <input class="custom-update-textbox pl-4" minlength="10" maxlength="10" type="text" value="" placeholder="Employer Contact No" formControlName="employer_Contact_Number"> 
                        <div *ngIf="empDetails.get('employer_Contact_Number').invalid && empDetails.get('employer_Contact_Number').touched" class="error-message">
                            Contact Number is required
                        </div>
                    </div>
                </div>  

                <div class="row pl-4 pr-4 pt-1 mobile-padding">
                    <div class="col-6 center-container pr-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Employee Number</label>
                        <input class="custom-update-textbox pl-4" type="text" value="" placeholder="Employee No" formControlName="employee_Number"> 
                    </div>

                    
                    <div class="col-6 center-container pr-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Start Date</label>
                        <label class="custom-date-picker-wrapper"  for="employmentStartDate">
                            <input 
                                class="custom-update-datepicker pl-4"
                                id="employmentStartDate" 
                                type="date" 
                                formControlName="employment_Start_Date">
                        </label>
                        <div *ngIf="empDetails.get('employment_Start_Date').invalid && empDetails.get('employment_Start_Date').touched" class="error-message">
                            Start Date is required
                        </div>

                    </div>
                </div>  

                
                





                <div class="row pl-4 pr-4 pt-1 mobile-padding">                    
                    <div class="col-6 center-container pr-4 custom-select-wrapper font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Employee Salary Date</label>
                        <select id="bank" class="custom-update-textbox pl-4" formControlName="employer_Salary_Date">
                            <option class="select-background" *ngFor="let type of salaryDates" [value]="type.id">
                                {{type.value}}
                            </option>
                        </select>                        
                    </div>  
                    <div class="col-6 center-container pr-4 custom-select-wrapper font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Employment Type</label>
                        <select id="bank" class="custom-update-textbox pl-4" formControlName="employmentType_cd_Employment_Type">
                            <option class="select-background" *ngFor="let type of employementType" [value]="type.id">
                                {{type.value}}
                            </option>
                        </select>                        
                    </div>
                </div>  





                <div class="row pl-4 pr-4 pt-1 mobile-padding">                    
                    <div class="col-6 center-container pr-4 custom-select-wrapper font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Employer Sector</label>
                        <select id="bank" class="custom-update-textbox pl-4" formControlName="employerSector_cd_Employer_Sector">
                            <option class="select-background" *ngFor="let type of employmentSector" [value]="type.id">
                                {{type.value}}
                            </option>
                        </select>                        
                    </div>
                    <div class="col-6 center-container pr-4 custom-select-wrapper font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Salary Type</label>
                        <select id="bank" class="custom-update-textbox pl-4" formControlName="salary_Type_cd_Salary_Type">
                            <option class="select-background" *ngFor="let type of salaryType" [value]="type.id">
                                {{type.value}}
                            </option>
                        </select>                        
                    </div>
                </div>  

            </div>
            <div class="row pl-4 ml-4 pr-4 pt-4">
                <button mat-fab class="form-button-left" style="box-shadow: none;" type="submit" (click)="save()"><div style="font-size: 18px;">Next</div></button>
            
            </div>


        </form>
    </div>
</div>

