<div class="container-fluid capture-card">
  <div class="loading-popup" *ngIf="loading">
    <div class="loading-spinner">
        <div class="loading-spinner-content"></div>
    </div>
</div>  
  <div class="form-position">
    <form [formGroup]="addressForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="row mobile-padding">
        <h3><b>Residential Address Details</b><img class="icon-size desktop-only" src="/assets/img/30day/icon2.png"></h3>
      </div>
      <div class="row mobile-padding">
        <p>The following information will only be used by Lndr</p>
      </div>

      <div class="row pl-4 pr-4 pt-4 mobile-padding">
        <div class="col-6 center-container pr-4 font-mobile" style="font-size: 12px;">
          <label class="custom-update-text pl-4 font-mobile">Street</label>
          <input class="custom-update-textbox pl-4" required type="text" value="" placeholder="Street" formControlName="street">
          <div *ngIf="addressForm.get('street').errors?.required && addressForm.get('street').touched" class="error-message">
            Street is required.
          </div>
        </div>
        <div class="col-6 center-container pl-4 font-mobile" style="font-size: 12px;">
          <label class="custom-update-text pl-4 font-mobile">City</label>
          <input class="custom-update-textbox pl-4" required type="text" value="" placeholder="City" formControlName="city">
          <div *ngIf="addressForm.get('city').errors?.required && addressForm.get('city').touched" class="error-message">
            City is required.
          </div>
        </div>
      </div>

      <div class="row pl-4 pr-4 pt-1 mobile-padding">
        <div class="col-6 center-container pr-4 font-mobile" style="font-size: 12px;">
          <label class="custom-update-text pl-4 font-mobile">Address 1</label>
          <input class="custom-update-textbox pl-4" required type="text" value="" placeholder="Address line 1"
            formControlName="addressLine1">
            <div *ngIf="addressForm.get('addressLine1').errors?.required && addressForm.get('addressLine1').touched" class="error-message">
              Address 1 is required.
            </div>
        </div>
        <div class="col-6 center-container pl-4 font-mobile" style="font-size: 12px;">
          <label class="custom-update-text pl-4 font-mobile">Address 2</label>
          <input class="custom-update-textbox pl-4" type="text" value="" placeholder="Address Line 2"
            formControlName="addressLine2">
        </div>
      </div>

      <div class="row pl-4 pr-4 pt-1 mobile-padding">
        <div class="col-6 center-container pr-4 custom-select-wrapper font-mobile" style="font-size: 12px;">
            <label class="custom-update-text pl-4 font-mobile">Province</label>
          <select class="custom-select custom-update-textbox pl-4" required formControlName="stateId" style="font-size: 12px;">
            <option default disabled>Province</option>
            <option *ngFor="let type of StateOptions" [value]="type.id">{{ type.name }}</option>
          </select>
          <div *ngIf="addressForm.get('stateId').errors?.required && addressForm.get('stateId').touched" class="error-message">
            Province is required.
          </div>
        </div>
        <div class="col-6 center-container pl-4 font-mobile" style="font-size: 12px;">
          <label class="custom-update-text pl-4 font-mobile">Postal Code</label>
          <input class="custom-update-textbox pl-4" required type="text" value="" placeholder="Postal Code"
            formControlName="postalCode" maxlength="10">
            <div *ngIf="addressForm.get('postalCode').errors?.required && addressForm.get('postalCode').touched" class="error-message">
              Postal Code is required.
            </div>
        </div>
      </div>

      <div class="row pl-4 pr-4 pt-1 mobile-padding">
        <div class="col-6 center-container pr-4 custom-select-wrapper font-mobile" style="font-size: 12px;">
          <label class="custom-update-text pl-4 font-mobile">Address Type</label>
          <select class="custom-select custom-update-textbox pl-4" required formControlName="addressTypeId" style="font-size: 12px;">
            <option default disabled>Address Type</option>
            <option *ngFor="let type of addressTypeIdOptions" [value]="type.id">{{ type.name }}</option>
          </select>
          <div *ngIf="addressForm.get('addressTypeId').errors?.required && addressForm.get('addressTypeId').touched" class="error-message">
            Address Type is required.
          </div>
        </div>
        <div class="col-6 center-container pl-4 custom-select-wrapper font-mobile" style="font-size: 12px;">
          <label class="custom-update-text pl-4 font-mobile">Country</label>
          <select class="custom-select custom-update-textbox pl-4" required formControlName="countryId" style="font-size: 12px;">
            <option default disabled>Country</option>
            <option *ngFor="let type of countryIdOptions" [value]="type.id">{{ type.name }}</option>
          </select>
          <div *ngIf="addressForm.get('postalCode').errors?.required && addressForm.get('postalCode').touched" class="error-message">
            Country is required.
          </div>
        </div>
      </div>

      <div class="row pl-4 ml-4 pr-4 pt-4">
        <br>
        <button mat-fab class="form-button-left" style="box-shadow: none; cursor: pointer;" type="submit" (click)="save()"
          [disabled]="!addressForm.valid">
          <div style="font-size: 18px;">Next</div>
        </button>
      </div>












      <!-- <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input matInput formControlName="street" input  type="text" placeholder="Street Name">
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <input matInput formControlName="addressLine1" input  type="text" placeholder="Address Line 1">
          <mat-icon matSuffix><i class="fas fa-home"></i></mat-icon>
        </mat-form-field>        
      </div>
    </div>
  
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input matInput formControlName="addressLine2" input  type="text" placeholder="Address Line 2">
          <mat-icon matSuffix><i class="fas fa-home"></i></mat-icon>
        </mat-form-field>        
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-select formControlName="addressTypeId" placeholder="Address Type">
            <mat-option *ngFor="let type of addressTypeIdOptions" [value]="type.id">{{ type.name }}</mat-option>            
          </mat-select>
          <mat-icon matSuffix><i class="fas fa-building"></i></mat-icon>
        </mat-form-field>        
      </div>
    </div>
  
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input matInput formControlName="city" placeholder="City" input  type="text">
          <mat-icon matSuffix><i class="fas fa-map-pin"></i></mat-icon>
        </mat-form-field>       
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-select formControlName="stateId" placeholder="Provice">
            <mat-option *ngFor="let type of StateOptions" [value]="type.id">{{ type.name }}</mat-option>            
          </mat-select>
          <mat-icon matSuffix><i class="fas fa-map-marker"></i></mat-icon>
        </mat-form-field>     
      </div>
    </div>

  
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input matInput formControlName="postalCode" placeholder="Postal Code" input  type="text">
          <mat-icon matSuffix><i class="fas fa-signs-post"></i></mat-icon>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-select formControlName="countryId" placeholder="Country">
            <mat-option *ngFor="let type of countryIdOptions" [value]="type.id">{{ type.name }}</mat-option>            
          </mat-select>
          <mat-icon matSuffix><i class="fas fa-flag"></i></mat-icon>
        </mat-form-field>
      </div>

    </div>
    
    <div class="row row-padding">
        <br>
        <button mat-fab class="form-button-left" style="box-shadow: none;" type="submit" (click)="save()" [disabled]="!addressForm.valid" ><i class="fa fa-arrow-right" ></i></button>
    </div> -->
    </form>
  </div>
</div>