import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { LoadLoansComponent } from 'app/forms/broker-portal/load-loans/load-loans.component';

@Component({
  selector: 'app-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss']
})
export class LoanComponent implements OnInit {

  constructor() { }

  @ViewChild(LoadLoansComponent) loadLoan: LoadLoansComponent;
  @Output() updateStepEvent = new EventEmitter<number>();
  @Output() previousStepEvent = new EventEmitter<number>();

  ngOnInit() {
  }

  save() {
    this.loadLoan.apply();
    this.updateStepEvent.emit();
  }

}
