import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { ApiService } from 'app/ts/ApiService';
import { PasswordDetails } from 'app/ts/models/user-credentials';

@Component({
  selector: 'app-renew-password',
  templateUrl: './renew-password.component.html',
  styleUrls: ['./renew-password.component.scss']
})
export class RenewPasswordComponent implements OnInit {

  constructor(private api: ApiService,
    private router: Router,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder,) { }

  renewedPassword = new PasswordDetails();
  showDetails: boolean;

  renewPass = this.formBuilder.group({
    password: ['', Validators.required],
    repeatPassword: ['', Validators.required]
  })

  ngOnInit() {
    this.showDetails = false;
  }

  renew() {
    let userId = Number(sessionStorage.getItem("userId"));

    this.api.getUser(userId).subscribe((data) => {
      this.renewedPassword.firstname = data.firstname;
      this.renewedPassword.password = this.renewPass.controls["password"].value;
      this.renewedPassword.repeatPassword = this.renewPass.controls["repeatPassword"].value

      this.api.renewPassword(userId, this.renewedPassword).subscribe((otherData) => {
        if (otherData === "The submitted password has already been used in the past") {
          this.openSnackBar(otherData, 'error-snackbar');
        } else {
          this.openSnackBar("Password Updated", 'success-snackbar');
          this.router.navigate(['broker/dashboard'], { queryParams: { userId: userId } });
        }
      })
    })
  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
