<div class="container-fluid capture-card">
    <div class="form-position">
            <div class="row mobile-padding">
                <h3 class="ml-4"><b>Upload Documents</b><img class="icon-size desktop-only" src="/assets/img/30day/icon2.png"></h3>
            </div>
            <div class="row ml-2 mobile-padding">
                <p>The following information will only be used by Lndr</p>
            </div>
            <div class="card-body col-md-12 col-12 centered-grid">
                    
                    <div class="list-group mt-5">
                        <ng-container *ngFor="let doc of documents; let i = index">
                            <div *ngIf="!(doc.isUploaded && existingClient)" (click)="selectDocument(i)" class="list-group-item list-group-item-action" [ngClass]="{'active': selectedDocument == doc.key}">
                                <div class="doc-description">
                                    {{doc.description}}
                                </div>
                                <input type="checkbox" class="doc-checkbox" disabled="true" [checked]="doc.isUploaded" />
                            </div>
                        </ng-container>
                    </div>
                    <mat-list class="mt-5 instructions-list">
                        <mat-list-item>
                          <mat-icon matListIcon>file_upload</mat-icon>
                          <h4 matLine>Upload at least one document from each category</h4>
                        </mat-list-item>
                        <mat-list-item>
                          <mat-icon matListIcon>warning</mat-icon>
                          <h4 matLine>Files must not exceed 5MB each</h4>
                        </mat-list-item>
                        <mat-list-item>
                          <mat-icon matListIcon>check_circle</mat-icon>
                          <h4 matLine>A tick confirms successful upload</h4>
                        </mat-list-item>
                        <mat-list-item>
                          <mat-icon matListIcon>description</mat-icon>
                          <h4 matLine>Supported formats: 'jpg', 'jpeg', 'png', 'pdf'</h4>
                        </mat-list-item>
                      </mat-list>
                    <br>
                    <div class="fileDropZone">
                        <div for="file" ng2FileDrop (fileOver)="fileOverBase($event)" [uploader]="uploader" class="text-primary file-upload-label m-0 p-0">
                            <input type="file" ng2FileSelect [uploader]="uploader" multiple />
                            <div class="desktop-only">
                                <h3>Drag and drop {{selectedDocumentDescription}} here</h3>
                                <h3>or</h3>
                                <label for="fileDropID">Browse for file</label>
                            </div>
                            <div class="mobile-only">
                                <h3>Click to upload: {{selectedDocumentDescription}}</h3>
                               
                            </div>
                           
                        </div>
                    </div>
                    <br />
                    <div class="files-list" *ngIf="documentSelected.uploadDetails == null">
                        <div class="single-file" *ngFor="let fileItem of uploader.queue; let i = index">
                            <div class="info">
                                <h4 class="name">
                                    {{ fileItem?.file?.name }}
                                </h4>
                                <p class="size" *ngIf="uploader.options.isHTML5" nowrap>
                                    {{ fileItem?.file?.size/1024/1024 | number:'.2' }} MB
                                </p>
                                <div class="progress" style="margin-bottom: 0;">
                                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                                </div>
                            </div>
                            <i class="material-icons" title="Upload" style="cursor: pointer;" (click)="fileItem.upload()" *ngIf="!fileItem.isReady && !fileItem.isUploading && !fileItem.isSuccess">upload</i>
                            <i class="material-icons text-warning" title="Cancel" style="cursor: pointer;" (click)="fileItem.cancel()" *ngIf="fileItem.isUploading">cancel</i>
                            <i class="material-icons text-danger" title="Remove" style="cursor: pointer;" (click)="fileItem.remove()">delete</i>
                        </div>
                    </div>
                    <div class="files-list" *ngIf="documentSelected.uploadDetails != null">
                        <!-- <p>Queue length: {{ documentSelected.uploadDetails?.queue?.length }}</p> -->
                        <div class="single-file" *ngFor="let fileItem of documentSelected.uploadDetails?.queue; let i = index">
                            <div class="info">
                                <h4 class="name">
                                    {{ fileItem?.file?.name }}
                                </h4>
                                <p class="size" *ngIf="uploader.options.isHTML5" nowrap>
                                    {{ fileItem?.file?.size/1024/1024 | number:'.2' }} MB
                                </p>
                                <div class="progress" style="margin-bottom: 0;">
                                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': documentSelected.uploadDetails.progress + '%' }"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
            <div class="spinner-overlay" *ngIf="isLoading">
                <div class="spinner"></div>
              </div>
        <!-- </div> -->
        <div class="row row-padding">
            <button mat-button class="form-button-center" type="submit" (click)="finish()">Submit</button>
        </div>
    </div>
</div>