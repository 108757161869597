import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ProductSelectService } from 'app/ts/services/product-select.service';
import { IdentificationTypeService } from 'app/ts/services/identification-type.service';
import { PersonalDetailsComponent } from 'app/forms/broker-portal/personal-details/personal-details.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataSharingService } from 'app/data-sharing-service';
import { ApiService } from 'app/ts/ApiService';
import { FindClient, LoanQualification } from 'app/ts/models/broker-classes';
import { delay } from 'rxjs/operators';


@Component({
  selector: 'app-client-capture',
  templateUrl: './client-capture.component.html',
  styleUrls: ['./client-capture.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-50%)' }),
        animate('100ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('100ms ease-in', style({ transform: 'translateX(-50%)' }))
      ])
    ])
  ]
})

@ViewChild('picker', { read: undefined, static: false })
export class ClientCaptureComponent implements OnInit, AfterViewChecked {

  @ViewChild('loanAmountSlider') loanAmountSlider: ElementRef;
  @ViewChild('salaryDateSlider') salaryDateSlider: ElementRef;

  onClientAddressTabActivated() {
    console.log("get address")
  }
  loanAmount: number = 0;
  salaryDate: number = 0;
  clientDetailsForm: FormGroup;
  bankDetails: FormGroup;
  employmentDetails: FormGroup;
  uploadDocumentsForm: FormGroup;
  selectedIndex: number;
  formStep: number;
  currentRoute: string;
  showProductSelection: boolean = true;
  clientId: number;
  isPassport$ = this._identityType.isPassport;
  productList$ = this._productSelect.productList;
  @ViewChild('clientDetails') clientDetails: PersonalDetailsComponent;
  formDataEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() clientAffordabilityDataEvent: EventEmitter<any>;

  showApprovalPopup = false;
  showDeclinePopup = false;
  loading = false;
  installmentAmount:number = 0;

   totalInterestAndFees: number = 0;

   existingClient: boolean = false;
   existingClientHideFields:boolean = false;
  // loanRepayment:number =0;
  // interestRate: number = 13;
  // loanTermMonths: number = 1;

  declineMessage: string;
  approvalMessage: string;

  maxLoan:number = 4000;
  minLoan:number = 500;
  returnedClient:boolean = false;
  loanInterestRate:number = 0;


  onFormSubmitted(formData: FormGroup) {
    // Pass the form data to PersonalDetailsComponent
    //this.clientDetails.receiveFormData(formData);
    this.formDataEvent.emit(formData);
  }

  updateLoanAmount(event: any): void {
    this.loanAmount = event.target.value;
    this.interestAndFeesCalculations(this.loanAmount);
  }

  updateSalaryDate(event: any): void {
    this.salaryDate = event.target.value;
    this.interestAndFeesCalculations(this.loanAmount);
  }


  ///
  loanForm:FormGroup;
  personalDetailsForm: FormGroup;
  oldLoanPaidSuccessful:boolean = false;

  
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _productSelect: ProductSelectService,
    public _identityType: IdentificationTypeService,
    private api: ApiService,
    private dataSharingService: DataSharingService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {

    this.existingClient = this.evaluateData(this.clientAffordabilityDataEvent);

    this.setPersonalDetailsForm();

    if (sessionStorage.length > 0) {
      let client = JSON.parse(sessionStorage.getItem("ClientInfo"));
      let clientCreditScore = client.creditScore;

      if (client) {
        const state = history.state as { loanAmount: number, salaryDate: number };
    
        if (state && state.loanAmount !== undefined) {
          this.loanAmount = Number(state.loanAmount);
          this.salaryDate = state.salaryDate;
    
        }
        this.existingClient = true;
        this.existingClientHideFields = true;

        this.personalDetailsForm.patchValue({
          idNumber: client.client.externalId,
          cellNumber: client.client.mobileNo,
          name: client.client.firstname,
          surname: client.client.lastname,
          loanamount: this.loanAmount,
          salaryDate: this.salaryDate
        });
        let loans = client.loans.forEach((loan) => {
          if (
            loan.loanStatus === 706 ||
            loan.loanStatus === 707 ||
            loan.loanStatus === 704 ||
            loan.loanStatus === 600
          ) {
            this.oldLoanPaidSuccessful = true;
            this.returnedClient = true;
            // this.trustLevels(Number(clientCreditScore));
          }
        });
      }
    }
    
    this.interestAndFeesCalculations(this.loanAmount);



    this._productSelect.updateLoanAmount(0);
    this._productSelect.updateLoanTerm(0);
    this._productSelect.updateLoanType("Short Term Loan");

    this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));

    this.personalDetailsForm.get('idNumber').valueChanges.subscribe(value => {
      this.personalDetailsForm.get('idNumber').updateValueAndValidity();
    });

    this.selectedIndex = 0;
  }

  ngAfterViewInit(): void {
    this.setSliderValue();
    this.setSalarySliderValue();
  }

  setSliderValue(): void {
    const slider = this.loanAmountSlider.nativeElement as HTMLInputElement;
    if (slider) {
      slider.value = this.loanAmount.toString();
      slider.min = this.minLoan.toString();
      slider.max = this.maxLoan.toString();
      this.updateSlider({ target: slider });
    }
  }

  setSalarySliderValue(): void {
    const slider = this.salaryDateSlider.nativeElement as HTMLInputElement;
    if (slider) {
      slider.value = this.salaryDate.toString();
      slider.min = "1";
      slider.max = "31";
      this.updateSlider({ target: slider });
    }
  }

  evaluateData(data: any): boolean {
    // Replace the following condition with your actual condition
    return data;
  }

  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    // Check if the click is outside the popup
    if (
      this.showApprovalPopup && !targetElement.closest('.popup-content') ||
      this.showDeclinePopup && !targetElement.closest('.popup-content') 
      
    ) {
      this.closeAllPopups();
    }
  }

  closeAllPopups() {
    this.showApprovalPopup = false;
    this.showDeclinePopup = false;
    this.loading = false;
  }

  

    onTabChange(event: MatTabChangeEvent) {
    // Code to run when the tab changes
    if (event.index === 1) { // Check if Tab 2 is selecte
    }
  }

  updateSlider(event) {
    const value = ((event.target.value - event.target.min) / (event.target.max - event.target.min)) * 100;
    event.target.style.setProperty('--value', `${value}%`);
    }


  trustLevels(creditScore){

    this.minLoan = 500;
    this.maxLoan = 1000;

    if (creditScore >= 520 && creditScore <= 529) {
      this.minLoan = 500;
      this.maxLoan = 1500;
    }
    if (creditScore >= 530 && creditScore <= 539) {
      this.minLoan = 500;
      this.maxLoan = 2000;
    }
    if (creditScore >= 540 && creditScore <= 550) {
      this.minLoan = 500;
      this.maxLoan = 2500;
    }
    if (creditScore >= 550 && creditScore <= 649) {
      this.minLoan = 500;
      this.maxLoan = 3500;
    }
    if (creditScore >= 650) {
      this.minLoan = 500;
      this.maxLoan = 4000;
    }
  }



  ngAfterViewChecked(): void {
    var staffId = sessionStorage.getItem('staffId');
    //capture logic
    if(Number(sessionStorage.getItem("clientId")) == 0){

      //search logic
      this.route.params.subscribe(params => {
      if (this.clientId != params['clientId']) {
        this.clientId = +params['clientId'];
        
        sessionStorage.setItem('staffId', this.clientId.toString());
        
          this.showProductSelection = true;

      }
    });
    }
      this.showProductSelection = true;
  }


  findClientTriggered() {
    var idNumber = sessionStorage.getItem('searchClientID');

    if (idNumber != '' && idNumber != null) {
      this.showProductSelection = false;
    }
    else {
      this.showProductSelection = true;
    }
  }

  public previousStep() {
    this.selectedIndex -= 1;
  }

  updateStep() {
    
    if (this.selectedIndex == 5) {
      this.reload();
    }
    else {
      this.selectedIndex += 1;
    }
  }

  onChange() {
    this.clientId = Number(sessionStorage.getItem("clientId"));

    sessionStorage.setItem("ClientTokenDetails", JSON.stringify(this.clientDetails));
    
  }

  reload() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./'], { relativeTo: this.route });
  }

  visitWebsite(){
    this.showDeclinePopup = false;
    window.location.href = 'https://lndr.co.za/contact/'
  }

  validateID(idNumber: string): boolean {
    if (idNumber.length !== 13) return false;

    const dateString = idNumber.substring(0, 6);
    const year = parseInt(dateString.substring(0, 2), 10);
    const month = parseInt(dateString.substring(2, 4), 10) - 1;
    const day = parseInt(dateString.substring(4, 6), 10);

    const date = new Date(year < 50 ? 2000 + year : 1900 + year, month, day);
    if (date.getFullYear() !== (year < 50 ? 2000 + year : 1900 + year) || date.getMonth() !== month || date.getDate() !== day) {
        return false;
    }

    if (parseInt(idNumber.charAt(10), 10) > 1) return false;

    let oddString = "";
    let evenString = "";
    let doubleEven: number;

    for (let i = 0; i < 12; i += 2) {
        oddString += idNumber.charAt(i);
    }

    const sumOdd = oddString.split('').reduce((acc, digit) => acc + parseInt(digit, 10), 0);

    for (let i = 1; i < 12; i += 2) {
        evenString += idNumber.charAt(i);
    }
    doubleEven = parseInt(evenString, 10) * 2;

    const sumDoubleEven = doubleEven.toString().split('').reduce((acc, digit) => acc + parseInt(digit, 10), 0);

    const sumResults = sumOdd + sumDoubleEven;
    let check = 10 - (sumResults % 10);

    if (check === 10) check = 0;

    return idNumber.charAt(12) === check.toString();
}



  setPersonalDetailsForm() {
    
    this.personalDetailsForm = this.formBuilder.group({
      idNumber: ['', [Validators.required]],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      // email: ['test@gmail.com', [Validators.email, Validators.required]],
      cellNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      mobileNumber: '',
      country: [''],
      dateOfBirth: [''],
      dateOfIssue: [''],
      expiryDate: [''],
      creditCheckConsent: [false, [Validators.requiredTrue]],
      existingClientCheckbox: [false],
      loanterm:[''],
      loanamount: [{ value: 500, disabled: false }, [Validators.required, Validators.min(500)]],
      salaryDate: [{ value: 1, disabled: false }, [Validators.required, Validators.min(1)]],
      staffId: 718,
    });
  }


  interestAndFeesCalculations(loanAmount: number) {
    let amount = Number(loanAmount);
    let salaryDate = this.personalDetailsForm.get('salaryDate').value;
    let loanTerm = this.calculateDateDifference(salaryDate);

    let interestRate: number;
    let interest: number = 0;
    let adminFee: number = 69; // Admin fee is R 69.00 VAT inclusive
    let initiationFee: number = 0;
    let initiationFeeVAT: number = 0;
    let loanAndFees: number = 0;

    // Interest rate based on whether the client is existing or not
    interestRate = this.oldLoanPaidSuccessful ? 3 / 100 : 5 / 100;
    this.loanInterestRate = interestRate;

    // Calculate initiation fee and VAT
    if (amount <= 1000) {
        initiationFee = amount * 15 / 100;
        initiationFeeVAT = initiationFee * 15 / 100;
    } else {
        let baseFee = 165;
        let additionalFee = (amount) * 0.1;
        initiationFee = baseFee + additionalFee;
        initiationFee = Math.min(initiationFee, 1050); // Max fee capped at R 1050
        initiationFeeVAT = initiationFee * 15 / 100;
    }

    loanAndFees = amount + initiationFee + initiationFeeVAT;
    
    let annualInterestRate = interestRate * 12;

    interest = (((loanAndFees * annualInterestRate)/365) * loanTerm);

    // Total interest and fees
    this.totalInterestAndFees = interest + adminFee + initiationFee + initiationFeeVAT;
}
  
  
  calculateDateDifference(salaryDay) {
    let sysDate = new Date();
    let currentDay = sysDate.getDate();
    let dayDifference;

    if (currentDay <= salaryDay) {
        dayDifference = salaryDay - currentDay;
    } else {
        dayDifference = (31 - currentDay) + salaryDay;
    }

    if(dayDifference <= 10) {
      dayDifference = dayDifference + 30;
    }

    return dayDifference;
  }

  format(value: number) {
    return value.toFixed(2).toLocaleString();
  }
  idValid:boolean;


  existingClientTick() {
    this.existingClient = this.personalDetailsForm.get('existingClientCheckbox')?.value;
  }


  submit() {

    this.idValid = this.validateID(this.personalDetailsForm.controls.idNumber.value);
    if (!this.idValid) return;

    if (this.personalDetailsForm.valid) {
      this.loading = true;
  
      const formData = this.personalDetailsForm.value;
      const clientDetails: FindClient = {
        firstName: formData.name,
        lastName: formData.surname,
        externalID: formData.idNumber,
        mobileNo: formData.cellNumber,
        email: formData.email,
        staffId: 718,
        isPassport: false,
        dateOfIssue: '',
        expiryDate: '',
        dateOfBirth: '',
        country: 0,
        clientId: 0,
        loanStatusId: 0,
        loanAmount:formData.loanamount
      }

      var sessionStorageClient = JSON.parse(sessionStorage.getItem("ClientInfo"));
  

      this.api.verifyClientLoanQualification(clientDetails).pipe(delay(1500)).subscribe((data: LoanQualification) => {
        this.loading = false;
        if (data.clientqualifiesForLoan) {
          if (data.existing && this.existingClient && !sessionStorageClient) {
            this.approvalMessage = "We have detected that you are an existing LNDR Client, and you may qualify for better rates. Please click on Ok to proceed."
            this.showApprovalPopup = true;
          } else {
            this.existingClient = false;
            const formattedLoanAmount = this.formatCurrency(Number(this.loanAmount));
            const formattedInstallment = this.formatCurrency(Number(this.loanAmount) + Number(this.totalInterestAndFees));
            this.approvalMessage = `You've been provisionally approved for a loan of ${formattedLoanAmount} over 1 month with an installment of ${formattedInstallment}`;
            var newLoan = true;
            sessionStorage.setItem("newLoan", JSON.stringify(newLoan));
            // this.approvalMessage = `You've been provisionally approved for a loan of R ${Number(this.loanAmount).toFixed(2)} over 1 month with an installment of R ${(Number(this.loanAmount) + Number(this.totalInterestAndFees)).toFixed(2)}`;
            this.showApprovalPopup = true;
          }
        } else {
          if (data.message === "Failed Prevet Check"){
            this.declineMessage = "Regrettably, your application did not meet the minimum requirements and, as a result, has been declined"
          }
          if (data.message === "Loan Rejected"){
            this.declineMessage = "We regret to inform you that we are unable to process your application at this time due to your current loan status with us. Please contact us for further assistance";
          }
          if (data.message === "Active Loan") {
            this.declineMessage = "Your loan application has been declined as you currently have an outstanding loan that must first be repaid. Please contact us for further assistance."
          }
          if (data.message === "Age not valid"){
            this.declineMessage = "Unfortunately, our services are limited to users between the ages of 18 and 65 years";
          }
          if (data.message === "Judgment") {
            this.declineMessage = "We regret to inform you that we are unable to proceed with your application at this time, as our review has indicated active judgments on your credit bureau profile."
          }
          if (data.message === "Debt Review") {
            this.declineMessage = "We regret to inform you that we are unable to proceed with your application at this time, as our review has indicated that you are currently under debt review on your credit bureau profile."
          }
          if (data.message === "Deceased") {
            this.declineMessage = "We regret to inform you that we are unable to proceed with your application at this time, due to our internal policy rules."
          }
          this.showDeclinePopup = true;
        }
      });
  
     
    }else{
      this.personalDetailsForm.markAllAsTouched();
    }
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
        minimumFractionDigits: 2
    }).format(value).replace('ZAR', 'R');
}


  existingClientNavigation() {
    let mobileNumber = this.personalDetailsForm.controls['cellNumber'].value;
    this.loanAmount = this.personalDetailsForm.controls['loanamount'].value;
    this.salaryDate = this.personalDetailsForm.controls['salaryDate'].value;
    this.router.navigate(['/login'], { state: { mobilenumber: mobileNumber, loanamount: this.loanAmount, salarydate: this.salaryDate } });
  }
  

  gotoUpdateClient() {
    this.personalDetailsForm.get('mobileNumber').patchValue(this.personalDetailsForm.get("cellNumber").value)
    localStorage.setItem("clientDetailsData", JSON.stringify(this.personalDetailsForm.value));
    sessionStorage.setItem('authenticated', 'true');
    const existing = { existing: this.existingClient, return: this.returnedClient, interest: this.loanInterestRate, message: "new loan" };
    this.router.navigate(['/broker/update-client'], { state: { data: existing } });
}

getDayWithSuffix(day: number): string {
  if (day > 3 && day < 21) return `${day}th`; // 4th to 20th

  switch (day % 10) {
    case 1:
      return `${day}st`; // 1st, 21st, 31st
    case 2:
      return `${day}nd`; // 2nd, 22nd
    case 3:
      return `${day}rd`; // 3rd, 23rd
    default:
      return `${day}th`; // 4th, 5th, 6th, ...
  }
}

  
}
