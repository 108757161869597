
import { BrokerComponentsModule } from './components/broker-portal/broker-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { MatDialogModule } from '@angular/material/dialog';
// import { AdminLayoutComponent } from './layouts/admin-portal/admin-layout/admin-layout.component';
// import { ClientLayoutComponent } from './layouts/client-portal/client-layout/client-layout.component';
import { BrokerLayoutComponent } from './layouts/broker-portal/broker-layout/broker-layout.component';
// import { AuthenticateClientLayoutComponent } from './layouts/client-portal/authenticate-client-layout/authenticate-client-layout.component';
// import { AuthenticateAdminLayoutComponent } from './layouts/admin-portal/authenticate-admin-layout/authenticate-admin-layout.component';
import { AuthenticateBrokerLayoutComponent } from './layouts/broker-portal/authenticate-broker-layout/authenticate-broker-layout.component';
import { AuthenticateFormsModule } from './forms/broker-portal/authenticate/authenticate-forms.module';
import { AuthenticateClientLayoutComponent } from './layouts/client-portal/authenticate-client-layout/authenticate-client-layout.component';
import { ClientLayoutComponent } from './layouts/client-portal/client-layout/client-layout.component';
import { ChartsModule } from 'ng2-charts';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule, MatCardTitle } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CurrencyFormatterComponent } from './components/currency-formatter/currency-formatter.component';
import { CurrencyFormatterModule } from './components/currency-formatter/currency-formatter.component.module';
import { FileUploadModule } from 'ng2-file-upload';
import { DataSharingService } from './data-sharing-service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { CancelledPageComponent } from './cancelled-page/cancelled-page.component';
import { NotifyPageComponent } from './notify-page/notify-page.component';
import { SuccessPageComponent } from './success-page/success-page.component';

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrokerComponentsModule,
    AuthenticateFormsModule,
    ChartsModule,
    MatDialogModule,
    FontAwesomeModule,
    SweetAlert2Module.forRoot(),
    MatSnackBarModule,
    MatCardModule,
    MatProgressSpinnerModule,
    CurrencyFormatterModule,
    TabsModule.forRoot(),
    CommonModule,
    FileUploadModule
  ],
  declarations: [
    AppComponent,
    AuthenticateBrokerLayoutComponent,
    AuthenticateClientLayoutComponent,
    // AuthenticateAdminLayoutComponent, 
    BrokerLayoutComponent,
    ClientLayoutComponent,
    ErrorPageComponent,
    CancelledPageComponent,
    NotifyPageComponent,
    SuccessPageComponent,
    // AdminLayoutComponent,   
  ],
  providers: [AuthService,DataSharingService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
