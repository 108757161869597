import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataSharingService } from 'app/data-sharing-service';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { ApiService } from 'app/ts/ApiService';

@Component({
  selector: 'app-client-address',
  templateUrl: './client-address.component.html',
  styleUrls: ['./client-address.component.scss', '../../forms.shared.scss']
})
export class ClientAddressComponent implements OnInit {
  addressForm: FormGroup;
  loading: boolean = false;
  AddressObjects: any;
  StateOptions: any;
  addressTypeIdOptions: any;
  countryIdOptions: any;
  clientProfile: any;
  EditProfile: boolean;
  clientId: string;
  clientAddressSet: boolean;
  @Output() updateStepEvent = new EventEmitter<number>();

  constructor(private fb: FormBuilder, private api: ApiService, private snackbar: MatSnackBar, private dataSharingService: DataSharingService) {

    this.api.getAddressCountries().subscribe((data: any) => {
      this.AddressObjects = data;
      this.StateOptions = data.stateProvinceIdOptions;
      this.addressTypeIdOptions = data.addressTypeIdOptions;
      this.countryIdOptions = data.countryIdOptions;


    });

  }

  ngOnInit(): void {

    this.clientProfile = JSON.parse(sessionStorage.getItem('ClientInfo'));
    if (this.clientProfile != null) {
      this.EditProfile = true;
      this.processForm();
      return;
    }

    this.processForm();

    this.addressForm.controls['countryId'].setValue(50)
  }

  onSubmit() {
    const formData = this.addressForm.value;
    //const address: Address = { ...formData };
  }

  save() {
    this.addressForm.markAllAsTouched();

    const clientAddress = this.addressForm.value;
    this.clientId = sessionStorage.getItem('clientId');
    clientAddress.clientId = this.clientId


    if (this.EditProfile) {
      this.loading = true;
      let sessionStorageAddress = JSON.parse(sessionStorage.getItem('ClientInfo'));
      if (sessionStorageAddress.addresses.length != 0) {
        clientAddress.id = sessionStorageAddress.addresses[0].id;
        this.api.updateClientAddress(clientAddress).subscribe((data: any) => {
          this.openSnackBar("Address details saved!", 'success-snackbar');
          this.updateStepEvent.emit();
        });
      } else {
        this.api.saveClientAddress(clientAddress).subscribe((data: any) => {
          this.openSnackBar("Address details saved!", 'success-snackbar');
          this.updateStepEvent.emit();
        });
      }

    } else {

      this.api.saveClientAddress(clientAddress).subscribe((data: any) => {
        this.loading = true;
        //todo: add error handling
        this.openSnackBar("Address details saved!", 'success-snackbar');
        this.updateStepEvent.emit();
      });
    }
  }


  ngAfterViewChecked(): void {
    this.dataSharingService.ClientInformation$.subscribe(details => {
      if (details != null && !this.EditProfile && !this.clientAddressSet) {
        this.clientAddressSet = true;
        const clientAddress = details.addresses[details.addresses.length - 1];
        this.addressForm.patchValue({
          addressLine1: clientAddress.addressLine1,
          addressLine2: clientAddress.addressLine2,
          addressLine3: clientAddress.addressLine3,
          addressTypeId: clientAddress.addressTypeId,
          city: clientAddress.city,
          countryId: clientAddress.countryId,
          countryName: clientAddress.countryName,
          postalCode: clientAddress.postalCode,
          stateId: clientAddress.stateId,
          street: clientAddress.street,
        });
      }
    });
  }

  getClientInfo(id: string, data: any) {
    this.api.GetFullClientInfoById(id).subscribe((data: any) => {
      if (data != null) {
        var json = JSON.stringify(data);
      }

    })
  }


  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  processForm() {
    if (this.EditProfile) {
      const clientAddress = this.clientProfile.addresses[0];
      this.addressForm = this.fb.group({
        addressLine1: ['', Validators.required],
        addressLine2: [''],
        addressLine3: [''],
        addressTypeId: ['', Validators.required],
        city: ['', Validators.required],
        clientId: [''],
        countryId: ['', Validators.required],
        countryName: [''],
        postalCode: ['', Validators.required],
        stateId: ['', Validators.required],
        street: ['', Validators.required],
      });

      this.addressForm.patchValue({
        addressLine1: clientAddress.addressLine1,
        addressLine2: clientAddress.addressLine2,
        addressLine3: clientAddress.addressLine3,
        addressTypeId: clientAddress.addressTypeId,
        city: clientAddress.city,
        clientId: clientAddress.clientId,
        countryId: clientAddress.countryId,
        countryName: clientAddress.countryName,
        postalCode: clientAddress.postalCode,
        stateId: clientAddress.stateId,
        street: clientAddress.street,
        id: clientAddress.id
      });

      // this.addressForm = this.fb.group({
      //   addressLine1: [clientAddress.addressLine1,Validators.required],
      //   addressLine2: [clientAddress.addressLine2],
      //   addressLine3: [clientAddress.addressLine3],
      //   addressTypeId: [clientAddress.addressTypeId,Validators.required],
      //   city: [clientAddress.city,Validators.required],
      //   clientId: [''],
      //   countryId: [clientAddress.countryId,Validators.required],
      //   countryName: [clientAddress.countryName],
      //   postalCode: [clientAddress.postalCode,Validators.required],
      //   stateId: [clientAddress.stateId,Validators.required],
      //   street: [clientAddress.street,Validators.required],
      //   id:[clientAddress.id]
      // });

    } else {
      this.addressForm = this.fb.group({
        addressLine1: ['', Validators.required],
        addressLine2: [''],
        addressLine3: [''],
        addressTypeId: ['', Validators.required],
        city: ['', Validators.required],
        clientId: [''],
        countryId: ['', Validators.required],
        countryName: [''],
        postalCode: ['', Validators.required],
        stateId: ['', Validators.required],
        street: ['', Validators.required],
      });
    }

  }
}
