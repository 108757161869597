import { ClientResult, LoanQualification, clientAffordabilityData } from './../models/broker-classes';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

import { AccountTypes, ApprovalRecords, Authentication, Banks, Countries, PreVetRecords, ProcessedRecords, BatchRecords, Staff, ResetPassword, LoansToApprove, Products } from '../models/broker-interfaces';
import { LoansRecords } from '../models/client-interfaces';
import { OtpLoan, OtpLogin, PasswordDetails, UserCredentials } from '../models/user-credentials';
import {
  EmployerDetails,
  Report,
  BankingDetails,
  SaveClientForCallback,
  Consent,
  Acknowledgments,
  FindClient
} from '../models/broker-classes';
import { response } from 'express';
import { Approval, Decline, loanAmountRates, LoanApplication, Reject } from '../models/loan-classes';

const API_URL = environment.apiURL;
const FINDUIS = environment.finduis;


class batchFileRequest {
  BatchFile: string;
  token: string;
  staffId: number
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationRepo {



  constructor(private http: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Fineract-Platform-TenantId': 'default',
        'Authorization': 'Basic amVyZW15OnBhc3N3b3Jk'
      }
    )
  }

  public getProducts(staffId: number) {
    return this.http.get(`${API_URL}Portal/getProducts/${staffId}`).pipe(
      map((data: Products[]) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public getLoansToApprove(staffId: number) {
    return this.http.get(`${API_URL}Portal/getLoansToApprove/${staffId}`).pipe(
      map((data: LoansToApprove) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public getNotes(loanId: number) {
    return this.http.get(`${API_URL}Portal/getLoanNotes/${loanId}`).pipe(
      map((data: any) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public getInitiations() {
    return this.http.get(API_URL + `Access/Initiations`).pipe(
      map((data: loanAmountRates[]) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public getCountriesList() {
    return this.http.get(API_URL + 'Portal/getCountriesList').
      pipe(
        map((data: Countries[]) => {
          return data;
        }), catchError(error => {
          return of(error.error);
        })
      )
  }

  public getStaffList() {
    return this.http.get(API_URL + 'Portal/getStaffList').
      pipe(
        map((data: Staff[]) => {
          return data;
        }), catchError(error => {
          return of(error.error);
        })
      )
  }

  public getBanks() {
    return this.http.get(API_URL + 'GetBankNames').pipe(
      map((data: Banks[]) => {
        return data;
      }), catchError(error => {
        return of(error.error);
      })
    )
  }

  public getAccountTypes() {
    return this.http.get(API_URL + 'GetAccountTypes').pipe(
      map((data: AccountTypes[]) => {
        return data;
      }), catchError(error => {
        return of(error.error);
      })
    )
  }

  public getClientsByStaffId() {
    var url = `${API_URL}Portal/getClientsByStaffId`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }), catchError(error => {
        return of(error.error);
      })
    )
  }

  public generatePrevetReport(reportRequest: Report) {
    var test = API_URL + `Portal/generatePrevetReport/${reportRequest.staffId}/${reportRequest.idNumber}`;
    return this.http.get(test).pipe(
      map((data: PreVetRecords[]) => {
        return data;
      }), catchError(error => {
        return of(error.error);
      })
    )
  }

  public generateProcessReport(reportRequest: Report) {
    var url = API_URL + `Portal/generateProcessReport/${reportRequest.staffId}/${reportRequest.source}/${reportRequest.idNumber}`;
    return this.http.get(url).pipe(
      map((data: ProcessedRecords[]) => {
        return data;
      }), catchError(error => {
        return of(error.error);
      })
    )
  }

  public generateApprovalReport(reportRequest: Report) {
    var url = API_URL + `Portal/generateApprovalReport/${reportRequest.staffId}/${reportRequest.idNumber}`;
    return this.http.get(url).pipe(
      map((data: ApprovalRecords[]) => {
        return data;
      }), catchError(error => {
        return of(error.error);
      })
    )
  }

  public getDeclinedLetter(reportRequest: Report) {
    var url = API_URL + `Portal/getLetterOfDecline/${reportRequest.idNumber}`
    return this.http.get(url, { responseType: 'text' }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(error => {
        return of(error.error);
      }));
  }

  public getLoansList(clientId: number) {
    return this.http.get(API_URL + `Portal/getLoans?clientId=${clientId}`).
      pipe(
        map((data: LoansRecords[]) => {
          return data;
        }), catchError(error => {
          return of(error.error);
        })
      )
  }

  getLoanPreeAgreement(clientId: number, id: number) {
    return this.http.get(API_URL + `Access/LndrGetPreAgreementFromClientId/${clientId}/${id}`).
    pipe(
      map((data: any) => {
        return data;
      }), catchError(error => {
        return of(error.error);
      })
    )
}

viewLoanPreAgreement(clientId: number,id: number)
{
  return this.http.get(API_URL + `Access/GetPreAgreementFromClientId/${clientId}/${id}`).
  pipe(
    map((data: any) => {
      return data;
    }), catchError(error => {
      return of(error.error);
    })
  )
}

signLoanPreeAgreement(otpLoan: OtpLoan) {
  return this.http.get(API_URL + `Access/LndrSignPreAgreementFromClientId/${otpLoan.otp}/${otpLoan.id}/${otpLoan.loanId}`).
  pipe(
    map((data: any) => {
      return data;
    }), catchError(error => {
      return of(error.error);
    })
  )
}



  public getClientByIdPassport(clientIdPassport: string) {
    return this.http.get(API_URL + `Portal/getClientByIdNumber?clientIdPassport=${clientIdPassport}`).
      pipe(
        map((data: FindClient[]) => {
          return data;
        }), catchError(error => {
          return of(error.error);
        })
      )
  }

  public getBankDetails(clientId: string) {
    return this.http.get(API_URL + `Portal/BrokerBankDetails/Get?clientId=${clientId}`).
      pipe(
        map((data: BankingDetails[]) => {
          return data;
        }), catchError(error => {
          return of(error.error);
        })
      )
  }

  public getEmployerDetails(clientId: string) {
    return this.http.get(API_URL + `Portal/EmployerBroker/Get?clientId=${clientId}`).
      pipe(
        map((data: EmployerDetails[]) => {
          return data;
        }), catchError(error => {
          return of(error.error);
        })
      )
  }

  public getConsent(clientId: string) {
    return this.http.get(API_URL + `Portal/BrokerConsent/Get?clientId=${clientId}`).
      pipe(
        map((data: Consent[]) => {
          return data;
        }), catchError(error => {
          return of(error.error);
        })
      )
  }

  public getAcknowledgements(clientId: string) {
    return this.http.get(API_URL + `Portal/BrokerAcknowledgements/Get?clientId=${clientId}`).
      pipe(
        map((data: Acknowledgments[]) => {
          return data;
        }), catchError(error => {
          return of(error.error);
        })
      )
  }

  public Login(credentials: UserCredentials) {
    // return this.http.post<Authentication>(`${FINDUIS}authentication?tenantIdentifier=default&username=${credentials.username}&password=${credentials.password}`, this.httpOptions).pipe(
    //   map((data: any) => {
    //     return data;
    //   }), catchError((response) => {
    //     return of(response.error);
    //   })
    // )



  }

  public generateLoginOtp(credentials: OtpLogin) {
    return this.http.post(`${API_URL}OTP/RequestLogin2FA`, credentials).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((response) => {
        return of(response.error)
      })
    )
  }

  public generateCreditCheckOtp(credentials: OtpLogin) {
    return this.http.post(`${API_URL}OTP/RequestCreditCheckOtp`, credentials).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((response) => {
        return of(response.error)
      })
    )
  }

  generateLoanPreeagreementOtp(OtpLogin: OtpLogin) {
    return this.http.post(`${API_URL}OTP/RequestLoanPreagrementOtp`, OtpLogin).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((response) => {
        return of(response.error)
      })
    )
  }

  public validateLoginOtp(credentials: OtpLogin) {
    return this.http.post(`${API_URL}OTP/ValidateLogin2FA`, credentials).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((response) => {
        return of(response.error)
      })
    )
  }

  public GetUser(userId: number) {
    return this.http.get(`${FINDUIS}users/${userId}`, this.httpOptions).pipe(
      map((data: any) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public GetFullClientInfo(mobilenumber: string) {
    return this.http.get(`${API_URL}Access/getFullClientInfoByMobileNumber/${mobilenumber}`, this.httpOptions).pipe(
      map((data:any) => {
        return data;
      }), catchError((response) => {
        return of (response.error);
      })
    )
  }

  public GetFullClientInfoById(clientId: any) {
    return this.http.get(`${API_URL}Access/GetFullClientInfoByClientId/${clientId}`, this.httpOptions).pipe(
      map((data:any) => {
        return data;
      }), catchError((response) => {
        return of (response.error);
      })
    )
  }

  public doCreditCheck(clientId: number) {
    return this.http.get(`${API_URL}Access/doCreditCheck?clientId=${clientId}`, this.httpOptions).pipe(
      map((data: any) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public getCreditBureauAssessment(clientId: number) {
    return this.http.get(`${API_URL}Access/getCreditBureauAssessment/${clientId}`, this.httpOptions).pipe(
      map((data: any) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  //API: POST

  public approveLoan(approval: Approval) {
    return this.http.post(`${API_URL}Portal/BrokerApproval`, approval).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((response) => {
        return of(response.error)
      })
    )
  }

  public declineLoan(decline: Decline) {
    return this.http.post(`${API_URL}Portal/BrokerDecline`, decline).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((response) => {
        return of(response.error)
      })
    )
  }

  public rejectLoan(reject: Reject) {
    return this.http.post(`${API_URL}Portal/BrokerReject`, reject).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((response) => {
        return of(response.error)
      })
    )
  }

  public submitLoanApplication(loanApp: LoanApplication) {
    return this.http.post(`${API_URL}Portal/SubmitLoanApplication`, loanApp).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        return of(error.error);
      })
    )
  }

  public renewPassword(userId: number, renewedPassword: PasswordDetails) {
    return this.http.put<ResetPassword>(`${FINDUIS}users/${userId}`, renewedPassword, this.httpOptions).pipe(
      map((data: any) => {
        return data.changes.passwordEncoded;
      }), catchError((response) => {
        return of(response.error.defaultUserMessage);
      })
    )
  }

  public uploadClientFromPortal(client: SaveClientForCallback) {
    return this.http.post(`${API_URL}Portal/uploadClientFromPortal`, client).pipe(
      map((data: any) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public updateClientFromPortal(client: any) {
    return this.http.put(`${API_URL}Portal/Client/Update/${client.ClientId}`, client).pipe(
      map((data: any) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }


  public saveConsent(consent: Consent) {
    return this.http.post(`${API_URL}Portal/SaveConsent`, consent).pipe(
      map((data: any) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public saveAcknowledgments(acknowledgments: Acknowledgments) {
    return this.http.post(API_URL + 'Portal/SaveAcknowledgments', acknowledgments).pipe(
      map((data: any) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public saveBankDetails(bankDetails: BankingDetails) {
    return this.http.post(`${API_URL}Portal/addBankDetails`, bankDetails).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        return of(error.error);
      })
    )
  }

  public saveEmployerDetails(empDetails: EmployerDetails) {
    return this.http.post(`${API_URL}Portal/saveEmployerDetails`, empDetails).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        return of(error.error);
      })
    )
  }

  public uploadIdDocument(file: FormData) {
    let clientId = Number(sessionStorage.getItem('clientId'));

    return this.http.post(API_URL + `Portal/uploadClientIdDocument/${clientId}`, file).pipe(
      catchError((response) => {
        return of(response.error);
      })
    )
  }

  public uploadBatchDocument(uploadFile: batchFileRequest) {
    let clientId = Number(sessionStorage.getItem('clientId'));
    let staffId = Number(sessionStorage.getItem('staffId'));

    return this.http.post(API_URL + `broker/uploadBatchFileForClients`, uploadFile).pipe(
      map((data: BatchRecords) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    );
    
  }

  public convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = error => {
        reject(error);
      };
    });
  }

  public getHistoryBatchResults(history_date: string, startTime: string, endTime: string) {
    let staffId = Number(sessionStorage.getItem('staffId'));

    return this.http.post(API_URL + `Portal/getBatchUploadHistory/${staffId}/${history_date}/${startTime}/${endTime}`, "").pipe(
      //return this.http.post(`http://localhost:5070/Portal/getBatchUploadHistory/${staffId}/${history_date}/${startTime}/${endTime}`, "").pipe(
      map((data: BatchRecords) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public uploadDocument(file: FormData) {
    let clientId = Number(sessionStorage.getItem('clientId'));
    let staffId = Number(sessionStorage.getItem("staffId"));
    return this.http.post(API_URL + `Portal/uploadClientBankStatement/${clientId}/${staffId}`, file).pipe(
      catchError((response) => {
        return of(response.error);
      })
    )
  }

  public sendUlEmail(staffId: number, clientId: number) {
    return this.http.post(API_URL + `Portal/BrokerSendULEmail/${staffId}/${clientId}`, "").pipe(
      catchError((response) => {
        return of(response.error);
      })
    )
  }

  public saveClientAffordability(clientAffordabilityData: clientAffordabilityData,clientid:number) {
    return this.http.post(API_URL + `Broker/UploadClientAffordabilityFromPortal`, clientAffordabilityData).pipe(
      map((data: any) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public GetAddressCountries() {
    return this.http.get(API_URL + 'Access/GetAddress').pipe(
      map((data: any) => {
        return data;
      }), catchError(error => {
        return of(error.error);
      })
    )
  }

  // public GetAddressCountries() {
  //   return this.http.get(`${API_URL}Access/GetAddress`, this.httpOptions).pipe(
  //     map((data: any) => {
  //       return data;
  //     }), catchError((response) => {
  //       return of(response.error);
  //     })
  //   )
  // }

  
  public saveClientAddress(address: any) {
    return this.http.post(`${API_URL}Portal/Address/Post`, address).pipe(
      map((data: any) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public updateClientAddress(address: any) {
    return this.http.post(`${API_URL}Portal/Address/Put`, address).pipe(
      map((data: any) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }

  public getClientUsingToken(token:string) {
    return this.http.post(API_URL + `Access/GetClientToken`,{ClientToken: token} ).pipe(
      map((data: any) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }


  public verifyClientLoanQualification(client: FindClient) {
    return this.http.post(`${API_URL}Access/verify-loan-qualification`, client).pipe(
      map((data: LoanQualification) => {
        return data;
      }), catchError((response) => {
        return of(response.error);
      })
    )
  }


}


