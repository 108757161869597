import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-currency-formatter',
  templateUrl: './currency-formatter.component.html',
  styleUrls: ['./currency-formatter.component.scss']
})
export class CurrencyFormatterComponent implements OnInit {
  @Input() currencyCode: string = 'ZAR';
  @Input() symbol: string = 'R ';
  @Input() value: number = 0;
  
  constructor() { }

  ngOnInit() {
  }

}
