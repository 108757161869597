<div class="container-fluid">
  <div class="form-position">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <mat-label><b>Name:</b></mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.name}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Risk Factor:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.riskFactor}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Risk Score:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.riskScore}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Risk Category:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.riskCatagory}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Too little info indicator:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.tooLittleInfoIndicator}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Fraud Indicator:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.fraudIndicator}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Administration Indicator:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.adminIndicator}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Review Alert:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.reviewAlert}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Deceased Indicator:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.deceasedIndicator}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Has Dispute:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.hasDispute}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Decline Reason One:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.declineReasonOne}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Decline Reason Two:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.declineReasonTwo}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Decline Reason Three:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.declineReasonThree}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Decline Reason Four:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.declineReasonFour}}</mat-label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-label>Decline Reason Five:</mat-label>
        </div>
        <div class="col">
          <mat-label>{{general.declineReasonFive}}</mat-label>
        </div>
      </div>
      <br />
      <div class="row row-cols-1 row-cols-md-12">
        <mat-accordion>
          <mat-expansion-panel #accounts>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Accounts
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <table mat-table class="mat-elevation-z8" [dataSource]="dataSourceAccounts" style="box-shadow: none;">
              <ng-container [matColumnDef]="account.DbName" *ngFor="let account of columnsAccounts">
                <th mat-header-cell *matHeaderCellDef style="padding: 0 5px;"> {{account.DisplayName}}
                </th>
                <td mat-cell *matCellDef="let record"  style="padding: 0 5px;">
                  <span *ngIf="account.DbName == 'openingBalance' || account.DbName == 'currentBalance' || account.DbName == 'intallmentAmount' || account.DbName == 'overdueAmount'" [ngClass]="({'redRow': record.overdueAmount> 0 })">
                    <app-currency-formatter [value]="record[account.DbName]"></app-currency-formatter>
                  </span>
                  <span *ngIf="account.DbName != 'openingBalance' && account.DbName != 'currentBalance' && account.DbName != 'intallmentAmount' && account.DbName !='overdueAmount'" [ngClass]="({'redRow': record.overdueAmount> 0 })">{{record[account.DbName]}}</span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="accountColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: accountColumns;"></tr>
            </table>
          </mat-expansion-panel>
          <mat-expansion-panel #nlraccounts>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Nlr Accounts
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <table mat-table class="mat-elevation-z8" [dataSource]="dataSourceNlrAccounts"
              style="box-shadow: none; width:100%;">
              <ng-container [matColumnDef]="nlr.DbName" *ngFor="let nlr of columnsNlrAccounts">
                <th mat-header-cell *matHeaderCellDef style="padding: 0 5px;"> {{nlr.DisplayName}}
                </th>
                <td mat-cell *matCellDef="let record" style="padding: 0 5px;">

                  <span *ngIf="nlr.DbName == 'openingBalance' || nlr.DbName == 'currentBalance' || nlr.DbName == 'intallmentAmount' || nlr.DbName == 'overdueAmount'" [ngClass]="({'redRow': record.overdueAmount> 0 })">
                    <!-- {{record[nlr.DbName]}} -->
                    <app-currency-formatter [value]="record[nlr.DbName]"></app-currency-formatter>
                  </span>
                  <span *ngIf="nlr.DbName != 'openingBalance' || nlr.DbName != 'currentBalance' || nlr.DbName != 'intallmentAmount' || nlr.DbName != 'overdueAmount'" [ngClass]="({'redRow': record.overdueAmount> 0 })">
                    {{record[nlr.DbName]}}
                  </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="nlrAccountsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: nlrAccountsColumns;"></tr>
            </table>
          </mat-expansion-panel>
          <mat-expansion-panel #warnings>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Warnings
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <table mat-table class="mat-elevation-z8" [dataSource]="dataSourceWarnings"
              style="box-shadow: none; width:100%;">
              <ng-container [matColumnDef]="warnings.DbName" *ngFor="let warnings of columnsWarnings">
                <th mat-header-cell *matHeaderCellDef> {{warnings.DisplayName}}
                </th>
                <td mat-cell *matCellDef="let record">
                  <span>{{record[warnings.DbName]}}</span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="warningsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: warningsColumns;"></tr>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div>
        <button mat-fab class="form-button-left" style="box-shadow: none;" type="submit" (click)="back()"><i class="fa fa-arrow-left"></i></button>
        <button mat-fab class="form-button-right" style="box-shadow: none;" type="submit" (click)="forward()"><i class="fa fa-arrow-right" ></i></button>
        <button mat-fab class="form-button-mid" style="box-shadow: none;" type="submit" (click)="refresh()"><i class="fa fa-arrows-rotate"></i></button>
      </div>
    </div>
  </div>
</div>