import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdentificationTypeService {
  private readonly initialIsPassport: boolean = false;


  isPassportSubject$ = new BehaviorSubject(this.initialIsPassport);
  isPassport$ = this.isPassportSubject$.asObservable();

  constructor() { }

  updateIdentificationType(newValues: boolean) {
    if (this.isPassportSubject$.value != newValues) {
      this.isPassportSubject$.next(newValues);
    }
  }

  get isPassport() {
    return this.isPassportSubject$.value;
  }

}
