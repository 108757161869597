import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'app/ts/ApiService';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss', '../../forms.shared.scss']
})
export class ConsentComponent implements OnInit {

  constructor(private api: ApiService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar) {

    this.consentDetails = this.formBuilder.group({
      popiaConsent: [false, Validators.required],
      marketingConsent: [false, Validators.required],
    });

  }

  @Output() updateStepEvent = new EventEmitter<number>();
  consentDetails: FormGroup;
  canProceed: boolean = false;

  ngOnInit() {

    this.consentDetails = this.formBuilder.group({
      popiaConsent: [, Validators.required],
      marketingConsent: [, Validators.required],
    });
  }

  save() {

  }

  checked(event: MatCheckboxChange) {
    let popiChecked = this.consentDetails.controls["popiaConsent"].value;
    let marketingChecked = this.consentDetails.controls["marketingConsent"].value;

    this.canProceed = popiChecked && marketingChecked;
  }

}
