import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoanApplication } from './ts/models/loan-classes';
import { Products } from './ts/models/broker-interfaces';


@Injectable()
export class DataSharingService {
  private loanApplicationSubject = new BehaviorSubject<LoanApplication | null>(null);
  loanApplication$ = this.loanApplicationSubject.asObservable();

  private viewedPreAgreementSubject = new BehaviorSubject<boolean | null>(null);
  ViewedPreAgreement$ = this.viewedPreAgreementSubject.asObservable();

  private signedPreAgreementSubject = new BehaviorSubject<boolean | null>(null);
  signedPreAgreement$ = this.signedPreAgreementSubject.asObservable();

  private selectedProductSubject = new BehaviorSubject<Products | null>(null);
  selectedProduct$ = this.selectedProductSubject.asObservable();

  private ClientDetailsSubject = new BehaviorSubject<any | null>(null);
  clientDetails$ = this.ClientDetailsSubject.asObservable();

  private ClientInformationSubject = new BehaviorSubject<any | null>(null);
  ClientInformation$ = this.ClientInformationSubject.asObservable();

  setLoanApplication(loanApplication: LoanApplication) {
    this.loanApplicationSubject.next(loanApplication);
  }

  setSelectedProduct(product: Products)
  {
    this.selectedProductSubject.next(product);
  }

  setViewedPreAgreement(viewed: boolean) {
    this.viewedPreAgreementSubject.next(viewed);
  }

  setsignedPreAgreement(viewed: boolean) {
    this.signedPreAgreementSubject.next(viewed);
  }

  setClientDetails(client: any) {
    this.ClientDetailsSubject.next(client);
  }

  setClientsInformation(client:any)
  {
    this.ClientInformationSubject.next(client)
  }
}