<div class="container-fluid">
    <div class="form-position">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="fileDropZone" appDragDropFile (fileDropped)="onBatchFileDropped($event)">
                            <input type="file" #fileDropBatchFile id="fileDropBatchFile" multiple
                                (change)="batchFileBrowseHandler($event.target.files)" />
                            <img class="icon_margin" *ngIf="isLoading" style="height: 50px;"
                                src="/assets/img/uloans/loading.svg">
                            <!-- <img  style="height: 20px;" src="/assets/img/uloans/ubank-logo.svg"> -->
                            <!-- <img src="assets/img/dnd/ic-upload-file.svg" alt=""> -->
                            <div *ngIf="!isLoading">
                                <h3>Drag and drop batch file here</h3>
                                <h3>or</h3>
                                <label for="fileDropBatchFile">Browse for file</label>
                            </div>
                            <div *ngIf="isLoading" class="loading_margin">
                                <h3>Loading...</h3>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="files-list">
                            <div class="single-file" *ngFor="let file of batchFiles; let i = index">
                                <!-- <img src="assets/img/dnd/ic-file.svg" width="45px" alt="file"> -->
                                <div class="info">
                                    <h4 class="name">
                                        {{ file?.name }}
                                    </h4>
                                    <p class="size">
                                        {{ formatBytes(file?.size) }}
                                    </p>
                                    <div class="progress-cont" *ngIf="file?.progress < 100">
                                        <div class="progress" [style.width]="file?.progress + '%'">
                                        </div>
                                    </div>
                                </div>
                                <i class="material-icons" style="cursor: pointer;"
                                    (click)="deleteBatchFile(i)">delete</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>