import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-authenticate-broker-layout',
  templateUrl: './authenticate-broker-layout.component.html',
  styleUrls: ['./authenticate-broker-layout.component.scss']
})
export class AuthenticateBrokerLayoutComponent implements OnInit {
  staffId: string;
  productId: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
  }

}
