<div class="card primary-card card-style">
  <div>
    <div class="row p-4">
      <h3 class="card-title"><b>Find</b> Client</h3>
    </div>
    <div class="container-fluid">
      <div class="form-position">
        <form [formGroup]="searchClient" novalidate>
          <div class="card-body col-md-12">
            <div class="row">
              <div class="col-md-12">
                <mat-form-field appearance="standard" class="field-padding">
                  <mat-label class="mat-label-margin">ID Number / Passport number</mat-label>
                  <input matInput class="mat-textbox" input name="idNumber" input type="text"
                    formControlName="idNumber">
                  <mat-error>
                    This field is required.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row row-padding">
            <br>
            <button mat-fab class="form-button-right button-style" style="box-shadow: none;" type="submit"
              (click)="findClient()"
              [disabled]="!searchClient.valid"><i class="fa fa-arrow-right"></i></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>