<div class="container-fluid capture-card">
    <div class="form-position">
        <form [formGroup]="bankDetails" novalidate>
            <div class="card-body col-md-12">
                <div class="row mobile-padding">
                    <h3><b>Banking Details</b><img class="icon-size desktop-only" src="/assets/img/30day/icon2.png"></h3>
                </div>
                <div class="row mobile-padding">
                    <p>The following information will only be used by Lndr</p>
                  </div>
                <div class="row pl-4 pr-4 pt-4 mobile-padding">
                    <div class="col-6 center-container pr-4 custom-select-wrapper font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Bank Name</label>
                        <select id="bank" class="custom-update-textbox pl-4" (change)="onBankChange($event.target.value)" formControlName="bank">
                            <option class="select-background" *ngFor="let b of banks" [value]="b.id">
                                {{b.bankName}}
                            </option>
                        </select>                        
                    </div>
                </div>  
                <div class="row pl-4 pr-4 pt-1 mobile-padding">
                    <div class="col-6 center-container pr-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Account Number</label>
                        <input class="custom-update-textbox pl-4" required type="text" value="" placeholder="Account Number" formControlName="accountNumber"> 
                        <div *ngIf="bankDetails.get('accountNumber').invalid && bankDetails.get('accountNumber').touched" class="error-message">
                            Account Number is invalid.
                        </div>
                    </div>
                </div>  
                <div class="row pl-4 pr-4 pt-1 mobile-padding">                    
                    <div class="col-6 center-container pr-4 custom-select-wrapper font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Account Type</label>
                        <select  class="custom-update-textbox pl-4" formControlName="accountType">
                            <option class="select-background" required *ngFor="let accountType of accountTypes" [value]="accountType.id">
                                {{accountType.accountTypeName}}
                            </option>
                        </select>                        
                    </div>
                </div>
               
                <div class="row pl-4 pr-4 pt-1 mobile-padding">
                    <div class="col-6 center-container pr-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Branch Code</label>
                        <input class="custom-update-textbox pl-4" type="text" required value="" placeholder="Branch Code" formControlName="branchCode"> 
                    </div>
                </div>

            </div>
            <div class="row pl-4 ml-4 pr-4 pt-4">
                <button mat-fab class="form-button-left" style="box-shadow: none;" type="submit" (click)="save()" [disabled]="!bankDetails.valid"><div style="font-size: 18px;">Next</div></button>
            
            </div>


            <!-- <div class="row row-cols-1 row-cols-md-4">
                <div class="col">
                    <mat-form-field appearance="standard" >
                        <mat-label class="mat-label-margin">Bank</mat-label>
                        <mat-select formControlName="bank" [disabled]="clientId !== 0"  (ngModelChange)="onBankChange($event)">
                            <mat-option *ngFor="let b of banks" [value]="b.id">
                                {{b.bankName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="standard">
                        <mat-label class="mat-label-margin">Account Number</mat-label>
                        <input matInput class="mat-textbox" input name="AccountNumber" type="number" formControlName="accountNumber" autocomplete="nope">
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="standard">
                        <mat-label class="mat-label-margin">Account Type</mat-label>
                        <mat-select name="you" formControlName="accountType" [disabled]="clientId !== 0">
                            <mat-option *ngFor="let accountType of accountTypes" [value]="accountType.id">
                                {{accountType.accountTypeName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="standard">
                        <mat-label class="mat-label-margin">Branch Code</mat-label>
                        <input matInput class="mat-textbox" input name="branchCode" type="number" formControlName="branchCode" autocomplete="nope">
                    </mat-form-field>
                </div>
            </div> -->
        </form>
    </div>
</div>