<div class="popup-container">
  <div class="row d-flex justify-content-center align-contents-center text-center otpPopup">
    <div class="col-md-10">
      <div mat-dialog-content>
        <h1 mat-dialog-title>{{otpName}}</h1>
        <p class="small text-center" *ngIf="!showOtpError">{{otpDescription}}</p>
        <p class="small text-center">If you're experiencing issues or haven't received the OTP, contact us at info@lndr.credit</p>
        <p *ngIf="showOtpError" class="small text-danger">You have entered an incorrect OTP. Please try again</p>
        <mat-form-field appearance="standard">
          <mat-label style="color: white;" class="pl-4">Enter your OTP</mat-label>
          <input required class="mat-input-element mat-form-field-autofill-control" matInput [(ngModel)]="data.otp" name="otp" #otpInput="ngModel">
          <mat-error *ngIf="otpInput.invalid && otpInput.touched">
            OTP is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div mat-dialog-actions class="mt-5 mb-2 justify-content-center">
        <button class="btn-primary" mat-button [mat-dialog-close]="data.otp" (click)="onSubmit()" cdkFocusInitial>SUBMIT</button>
      </div>
      <div class="text-center">
        <span role="button" class="small" (click)="resendOTP()">Resend OTP</span>
      </div>
    </div>
  </div>
</div>



<!-- <div class="row d-flex justify-content-center align-contents-center text-center otpPopup">
  <div class="col-md-10">
    <div mat-dialog-content>
      <h1 mat-dialog-title>{{otpName}}</h1>
      <p class="small text-center" *ngIf="!showOtpError">{{otpDescription}}</p>
      <p class="small text-center">If you're experiencing issues or haven't received the OTP, contact us at info@lndr.credit</p>
      <p *ngIf="showOtpError" class="small text-danger">You have entered an incorrect OTP. Please try again</p>
      <mat-form-field appearance="standard">
        <mat-label style="color: white;" class="pl-4">Enter your OTP</mat-label>
        <input class="mat-input-element mat-form-field-autofill-control" matInput [(ngModel)]="data.otp">
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="mt-5 mb-2 justify-content-center">
      
      <button class="btn-primary" mat-button [mat-dialog-close]="data.otp"cdkFocusInitial>SUBMIT</button>
    </div>
    <div class="text-center">
      <span role="button" class="small" (click)="resendOTP()">Resend OTP</span>
      
       <br>
      <span role="button" class="small" (click)="emailOTP()">Email OTP</span> 

    </div>
  </div>
</div> -->