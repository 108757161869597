import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authenticate-client-layout',
  templateUrl: './authenticate-client-layout.component.html',
  styleUrls: ['./authenticate-client-layout.component.scss']
})
export class AuthenticateClientLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
