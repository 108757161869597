import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyFormatterComponent } from './currency-formatter.component';
import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
  declarations: [
    CurrencyFormatterComponent
  ],
  exports: [
    CurrencyFormatterComponent
  ],
  imports: [
    CommonModule,
    FileUploadModule
  ]
})
export class CurrencyFormatterModule { }
