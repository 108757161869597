<div class="mt-0 pt-0">
    <div class="row">
        <div class="col" offsetXl="6" offsetLg="4" offsetMd="0" offsetSm="0" offsetXs="0">
            <div class="text-center">
                <div class="row loan-selection-container mb-2">
                    <div class="col loan-selection-form">
                        <tabset  #tabset [justified]="true" [vertical]="false" id="loan-tabs">
                            <tab *ngIf="showShortTermLoans" id="Short" (selectTab)="onSelect('Short Term Loan')">
                                <ng-template tabHeading>
                                    <h2 class="m-0 p-0 title">Short Term Loan</h2>
                                    <div class="form-text">
                                        Up to
                                        <app-currency-formatter [value]="shortTermMaxAmt"></app-currency-formatter>
                                    </div>
                                </ng-template>
                                <div class="tab-body">
                                    <app-product-select></app-product-select>
                                </div>
                            </tab>
                            <tab *ngIf="showPersonalLoans" id="Personal"  (selectTab)="onSelect('Personal Loan')">
                                <ng-template tabHeading>
                                    <h2 class="m-0 p-0 title">Personal Loan</h2>
                                    <div class="form-text">
                                        Up to
                                        <app-currency-formatter [value]="personalMaxAmt"></app-currency-formatter>
                                    </div>
                                </ng-template>
                                <div class="tab-body">
                                    <app-product-select></app-product-select>
                                </div>
                            </tab>
                            <tab *ngIf="showDebtConLoans"  id="DebtCon"  (selectTab)="onSelect('Debt Consolodation Loan')">
                                <ng-template tabHeading class="last">
                                    <h2 class="m-0 p-0 title">Consolidation Loan</h2>
                                    <div class="form-text">
                                        Up to
                                        <app-currency-formatter [value]="debtConMaxAmt"></app-currency-formatter>
                                    </div>
                                </ng-template>
                                <div class="tab-body">
                                    <app-product-select></app-product-select>
                                </div>
                            </tab>
                        </tabset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>