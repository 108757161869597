import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'app/auth.service';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { ApiService } from 'app/ts/ApiService';
import { Products } from 'app/ts/models/broker-interfaces';
import { loanAmountRates, LoanApplication } from 'app/ts/models/loan-classes';
import { ProductSelectService } from 'app/ts/services/product-select.service';

@Component({
  selector: 'app-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss']
})
export class ProductSelectComponent implements OnInit, AfterViewChecked {

  loanApp = new LoanApplication();
  loanApplication$: LoanApplication;
  filteredProducts: Products[] = []
  productList: Products[] = []
  selectedProductId: number;

  loanAmt: number = 0;
  loanTerm: number = 0;
  loanType: string;


  step: number = 500;
  minValue: number = 0;
  maxValue: number = 0;
  currentValue: number = 0;
  term: number = 0;
  rate: number = 0;
  monthlyInstallment: number = 0
  selected: number = 1;

  autoTicks = false;
  disabled = false;
  invert = false;
  showTicks = false;
  amountStep = 1;
  amountMax = 100;
  amountMin = 0;
  loanAmount = 0;
  termStep = 1;
  termMax = 100;
  termMin = 0;
  totalInterest = 0;
  totalInterestFixed: string;
  totalFees = 0;
  totalFeesFixed: string;
  totalRepayable = 0;
  totalRepayableFixed: string;
  thumbLabel = false;
  vertical = false;
  tickInterval = 1;
  isLoggedIn = false;
  emiVal: string;
  loanApplication = new LoanApplication();

  @Output() updateStepEvent = new EventEmitter<number>();

  public loanRates: loanAmountRates[] = [];

  constructor(private api: ApiService,
    public authService: AuthService,
    private snackbar: MatSnackBar,
    private _productSelect: ProductSelectService) {
    // this.loanRates = [] as loanAmountRates[];

    this._productSelect.loanApplication$.subscribe((x: LoanApplication) => {
      if (x !== undefined || x !== null) {
        if (this.loanApp != x) {

          this.loanApp.amount = x.amount;
          this.loanApp.term = x.term;


          if (this.loanApp.selectedProduct !== x.selectedProduct) {
            this.loanApp.selectedProduct = x.selectedProduct;
            if (this.productList.length > 0) {
              this.filteredProducts = this.productList.filter(p => p.productCategory == x.selectedProduct);
              this.loanApp.type = x.type;
              this.loanSelect(x.type);
            }
          }

          if (this.loanApp.type !== x.type) {
            this.loanApp.type = x.type;
            this.loanSelect(x.type);
          }

        }

      }
    });
    this._productSelect.productList$.subscribe((x: Products[]) => {
      if (x !== undefined || x !== null) {
        if (this.productList != x) {
          this.productList = x;
          this.filteredProducts = this.productList.filter(p => p.productCategory == this.loanApp.selectedProduct);
          this.loanSelect(this.loanApp.type);
        }
      }
    });
  }


  ngAfterViewChecked(): void {
    // if (this.loanApp == undefined) {
    //   this.loanApp = JSON.parse(localStorage.getItem("loanAppState"));
    //   this.loanType = this.loanApp.type;
    //   this.loadInitiations();

    //   this.loanSelect(this.loanApp.type);
    // }
  }

  ngOnInit(): void {
    this.loanApp = JSON.parse(localStorage.getItem("loanAppState"));
    this.loanType = this.loanApp.type;
    this.loadInitiations();

    this.loanSelect(this.loanApp.type);
  }

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }
    return 0;
  }

  loadInitiations() {
    if (sessionStorage.getItem("LoanRates") === null) {
      this.api.getInitiations().subscribe((data: loanAmountRates[]) => {
        this.loanRates = data as loanAmountRates[];
        sessionStorage.setItem("LoanRates", JSON.stringify(this.loanRates));
      });
    }
    else {
      this.loanRates = JSON.parse(sessionStorage.getItem("LoanRates")) as loanAmountRates[];
    }
  }

  loanSelect(value: string) {

    var shortTermSettings = undefined;
    var personalSettings = undefined;
    var debtConSettings = undefined;
    if (this.filteredProducts.length > 0) {

      shortTermSettings = this.filteredProducts.some(p => p.productType == 1) ? this.filteredProducts.filter(p => p.productType == 1)[0] : undefined;
      personalSettings = this.filteredProducts.some(p => p.productType == 2) ? this.filteredProducts.filter(p => p.productType == 2)[0] : undefined;
      debtConSettings = this.filteredProducts.some(p => p.productType == 3) ? this.filteredProducts.filter(p => p.productType == 3)[0] : undefined;

    }

    if (value == 'Short Term Loan' && shortTermSettings === undefined) {
      value = "";
    }
    else if (value == 'Personal Loan' && personalSettings === undefined) {
      value = "";
    }
    else if (value == 'Debt Consolodation Loan' && debtConSettings === undefined) {
      value = "";
    }

    if (value == "") {
      if (shortTermSettings !== undefined) {
        value = "Short Term Loan";
      }
      else if (personalSettings !== undefined) {
        value = "Personal Loan";
      }
      else if (debtConSettings !== undefined) {
        value = "Debt Consolodation Loan";
      }
    }


    switch (value) {
      case "Debt Consolodation Loan": {
        this.selectedProductId = debtConSettings.id;
        this.amountMax = debtConSettings != undefined ? debtConSettings.maxPrincipal : 120000;
        this.amountMin = debtConSettings != undefined ? debtConSettings.minPrincipal : 10000;
        this.amountStep = 1000;
        this.loanAmount = this.loanApp.amount < this.amountMin || this.loanApp.amount > this.amountMax ? 10000 : this.loanApp.amount;
        this.termMax = debtConSettings != undefined ? debtConSettings.maxNumberOfPayments : 60;
        this.termMin = debtConSettings != undefined ? debtConSettings.minNumberOfPayments : 12;
        this.termStep = 1;
        this.term = this.loanApp.term < this.termMin || this.loanApp.term > this.termMax ? 12 : this.loanApp.term;
        this.rate = debtConSettings != undefined ? debtConSettings.nominalInterestRate : 22.00;

        this.selected = 3;
        break;
      }
      case "Personal Loan": {
        this.selectedProductId = personalSettings.id;
        this.amountMax = personalSettings != undefined ? personalSettings.maxPrincipal : 120000;
        this.amountMin = personalSettings != undefined ? personalSettings.minPrincipal : 8000;
        this.amountStep = 1000;
        this.loanAmount = this.loanApp.amount < this.amountMin || this.loanApp.amount > this.amountMax ? 8000 : this.loanApp.amount;
        this.termMax = personalSettings != undefined ? personalSettings.maxNumberOfPayments : 48;
        this.termMin = personalSettings != undefined ? personalSettings.minNumberOfPayments : 12;
        this.termStep = 1;
        this.term = this.loanApp.term < this.termMin || this.loanApp.term > this.termMax ? 12 : this.loanApp.term;
        this.rate = personalSettings != undefined ? personalSettings.nominalInterestRate : 22.00;
        this.selected = 2;
        break;
      }
      case "Short Term Loan": {
        this.selectedProductId = shortTermSettings.id;
        this.amountMax = shortTermSettings != undefined ? shortTermSettings.maxPrincipal : 8000;
        this.amountMin = shortTermSettings != undefined ? shortTermSettings.minPrincipal : 500;
        this.amountStep = 500;
        this.loanAmount = this.loanApp.amount < this.amountMin || this.loanApp.amount > this.amountMax ? 500 : this.loanApp.amount;
        this.termMax = shortTermSettings != undefined ? shortTermSettings.maxNumberOfPayments : 6;
        this.termMin = shortTermSettings != undefined ? shortTermSettings.minNumberOfPayments : 1;
        this.termStep = 1;
        this.term = this.loanApp.term < this.termMin || this.loanApp.term > this.termMax ? 1 : this.loanApp.term;
        this.rate = shortTermSettings != undefined ? shortTermSettings.nominalInterestRate : 60;
        this.selected = 1;
        break;
      }
      default: {
        this.selectedProductId = 0;
        this.amountMax = 8000;
        this.amountMin = 500;
        this.amountStep = 500;
        this.loanAmount = 500;
        this.termMax = 6;
        this.termMin = 1;
        this.termStep = 1;
        this.term = 1;
        this.rate = 60;
        this.selected = 1;
        break;
      }
    }
    this.emi();
  }

  emi() {
    if (this.loanRates !== undefined && this.loanRates !== null && this.loanRates.length > 0) {
      var selectedLoanAmount = (this.loanAmount + this.initiationFee(this.loanAmount));
      var numberOfMonths = this.term;
      var rateOfInterest = this.rate;
      var monthlyInterestRatio = (rateOfInterest / 100) / 12;

      var top = Math.pow((1 + monthlyInterestRatio), numberOfMonths);
      var bottom = top - 1;
      var sp = top / bottom;
      this.emiVal = (((selectedLoanAmount * monthlyInterestRatio) * sp) + 69).toFixed(2);
      this.totalRepayable = (((selectedLoanAmount * monthlyInterestRatio) * sp) + 69) * this.term;
      this.totalInterest = this.totalRepayable - this.totalFees - this.loanAmount;
      this.totalRepayableFixed = ((((selectedLoanAmount * monthlyInterestRatio) * sp) + 69) * this.term).toFixed(2);
      this.totalInterestFixed = (this.totalRepayable - this.totalFees - this.loanAmount).toFixed(2);

      return this.emiVal.toLocaleString();
    }
  }

  initiationFee(value: number) {
    if (value >= 10000) {
      this.totalFees = 1207.50 + (69 * this.term);
      this.totalFeesFixed = this.totalFees.toFixed(2);
      return 1207.50;
    }
    else {
      this.totalFees = this.loanRates.find(s => s.loan_amount == value).initiation + (69 * this.term);
      this.totalFeesFixed = this.totalFees.toFixed(2);
      var f = this.loanRates.find(s => s.loan_amount == value).initiation;
      return f;
    }
  }

  format(value: number) {
    return value.toLocaleString();
  }

  apply() {

    this.loanApp.username = sessionStorage.getItem('username');
    this.loanApp.productId = this.selectedProductId;
    //this.loanApp.clientId = Number(sessionStorage.getItem('clientId'));
    
    if (this.loanApp.type == 'Debt Consolodation Loan') {
      this.loanApp.type = "individual-debt";
    }
    else if (this.loanApp.type == 'Personal Loan') {
      this.loanApp.type = "individual-personal";
    }
    else if (this.loanApp.type == 'Short Term Loan') {
      this.loanApp.type = "individual-short";
    }

    this.api.submitLoanApplication(this.loanApp).subscribe((data: any) => {

      if (data.loanId !== 0 && data.loanId !== undefined) {
        sessionStorage.setItem("loanId", data.loanId);
        this.openSnackBar("Loan submitted", 'success-snackbar');
      }
      else {
        this.openSnackBar(data, 'error-snackbar');
      }
    })
  }

  monthAdd() {
    ++this.term;
    this.emi();
  }

  monthMinus() {
    --this.term;
    this.emi();
  }

  onInputChange($event: any) {
    this.loanAmount = $event.value;
    this._productSelect.updateLoanAmount(this.loanAmount);
    this.emi();
  }

  onTermChange($event: any) {
    this.term = $event.value;
    this._productSelect.updateLoanTerm(this.term);
    this.emi();
  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
