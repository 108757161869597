import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'app/ts/ApiService';
import { columnHeaders } from 'app/ts/models/broker-interfaces';
import { Approval } from 'app/ts/models/loan-classes';
import { LoadLoansComponent } from 'app/forms/broker-portal/load-loans/load-loans.component';
import { LoanDialogComponent } from './loan-dialog/loan-dialog.component';
import { ProductSelectService } from 'app/ts/services/product-select.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { PendingApprovalLoansService } from 'app/ts/services/pending-approval-loans.service';

@Component({
  selector: 'app-loan-pending-approval',
  templateUrl: './loan-pending-approval.component.html',
  styleUrls: ['./loan-pending-approval.component.scss']
})
export class LoanPendingApprovalComponent implements OnInit {

  constructor(private api: ApiService,
    public dialog: MatDialog,
    private _productSelect: ProductSelectService,
    private snackbar: MatSnackBar,
    private _pendingLoansService: PendingApprovalLoansService
  ) {

    this._pendingLoansService.pendingLoanList$.subscribe((x) => {
      if (x !== undefined || x !== null) {
        if (this.pendingLoanList$ != x) {
          this.pendingLoanList$ = x;
          if(this.dataSource.data != this.pendingLoanList$) {
            this.dataSource.data = this.pendingLoanList$
          }
          //this.getLoans();
        }
      }
    });
    //this.getLoans();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource([]);

  pendingLoanList$ = this._pendingLoansService.pendingLoanList;


  columnsToDisplay: string[];
  columns: columnHeaders[];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  length = 50;
  pageSize = 10;
  pageIndex = 0;
  displayedColumns: string[];


  pageEvent: PageEvent;
  ngOnInit() {
    this.displayedColumns = [
      'clientId',
      'loanId',
      'product',
      'principalProposed',
      'principalApproved',
      'term',
      'submittedDate',
      'firstName',
      'lastName',
      'idNumber',
      'lastNote',
      'approve',
      'decline',
      'reject'
    ];

    this.columns = [
      { DisplayName: 'Client Id', DbName: 'clientId' },
      { DisplayName: 'Loan Id', DbName: 'loanId' },
      { DisplayName: 'Loan Type', DbName: 'product' },
      { DisplayName: 'Proposed Principal', DbName: 'principalProposed' },
      { DisplayName: 'Approved Principal', DbName: 'principalApproved' },
      { DisplayName: 'Term', DbName: 'term' },
      { DisplayName: 'Submitted Date', DbName: 'submittedDate' },
      { DisplayName: 'First Name', DbName: 'firstName' },
      { DisplayName: 'Last Name', DbName: 'lastName' },
      { DisplayName: 'Id Number', DbName: 'idNumber' },
      { DisplayName: 'Note', DbName: 'lastNote' },
      { DisplayName: '', DbName: 'approve' },
      { DisplayName: '', DbName: 'decline' },
      { DisplayName: '', DbName: 'reject' }
    ]

    this.columnsToDisplay = this.displayedColumns.slice();

    let staffId = Number(sessionStorage.getItem('staffId'));
    this.getLoans();
    // this.updateLoanList(staffId);
  }

  getLoans() {
    let staffId = Number(sessionStorage.getItem('staffId'));
    this.updateLoanList(staffId);

    this.dataSource.data = this.pendingLoanList$;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  approve($event: { clientId: number; loanId: number; brokerApproved: boolean }) {
    $event.brokerApproved = true;
    let approval = new Approval();
    approval.isApproved = true;
    approval.approvedOn = new Date()
    approval.clientId = $event.clientId;
    approval.loanId = $event.loanId;
    approval.userId = Number(sessionStorage.getItem('userId'));
    approval.brokerId = Number(sessionStorage.getItem('staffId'));

    this.api.approveLoan(approval).subscribe((data) => {
      if (data.message == "Success") {
        this.getLoans();
      } else {
        this.openSnackBar("Something went wrong!", 'error-snackbar');
      }
    })

  }

  decline($event: { clientId: number; loanId: number; principalApproved: number; term: number; product: string }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this._productSelect.updateLoanType($event.product);
    this._productSelect.updateLoanAmount($event.principalApproved);
    this._productSelect.updateLoanTerm($event.term);

    dialogConfig.data = {
      loanId: $event.loanId,
      clientId: $event.clientId,
      actionType: 'decline',
      width: '900px'
    }

    this.openDialog(dialogConfig);
  }

  reject($event: { clientId: number; loanId: number; principalApproved: number; term: number; product: string }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this._productSelect.updateLoanType($event.product);
    this._productSelect.updateLoanAmount($event.principalApproved);
    this._productSelect.updateLoanTerm($event.term);

    dialogConfig.data = {
      //loanAmount: $event.principalApproved,
      //term: $event.term,
      loanId: $event.loanId,
      clientId: $event.clientId,
      actionType: 'reject',
      width: '900px'
    }

    this.openDialog(dialogConfig);




    // let approval = new Approval();
    // approval.isApproved = false;
    // approval.approvedOn = new Date();
    // approval.clientId = $event.clientId;
    // approval.loanId = $event.loanId;
    // approval.userId = Number(sessionStorage.getItem('userId'));
    // approval.brokerId = Number(sessionStorage.getItem('staffId'));

    // this.api.declineLoan(approval).subscribe((data) => {

    // })
  }

  updateLoanList(staffId: number) {
    this._pendingLoansService.updatePendingLoanList(staffId);
  }


  openDialog(config): void {
    const dialogRef = this.dialog.open(LoanDialogComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      this.getLoans();
    });
  }


  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

}
