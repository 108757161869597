<div class="container-fluid">
    <div class="form-position">
        <form>
            <div class="text-center"> <!-- Add this div for centering -->
                <div class=" p-4">
                    <h3 class="card-title"><b>Successfully captured.</b></h3>
                </div>
                <div class="row-padding">
                    <p style="display:inline-block;">Congratulations, you have successfully submitted your application.<br> One of our sales consultants will be in touch with you shortly to discuss your loan options and help you complete your application.</p>
                </div>
            </div>
        </form>
    </div>
</div>
