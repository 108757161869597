<div class="wrapper">
    <div class="sidebar" data-color="grey" data-background-color="white">
        <!-- <app-sidebar></app-sidebar> -->
    </div>
    <div class="main-panel" data-background-color="white" style="background-size: 50%;">

        <!-- graph component -->
        <!-- timeline component -->
        <!-- <app-navbar></app-navbar> -->
        <div class="main-content">
            <router-outlet></router-outlet>
        </div>
        <!-- <img src="./assets/img/uloans/backg.png" style="margin-top:-805px; margin-left: 150px;"> -->
    </div>
</div>