import { NavbarAuthComponent } from './../../../components/broker-portal/authenticate/navbar-auth/navbar-auth.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker'; 
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { RenewPasswordComponent } from './renew-password/renew-password.component';
import { FileUploadModule } from 'ng2-file-upload';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule, 
    ReactiveFormsModule, 
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule, 
    MatInputModule, 
    MatSelectModule, 
    MatTooltipModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule, 
    MatCheckboxModule,
    MatIconModule,
    MatStepperModule,
    MatPasswordStrengthModule,
    FileUploadModule
  ],
  declarations: [
    LoginComponent,
    RegisterComponent,
    RenewPasswordComponent
  ],
  exports: [
    LoginComponent,
    RegisterComponent,
    RenewPasswordComponent
  ],
  providers: []
 
})


export class AuthenticateFormsModule { }
