import { AfterViewChecked, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Products } from 'app/ts/models/broker-interfaces';
import { LoanApplication } from 'app/ts/models/loan-classes';
import { ProductSelectService } from 'app/ts/services/product-select.service';
import { json } from 'express';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { threadId } from 'worker_threads';
import { ProductSelectComponent } from '../product-select/product-select.component';

@Component({
  selector: 'app-load-loans',
  templateUrl: './load-loans.component.html',
  styleUrls: ['./load-loans.component.scss', '../../forms.shared.scss']
})
export class LoadLoansComponent implements OnInit {

  @ViewChild('tabset') tabset: TabsetComponent;
  @ViewChild(ProductSelectComponent) productSelect: ProductSelectComponent;

  loanSelected = false;
  loanApplication$ = this._productSelect.loanApplication;
  productList$ = this._productSelect.productList;
  loanApp: LoanApplication = {} as LoanApplication;
  Products: Products[] = [];

  shortTermMaxAmt: number = 8000;
  personalMaxAmt: number = 150000;
  debtConMaxAmt: number = 150000;

  showShortTermLoans: boolean = false;
  showPersonalLoans: boolean = false;
  showDebtConLoans: boolean = false;

  constructor(private _productSelect: ProductSelectService) {
    this.Products = JSON.parse(localStorage.getItem("productList"))

    //this._productSelect.productList$.subscribe((x: any) => {
    //  if (x.length !== 0 && x !== undefined) {
    //    this.Products = x;
    //  } else {
    //    this._productSelect.getStaffProductList();
    //  }
    //});

    this._productSelect.loanApplication$.subscribe((x: LoanApplication) => {
      if (x !== undefined || x !== null) {

        this.loanApp = x;
        
        if(this.Products.length > 0){
          var checkList = this.Products.filter(p => p.productCategory == x.selectedProduct);
  
          //check if the list contains at least one of these types of loans, then display that loan type in the product selector
          this.showShortTermLoans = checkList.some(p => p.productType == 1);
          this.showPersonalLoans = checkList.some(p => p.productType == 2);
          this.showDebtConLoans = checkList.some(p => p.productType == 3);
  
  
          checkList.forEach(element => {
            if (element.productType == 1) {
              this.shortTermMaxAmt = element.maxPrincipal
            }
            else if (element.productType == 2) {
              this.personalMaxAmt = element.maxPrincipal
            }
            else if (element.productType == 3) {
              this.debtConMaxAmt = element.maxPrincipal
            }
          });

          checkList[0].productType === 1 ? this.Short() : checkList[0].productType === 2 ? this.Personal() : this.DebtCon();
  
        }
      }
    });
  }

  ngOnInit() {

    this.Products = JSON.parse(localStorage.getItem("productList"));
    this.loanApp = JSON.parse(localStorage.getItem("loanAppState"));
    if (this.loanApp.type == "") {
      this.Short();
    }
  }

  apply() {
    this.productSelect.apply();
  }

  goto(type){

    this.tabset.tabs.forEach(element => {

      if(element.id == type)
      {
        element.active = true;
      }

    });
   
  }

  Short() {
    this.loanSelected = true;
    this.updateLoanType("Short Term Loan");
    this.goto("Short");
  }

  Personal() {
    this.loanSelected = true;
    this.updateLoanType("Personal Loan");
    this.goto("Personal");
  }

  DebtCon() {
    this.loanSelected = true;
    this.updateLoanType("Debt Consolodation Loan")
    this.goto("DebtCon");
  }

  back() {

    // localStorage.removeItem("Apply-Loan");
    // localStorage.removeItem("Apply-Loan-Type");
    // localStorage.removeItem("Apply-Loan-Step");
    this.loanSelected = false;
  }
  

  public updateLoanType(loanType: string) {
    this._productSelect.updateLoanType(loanType);
  }

  onSelect(option: string) {
    switch (option) {
      case 'Short Term Loan': {
        this.Short();
        break;
      }
      case "Personal Loan": {
        this.Personal();
        break;
      }
      case "Debt Consolodation Loan": {
        this.DebtCon();
        break;
      }
      default: {
        this.back();
        break;
      }
    }
  }
}
