<div class="container-fluid capture-card">
    <div class="loading-popup" *ngIf="loading">
        <div class="loading-spinner">
            <div class="loading-spinner-content"></div>
        </div>
    </div>    
    <div class="form-position">
        <form [formGroup]="personalDetails" novalidate>
            <div class="card-body col-md-12">
                <div class="row desktop-only">
                    <h3><b>Let's get the funding you require</b><img class="icon-size desktop-only" src="/assets/img/30day/icon2.png"></h3>
                </div>
                <div class="row mobile-padding">
                    <p>Please provide Lndr with some personal details</p>
                  </div>
                <div class="row pl-4 pr-4 pt-4 mobile-padding">
                    <div class="col-6 center-container pr-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Title</label>
                        <input class="custom-update-textbox pl-4 " type="text" value="" placeholder="Title" formControlName="title"> 
                    </div>
    
                    <div class="col-6 center-container pl-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Email Address</label>
                        <input class="custom-update-textbox pl-4" type="text" value="" placeholder="Email Address" formControlName="email">
                        <div *ngIf="personalDetails.get('email').errors?.required && personalDetails.get('email').touched" class="error-message">
                            Email is required.
                          </div> 
                    </div>   
 
                </div>    
                <div class="row pl-4 pr-4 pt-1 mobile-padding">
                    <div class="col-6 center-container pr-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">First Name</label>
                        <input class="custom-update-textbox pl-4" type="text" value="" placeholder="First Name" formControlName="name"> 
                        <div *ngIf="personalDetails.get('name').errors?.required && personalDetails.get('name').touched" class="error-message">
                            Firstname is required.
                          </div> 
                    </div>     
                    <div class="col-6 center-container pl-4  font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Surname</label>
                        <input class="custom-update-textbox pl-4" type="text" value="" placeholder="Surname" formControlName="surname">
                        <div *ngIf="personalDetails.get('surname').errors?.required && personalDetails.get('surname').touched" class="error-message">
                            surname is required.
                          </div>
                      </div> 
                </div>    
                <div class="row pl-4 pr-4 pt-1 mobile-padding">
                    <div class="col-6 center-container pr-4 custom-select-wrapper font-mobile" style="font-size: 12px;">
                        
                          <label class="custom-update-text pl-4 font-mobile">Country</label>
                        <select class="custom-select custom-update-textbox pl-4" formControlName="country" style="font-size: 12px;">
                          <option *ngFor="let type of countryIdOptions" [value]="type.id">{{ type.name }}</option>
                        </select>
                        <!-- <div *ngIf="personalDetails.get('country').errors?.required" class="error-message">
                            Country is required.
                          </div> -->
                    </div>   
                    <div class="col-6 center-container pl-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">ID Number</label>
                        <input class="custom-update-textbox pl-4" type="text" disabled maxlength="13" value="" placeholder="ID Number" formControlName="idNumber"> 
                        <div *ngIf="personalDetails.get('idNumber').errors?.required && personalDetails.get('idNumber').touched" class="error-message">
                            ID Number is required.
                          </div>
                    </div>                         
                </div>  
                <div class="row pl-4 pr-4 pt-1 mobile-padding">
                    <div class="col-6 center-container pr-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Nationality</label>
                        <input class="custom-update-textbox pl-4" type="text" value="" placeholder="Nationality" formControlName="nationality">
                        <!-- <div *ngIf="personalDetails.get('nationality').errors?.required && personalDetails.get('nationality').touched" class="error-message">
                            Nationality is required.
                          </div> -->
                    </div>   
                    <div class="col-6 center-container pl-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Mobile Number</label>
                        <input class="custom-update-textbox pl-4" type="text" disabled value="" minlength="10" maxlength="10" placeholder="Mobile Number" formControlName="mobileNumber"> 
                        <div *ngIf="personalDetails.get('mobileNumber').errors?.required && personalDetails.get('mobileNumber').touched" class="error-message">
                            Mobile number is required.
                          </div>
                    </div>                         
                </div>  

                <div class="row pl-4 pr-4 pt-1 mobile-padding">
                    <div class="col-6 center-container pr-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Net Income</label>
                        <input class="custom-update-textbox pl-4" type="text" value="" placeholder="What is your monthly net income?" formControlName="">
                        
                    </div>   
                    <div class="col-6 center-container pl-4 font-mobile" style="font-size: 12px;">
                        <label class="custom-update-text pl-4 font-mobile">Total Expenses</label>
                        <input class="custom-update-textbox pl-4" type="text" value="" placeholder="What is your monthly total expenses?" formControlName=""> 
                    </div>                         
                </div>  
                <div class="row pl-4 ml-4 pr-4 pt-4">
                    <br>
                    <button class="form-button-left" style="box-shadow: none; cursor: pointer;" type="submit" (click)="save()">
                        <div style="font-size: 18px;" >Next</div>
                    </button>
                </div>
                
                  </div>
           
        </form>           
    </div>    
</div>
