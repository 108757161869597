<div class="wrapper">
    <!-- <div class="sidebar" data-color="grey" data-background-color="white">    
       @Note dev
        <app-sidebar></app-sidebar>
    </div> -->
    <div class="main-panel custom-background" data-background-color="white" style="background-size: 50%;">

        <!-- graph component -->
        <!-- timeline component -->
        <app-navbar></app-navbar>
        <div class="main-content ">
            <router-outlet></router-outlet>
        </div>  
        <div>
            <app-footer></app-footer>
        </div>      
    </div>
</div>