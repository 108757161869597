<div class="main-content">
  <div class="container-fluid">
    <form [formGroup]="registerForm" (ngSubmit)="submitForm()" novalidate>
      <div class="row">
        <div class=" col-md-6 ">
          <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput type="text" formControlName="firstName">           
            <mat-error *ngIf="errorHandling('firstName', 'required')">
              This field is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div class=" col-md-6 ">
          <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput type="text" formControlName="lastName" >
            <mat-error *ngIf="errorHandling('lastName', 'required')">
              This field is required.
            </mat-error>
          </mat-form-field>         
        </div>
      </div>

      <div class="row">
        <div class=" col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>Mobile Number</mat-label>
            <input formControlName="mobileNumber" matInput type="text" >
            <mat-error *ngIf="errorHandling('mobileNumber', 'required')">
              This field is required.
            </mat-error>
          </mat-form-field>
          
        </div>

        <div class=" col-md-6 ">
          <mat-form-field appearance="outline">
            <mat-label>Email Address</mat-label>
            <input matInput formControlName="email" type="email" >
            <mat-error *ngIf="errorHandling('email', 'required')">
              This field is required.
            </mat-error>
          </mat-form-field>
          
        </div>
      </div>

      

      <div class="row">
        <div class=" col-md-12 ">
          <div fxFlex>
            <!--password input filed-->
            <mat-form-field appearance="outline" style="width: 100%" [color]="passwordComponent.color">
              <mat-label class="mat-label-margin">Password</mat-label>
              <!-- <div class="toggle-visibility-absolute-position"> -->
                <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
              <!-- </div> -->
              <input matInput #password [type]="toggle.type" formControlName="password" required >
              <!-- <mat-hint align="end" aria-live="polite">
                {{password.value.length}} / {{passwordComponent.max}}
              </mat-hint> -->
              <mat-error *ngIf="errorHandling('firstName', 'required')">
                This field is required.
              </mat-error>
            </mat-form-field>
            <!--@angular-material-extensions/password-strength's main component-->
            <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)"
              [password]="password.value">
            </mat-password-strength>
            
            <!--Password's strength info-->
            <mat-password-strength-info *ngIf="showDetails" [passwordComponent]="passwordComponent">
            </mat-password-strength-info>
          </div>
          <!-- <mat-form-field>
            <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" required>
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint>
            </mat-form-field> -->
        </div>
      </div>

      <!-- <div class="row">
        <div class="card-body col-md-12 row-padding">
          

          <button type="submit" class="button sign-in-button flex card card-body mat-textbox" style="width: 100%">
            <h3 class="button-text">
              <b>Register</b>
            </h3>
          </button>

        </div>
      </div> -->
      <div class="row">
        <div class="col-md-12">
          <!-- <button mat-raised-button type="submit" class="btn btn-rose">Sign In</button> -->
          <button class="rounded-raised-button" type="submit" mat-raised-button >            
            <span class="extended-fab-button__text">Register</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4><span>or continue with</span></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 d-flex justify-content-md-center">
          <button mat-raised-button type="button" style="min-width: 70%;" (click)="loginWithGoogle()"
            class="btn btn-outline btn-white"><img style="height: 40px;"
              src=""></button>
        </div>
        <div class="col-md-5 d-flex justify-content-md-center">
          <button mat-raised-button type="button" style="min-width: 70%;" (click)="loginWithFacebook()"
            class="btn btn-white btn-outline"><img style="height: 40px;"
              src=""></button>
        </div>
      </div>
      <!-- <div class="clearfix"></div> -->
    </form>
  </div>
</div>