<!-- <footer class="footer ">
    <div class="container-fluid">        
        <div class="copyright">
             &copy;
            {{year | date: 'yyyy'}} Made by Fintegrate.
            {{version}} 

            <p>Endless <span class="text-green">Possibilities</span> NCRCP9333</p>

            <div style="border: dashed red;">
                <p class="pl-4">
                    Legal Stuff
                </p>
                <a href="https://lndrcredit.co.za/legal/" class="text-green">Legal Documentation</a>
            </div>
        </div>
    </div>
</footer> -->

<footer class="footer">
    <div class="container-fluid">        
        <div class="copyright">
            <p class="center-text">Endless <span class="text-green">Possibilities</span> NCRCP9333</p>

            <div class="legal-container">
                <div>
                    <p >Legal Stuff</p>
                    <a href="https://lndrcredit.co.za/legal/" class="text-green">Legal Documentation</a>
                </div>
            </div>
        </div>
    </div>
</footer>
