<table mat-table matSort [dataSource]="dataSource" class="w-100 noShadow">
  <ng-container [matColumnDef]="column.DbName" *ngFor="let column of columns">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> {{column.DisplayName}}</th>
      <td mat-cell *matCellDef="let record">
          <span *ngIf="column.DbName != 'principalProposed' && column.DbName != 'principalApproved'">{{record[column.DbName]}}
          </span>
          <span *ngIf="column.DbName == 'principalProposed' || column.DbName == 'principalApproved'">
              <app-currency-formatter [value]="record[column.DbName]">
              </app-currency-formatter>
          </span>
          <button *ngIf="column.DbName == 'approve'" (click)="approve(record)" class="table-button">APPROVE</button>
          <button *ngIf="column.DbName == 'decline'" (click)="decline(record)" class="table-button-reject">DECLINE</button>
      </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>