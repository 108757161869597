import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  year : Date = new Date();
  version: string;
  constructor() { }

  ngOnInit() {
    //read version number from package.json
    this.version = "v0.0.1";
  }

}
