import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'app/ts/ApiService';
import { columnHeaders } from 'app/ts/models/broker-interfaces';
import { BankValidation, CreditCheckAccounts, CreditCheckCellNumbers, CreditCheckCodixProductLists, CreditCheckEmployers, CreditCheckFraudIndicatorDescriptions, CreditCheckGeneralInfo, CreditCheckNlrAccounts, CreditCheckPreviousEnquiries, CreditCheckPublicDefaults, CreditCheckStats, CreditCheckWarnings } from 'app/ts/models/client-interfaces';

@Component({
  selector: 'app-credit-check',
  templateUrl: './credit-check.component.html',
  styleUrls: ['./credit-check.component.scss']
})
export class CreditCheckComponent implements OnInit {

  constructor(private api: ApiService) { }

  @Output() updateStepEvent = new EventEmitter<number>();
  @Output() previousStepEvent = new EventEmitter<number>();

  @Input()
  set event(event: number) {
    if (event) {
      this.clientId == event;
      this.refresh();
    }
  }

  general = {} as CreditCheckGeneralInfo;
  accounts: CreditCheckAccounts[];
  cellNumbers: CreditCheckCellNumbers[];
  codix: CreditCheckCodixProductLists[];
  previousEnq: CreditCheckPreviousEnquiries[];
  defaults: CreditCheckPublicDefaults[];
  stats = {} as CreditCheckStats;
  warnings: CreditCheckWarnings[];
  employers: CreditCheckEmployers[];
  fraudDescriptions: CreditCheckFraudIndicatorDescriptions[];
  nlrAccounts: CreditCheckNlrAccounts[];
  bankValiation = {} as BankValidation;
  clientId: number;

  accountColumns: string[];
  cellNumberColumns: string[];
  codixColumns: string[];
  previousEnqColumns: string[];
  defaultsColumns: string[];
  warningsColumns: string[];
  employersColumns: string[];
  fraudDescriptionsColumns: string[];
  nlrAccountsColumns: string[];

  columnsAccounts: columnHeaders[];
  columnsCellNumbers: columnHeaders[];
  columnsCodix: columnHeaders[];
  columnsPreviousEnq: columnHeaders[];
  columnsDefaults: columnHeaders[];
  columnsWarnings: columnHeaders[];
  columnsEmployers: columnHeaders[];
  columnsFraudDescriptions: columnHeaders[];
  columnsNlrAccounts: columnHeaders[];

  dataSourceAccounts = new MatTableDataSource([]);
  dataSourceCellNumbers = new MatTableDataSource([]);
  dataSourceCodix = new MatTableDataSource([]);
  dataSourcePreviousEnq = new MatTableDataSource([]);
  dataSourceDefaults = new MatTableDataSource([]);
  dataSourceWarnings = new MatTableDataSource([]);
  dataSourceEmployers = new MatTableDataSource([]);
  dataSourceFraudDescriptions = new MatTableDataSource([]);
  dataSourceNlrAccounts = new MatTableDataSource([]);

  ngOnInit() {
    this.clientId = Number(sessionStorage.getItem("clientId"));
    if (this.clientId != undefined && this.clientId != 0) {
      this.refresh();
    }

  }

  getCreditCheck() {
    this.api.getCreditBureauAssessment(this.clientId).subscribe((data: any) => {
      if (data != null) {
        this.general.adminIndicator = data.adminIndicator;
        this.general.customerRefId = data.customerRefId;
        this.general.deceasedIndicator = data.deceasedIndicator;
        this.general.declineReasonOne = data.declineReasonOne;
        this.general.declineReasonTwo = data.declineReasonTwo;
        this.general.declineReasonThree = data.declineReasonThree;
        this.general.declineReasonFour = data.declineReasonFour;
        this.general.declineReasonFive = data.declineReasonFive;
        this.general.fraudIndicator = data.fraudIndicator;
        this.general.hasDispute = data.hasDispute;
        this.general.idNumberExists = data.idNumberExists;
        this.general.name = data.name;
        this.general.nameMatchScore = data.nameMatchScore;
        this.general.reviewAlert = data.reviewAlert;
        this.general.riskFactor = data.riskFactor;
        this.general.riskCatagory = data.riskCatagory;
        this.general.riskScore = data.riskScore;
        this.general.riskScoreVersion = data.riskScoreVersion;
        this.general.tooLittleInfoIndicator = data.tooLittleInfoIndicator;

        this.accounts = data.accounts;
        this.cellNumbers = data.cellNumbers;
        this.codix = data.codixProductList;
        this.previousEnq = data.csPrevenq;
        this.defaults = data.csPublicDefaults;
        this.stats = data.csStats;
        this.warnings = data.csWarnings;
        this.employers = data.employers;
        this.fraudDescriptions = data.fraudIndicaterDescriptions;
        this.nlrAccounts = data.nlrAccounts;
        this.bankValiation = data.bankValiation;

        this.populateLists();
      }
    })

  }

  populateLists() {
    this.populateAccounts();
    this.populateCellNumbers();
    this.populateCodix();
    this.populateDefaults();
    this.populateEmployers();
    this.populateFraudDesciptions();
    this.populateNlrAccounts();
    this.populatePreviousEnq();
    this.populateWarnings();
  }

  populateAccounts() {
    this.accountColumns = [
      'subscriberName',
      'accountNumber',
      'accountType',
      'openingBalance',
      'currentBalance',
      'arrearsPeriod',
      'intallmentAmount',
      'statusCodeDescription',
      'dateIssued',
      'lastPaymentDate',
      'overdueAmount',
      'repaymentFreq',
    ]

    this.columnsAccounts = [
      { DisplayName: 'Subscriber Name', DbName: 'subscriberName' },
      { DisplayName: 'Account Number', DbName: 'accountNumber' },
      { DisplayName: 'Account Type', DbName: 'accountType' },
      { DisplayName: 'Opening Balance', DbName: 'openingBalance' },
      { DisplayName: 'Current Balance', DbName: 'currentBalance' },
      { DisplayName: 'Installment Amount', DbName: 'intallmentAmount' },
      { DisplayName: 'Status Code Description', DbName: 'statusCodeDescription' },
      { DisplayName: 'Date Issued', DbName: 'dateIssued' },
      { DisplayName: 'Last Payment Date', DbName: 'lastPaymentDate' },
      { DisplayName: 'Overdue Amount', DbName: 'overdueAmount' },
      { DisplayName: 'Arrears Period', DbName: 'arrearsPeriod' },
      { DisplayName: 'Repayment Frequency', DbName: 'repaymentFreq' }
    ]

    this.dataSourceAccounts.data = this.accounts;
  }

  populateEmployers() {
    this.employersColumns = [
      'name',
      'created',
      'started'
    ]

    this.columnsEmployers = [
      { DisplayName: 'Employer Name', DbName: 'name' },
      { DisplayName: 'Created Date', DbName: 'created' },
      { DisplayName: 'Started Date', DbName: 'started' }
    ]

    this.dataSourceEmployers.data = this.employers;
  }

  populateCellNumbers() {
    this.cellNumberColumns = [
      'cellNumber'
    ]

    this.columnsCellNumbers = [
      { DisplayName: 'Cell Number', DbName: 'cellNumber' }
    ]

    this.dataSourceCellNumbers.data = this.cellNumbers;
  }

  populateCodix() {
    this.codixColumns = [
      'number',
      'name',
      'outcome'
    ]

    this.columnsCodix = [
      { DisplayName: 'Product Number', DbName: 'number' },
      { DisplayName: 'Product Name', DbName: 'name' },
      { DisplayName: 'Outcome', DbName: 'outcome' }
    ]

    this.dataSourceCodix.data = this.codix;
  }


  populateFraudDesciptions() {
    this.fraudDescriptionsColumns = [
      'fraudindicaterdescription'
    ]

    this.columnsFraudDescriptions = [
      { DisplayName: 'Fraud Indicator Description', DbName: 'fraudindicaterdescription' }
    ]

    this.dataSourceFraudDescriptions.data = this.fraudDescriptions;
  }

  populateNlrAccounts() {
    this.nlrAccountsColumns = [
      'subscriberName',
      'accountNumber',
      'accountType',
      'openingBalance',
      'currentBalance',
      'arrearsPeriod',
      'intallmentAmount',
      'statusCodeDescription',
      'dateIssued',
      'lastPaymentDate',
      'overdueAmount',
      'repaymentFreq',
    ]

    this.columnsNlrAccounts = [
      { DisplayName: 'Subscriber Name', DbName: 'subscriberName' },
      { DisplayName: 'Account Number', DbName: 'accountNumber' },
      { DisplayName: 'Account Type', DbName: 'accountType' },
      { DisplayName: 'Opening Balance', DbName: 'openingBalance' },
      { DisplayName: 'Current Balance', DbName: 'currentBalance' },
      { DisplayName: 'Installment Amount', DbName: 'intallmentAmount' },
      { DisplayName: 'Status Code Description', DbName: 'statusCodeDescription' },
      { DisplayName: 'Date Issued', DbName: 'dateIssued' },
      { DisplayName: 'Last Payment Date', DbName: 'lastPaymentDate' },
      { DisplayName: 'Overdue Amount', DbName: 'overdueAmount' },
      { DisplayName: 'Arrears Period', DbName: 'arrearsPeriod' },
      { DisplayName: 'Repayment Frequency', DbName: 'repaymentFreq' }
    ]

    this.dataSourceNlrAccounts.data = this.nlrAccounts;
  }


  populatePreviousEnq() {
    this.previousEnqColumns = [
      'enquiryDate',
      'branchName',
      'contactPerson',
      'telehponeNumber',
    ]

    this.columnsPreviousEnq = [
      { DisplayName: 'Enquiry Date', DbName: 'enquiryDate' },
      { DisplayName: 'Branch Name', DbName: 'branchName' },
      { DisplayName: 'Contact Person', DbName: 'contactPerson' },
      { DisplayName: 'Telehpone Number', DbName: 'telehponeNumber' },
    ]

    this.dataSourcePreviousEnq.data = this.previousEnq;
  }

  populateWarnings() {
    this.warningsColumns = [
      'reasoN_CODE',
      'reasoN_DESC'
    ]

    this.columnsWarnings = [
      { DisplayName: 'Reason Code', DbName: 'reasoN_CODE' },
      { DisplayName: 'Reason Description', DbName: 'reasoN_DESC' },

    ]
    this.dataSourceWarnings.data = this.warnings;
  }

  populateDefaults() {
    this.defaultsColumns = [
      'account_ref_number',
      'branch_name',
      'default_amount',
      'default_date',
      'fk_subscr_code',
      'legal_action_code',
      'legal_action_desc',
    ]

    this.columnsDefaults = [
      { DisplayName: 'Account REF Number', DbName: 'account_ref_number' },
      { DisplayName: 'Branch Name', DbName: 'branch_name' },
      { DisplayName: 'Default Amount', DbName: 'default_amount' },
      { DisplayName: 'Default Date', DbName: 'default_date' },
      { DisplayName: 'Subscriber Code', DbName: 'fk_subscr_code' },
      { DisplayName: 'Legal Action Code', DbName: 'legal_action_code' },
      { DisplayName: 'Legal Action Description', DbName: 'legal_action_desc' },
    ]

    this.dataSourceDefaults.data = this.defaults;
  }

  forward() {
    this.updateStepEvent.emit();
  }

  back() {
    this.previousStepEvent.emit();
  }

  refresh() {
    this.clientId = Number(sessionStorage.getItem("clientId"));
    this.getCreditCheck();
  }

}
