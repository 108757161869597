import {
  Component, ViewChild, OnInit, Input, Output, EventEmitter,
  SimpleChange, ChangeDetectionStrategy, ChangeDetectorRef, TemplateRef, Inject, ElementRef
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LoansRecords } from 'app/ts/models/client-interfaces';
import { BatchRecords } from 'app/ts/models/broker-interfaces';
import { ApiService } from 'app/ts/ApiService';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BatchDialogComponent } from '../batch-dialog/batch-dialog.component'
import { catchError, take } from 'rxjs/operators';
import { SweetAlertService } from 'app/sweet-alert-service.service';
export interface LoansRecords2 {
  state: number,
  client_name: string,
  surname: string,
  Id: string,
  cell_number: string,
  email: string,
  reason: string
}

const ELEMENT_DATA: LoansRecords2[] = [
  { state: 1, client_name: 'Hydrogen', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 2, client_name: 'Helium', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 3, client_name: 'Lithium', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 4, client_name: 'Beryllium', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 5, client_name: 'Boron', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 6, client_name: 'Carbon', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 7, client_name: 'Nitrogen', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 8, client_name: 'Oxygen', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 9, client_name: 'Fluorine', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 10, client_name: 'Neon', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 11, client_name: 'Sodium', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 12, client_name: 'Magnesium', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 13, client_name: 'Aluminum', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 14, client_name: 'Silicon', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 15, client_name: 'Phosphorus', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 16, client_name: 'Sulfur', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 17, client_name: 'Chlorine', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 18, client_name: 'Argon', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 19, client_name: 'Potassium', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
  { state: 20, client_name: 'Calcium', surname: 'TheSurname', Id: '0000000000000', cell_number: '55544447777', email: 'test@info.co.za', reason: 'Experian check success' },
];



export interface DialogData {
  choice: string;
}

@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.scss', '../../../forms/forms.shared.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchListComponent implements OnInit {

  batchResultsLengthPrev: Number;
  reportName;
  selectedIndex: number;
  checkHistory: boolean = false;
  searchHistory: boolean = false;
  history_date: string;
  batchRecords: any[] = [];
  startTime: string;
  endTime: string;
  isLoading: boolean = false;
  isValidateSearch: boolean = true;
  validateText: string;
  choice: string = 'OK';
  recordsCount: number;
  interval;
  cancelOption: boolean = false;

  pageSize = window.innerWidth < 1440 ? 5 : 15;

  public dataSource = new MatTableDataSource(ELEMENT_DATA);

  @Input() batchResults: BatchRecords[];
  @Input() userChoice: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('timeElem') timeElem: ElementRef<HTMLElement>;

  @ViewChild('dialogRef')
  dialogRef!: TemplateRef<any>;

  myFooList = ['Some Item', 'Item Second', 'Other In Row', 'What to write', 'Blah To Do']


  constructor(
    private api: ApiService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private alertService: SweetAlertService) { }

  //columns: columnHeaders[];

  dialog_data: DialogData;

  displayedColumns: string[] = ['state', 'cname', 'surname', 'zaid', 'cellphone_number', 'email', 'reason', 'score'];

  columns = [
    { DisplayName: 'Prevet', DbName: 'state' },
    { DisplayName: 'Client Name', DbName: 'cname' },
    { DisplayName: 'Surname', DbName: 'surname' },
    { DisplayName: 'ID', DbName: 'zaid' },
    { DisplayName: 'Cell Number', DbName: 'cellphone_number' },
    { DisplayName: 'Email', DbName: 'email' },
    { DisplayName: 'Reason', DbName: 'reason' },
    { DisplayName: 'Score', DbName: 'score' }
  ];

  columnsToDisplay: string[] = this.displayedColumns.slice();

  //dataSource = new MatTableDataSource(ELEMENT_DATA);
  //dataSource = new MatTableDataSource<LoansRecords2>(ELEMENT_DATA);
  //loans: LoansRecords2[];
  //selectedReport: string;

  public previousStep() {
    this.selectedIndex -= 1;
  }

  validateSearchHistory() {
    if (this.history_date == '' || typeof this.history_date == 'undefined') {
      this.isValidateSearch = false;
      this.validateText = 'Please choose a valid date to search for';
      return false;
    }
    else {
      this.isValidateSearch = true;
      return true;
    }
  }

  openDialog() {

    this.isLoading = true;
    this.startTimer();

    this.alertService.Confirmation('Please note when you proceed that the current batch results will be replaced with new search results! Download your results to save them.')
      .subscribe((result: boolean) => {

        if (result === true) {
          this.batchRecords = [];

          this.historyBatchResults();
        }
        else {
          if (result === false) {

            this.cancelOption = true;
          }
        }
      });
  }

  startTimer() {
    this.interval = setInterval(() => {

      if (this.cancelOption == true && this.isLoading == true && typeof this.cancelOption != 'undefined') {
        this.isLoading = false;
        clearInterval(this.interval);
        this.cdr.detectChanges();
        this.cancelOption = false;
      }

    }, 100)
  }

  //   pauseTimer() {
  //     clearInterval(this.interval);
  //   }

  historyBatchResults() {

    if (this.validateSearchHistory()) {

      this.searchHistory = true;

      this.recordsCount = 0;
      this.batchRecords.length = 0;

      this.batchRecords = [];

      this.isLoading = true;

      let sendDate = new Date(this.history_date);

      this.api.getHistoryBatchResults(this.datePipe.transform(sendDate, "yyyy-MM-dd"), this.startTime, this.endTime).subscribe((data: any) => {
        data.forEach(x => {
          this.batchRecords.push(x);
        });

        this.dataSource = new MatTableDataSource<BatchRecords>(this.batchRecords);
        this.dataSource.paginator = this.paginator;

        this.recordsCount = this.batchRecords.length;

        this.isLoading = false;
        this.cancelOption = false;

      });

      let newDate = new Date(this.history_date);
      this.reportName = 'Uloans_' + formatDate(newDate, 'yyyy_MM_dd', 'en') + '_' + this.startTime.replace(":", "_");

    }
  }

  onChange(ob: MatCheckboxChange) {
    this.checkHistory = ob.checked;
  }

  updateStep() {

    if (this.selectedIndex == 3) {
      this.selectedIndex = 0;
      window.location.reload(); //I hate this. Can't seems to find a better alternative at present
    }
    else {
      this.selectedIndex += 1;
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    this.searchHistory = false;

    this.dataSource = new MatTableDataSource<BatchRecords>(this.batchResults);
    this.dataSource.paginator = this.paginator;

    this.reportName = 'Uloans_' + formatDate(new Date(), 'yyyy/MM/dd', 'en');

  }

  ngOnInit() {
    this.searchHistory = false;

    this.dataSource = new MatTableDataSource<BatchRecords>(this.batchResults);
    this.dataSource.paginator = this.paginator;

    this.selectedIndex = 0;

    this.startTime = "00:00";
    this.endTime = "23:59";

    this.history_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.recordsCount = 0;
  }

}

