export class LoanApplication {
    productId: number;
    username: string;
    amount: number;
    term: number;
    type: string;
    selectedProduct: string;
    clientId: number;
    orderId: number;
}

export class loanAmountRates {
    initiation: number;
    insurance: number;
    loan_amount: number;
}

export class Approval {
    clientId: number;
    loanId: number;
    brokerId: number;
    userId: number;
    isApproved: boolean;
    approvedOn: Date;
}

export class Decline {
    clientId: number;
    loanId: number;
    brokerId: number;
    userId: number;
    isApproved: boolean;
    approvedOn: Date;
    newTerm: number;
    newLoanAmount: number;
    loanType: string
}

export class Reject {
    clientId: number;
    loanId: number;
    brokerId: number;
    userId: number;
  
}