<div class="container-fluid">
    <div class="card primary-card">
        <div>
            <div class="row p-4">
                <h3 class="card-title"><b>Approve</b> Loans</h3>
            </div>
            <div class="row row-cols-1 row-cols-md-12">
                <div class="col">
                    <div>
                        <div>
                            <table mat-table matSort [dataSource]="dataSource" class="w-100 noShadow">
                                <ng-container [matColumnDef]="column.DbName" *ngFor="let column of columns">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef> {{column.DisplayName}}</th>
                                    <td mat-cell *matCellDef="let record">
                                        <span *ngIf="column.DbName != 'principalProposed' && column.DbName != 'principalApproved'">{{record[column.DbName]}}
                                        </span>
                                        <span *ngIf="column.DbName == 'principalProposed' || column.DbName == 'principalApproved'">
                                            <app-currency-formatter [value]="record[column.DbName]">
                                            </app-currency-formatter>
                                        </span>
                                        <button *ngIf="column.DbName == 'approve'" [ngClass]="{'btn-secondary': record.brokerApproved}" [disabled]="record.brokerApproved" (click)="approve(record)" class="table-button">APPROVE</button>
                                        <button *ngIf="column.DbName == 'decline'" (click)="decline(record)" class="table-button-decline">DECLINE</button>
                                        <button *ngIf="column.DbName == 'reject'" (click)="reject(record)" class="table-button-reject">REJECT</button>

                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
                            </table>
                            <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event" showFirstLastButtons aria-label="Select page">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>