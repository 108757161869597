export class UserCredentials {
    username: string;
    password: string;
}

export class PasswordDetails {
    firstname: string;
    password: string;
    repeatPassword: string;
}

export class OtpLogin {
    mobilenumber: string;
    otp: string;
    id: number;
}

export class OtpLoan {
    mobilenumber: string;
    otp: string;
    id: number;
    loanId: number;
}