import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/ts/ApiService';
import { AdminBarGraph, BrokerGraphs } from 'app/ts/models/broker-interfaces';
//import { Chart, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { environment } from 'environments/environment';
import { Label } from 'ng2-charts';
import {
  IBarChartOptions,
  IChartistAnimationOptions,
  IChartistData
} from 'chartist';
import { ChartEvent, ChartType } from 'ng-chartist';
import * as Chartist from 'chartist';

const API_URL = environment.apiURL;

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})

export class BarChartComponent implements OnInit {

  // this.http.get(this.url).subscribe((result: BrokerGraphs) => {
  //   result.submittedLeadsBars.forEach(x => {
  //     this.name.push(x.name);
  //     this.count.push(x.count);
  //   });
 
  url = `${API_URL}Portal/getClientsByStaffId`;
  errorMessage: string;
  labels: string[];
  series: number[];

  dataSet = new Promise((resolve: any): void => {
    this.http.get(this.url).subscribe((data: Chartist.IChartistData) => {
      resolve(data);
    },
      (error) => this.errorMessage = <any>error)
  });

  constructor(private api: ApiService, private http: HttpClient) { }

  type: ChartType = 'Bar';
  data: IChartistData = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    series: [
      [5, 4, 3, 7, 5, 10, 3, 4, 8, 10, 6, 8],
      [3, 2, 9, 5, 4, 6, 4, 6, 7, 8, 7, 4]
    ]
  };

  options: IBarChartOptions = {
    axisX: {
      showGrid: false
    },
    height: 300
  };

  events: ChartEvent = {
    draw: (data) => {
      if (data.type === 'bar') {
        data.element.animate({
          y2: <IChartistAnimationOptions>{
            dur: '0.5s',
            from: data.y1,
            to: data.y2,
            easing: 'easeOutQuad'
          }
        });
      }
    }
  };

  // barChartOptions: ChartOptions = {
  //   responsive: true,

  // };

  // barChartLabels: Label[] = ['Awie'];
  // barChartType: ChartType = 'horizontalBar';
  // barChartLegend = true;
  // barChartPlugins = [];
  // firstDataSet: AdminBarGraph[];

  // data: AdminBarGraph[];
  // url = `${API_URL}Portal/getClientsByStaffId`;
  // name = [];
  // count = [];
  // barchart = [];

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    // this.http.get(this.url).subscribe((result: BrokerGraphs) => {
    //   result.submittedLeadsBars.forEach(x => {
    //     this.name.push(x.name);
    //     this.count.push(x.count);
    //   });

    //   this.barchart.push(new Chart('canvas', {
    //     type: 'horizontalBar',
    //     data: {
    //       labels: this.name,
    //       datasets: [
    //         {
    //           data: this.count,
    //           borderColor: '#ef6c00',
    //           backgroundColor: [
    //             "#ef6c00"
    //           ],
    //           fill: true,
    //           label: 'Submitted',
    //         }
    //       ]
    //     },
    //     options: {
    //       legend: {
    //         display: true
    //       },
    //       scales: {
    //         xAxes: [{
    //           display: true
    //         }],
    //         yAxes: [{
    //           display: true
    //         }]
    //       }
    //     }
    //   }));
    // });
  }

  GetRandomColor() {
    var trans = '0.3'; // 80% transparency
    var color = 'rgba(';
    for (var i = 0; i < 3; i++) {
      color += Math.floor(Math.random() * 255) + ',';
    }
    color += trans + ')';
    return color;
  }
}
