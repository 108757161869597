<div class="container-fluid">
    <div class="form-position">
        <form>
            <div class="text-center"> <!-- Add this div for centering -->
                <div class=" p-4">
                    <h3 class="card-title"><b>The application process has been canceled.</b></h3>
                </div>
                <div class="row-padding">
                    <p class="support-link">Contact customer support: {{ supportPhoneNumber }}</p>
                </div>
            </div>
        </form>
    </div>
</div>
