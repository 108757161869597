import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EmployerDetails, FindEmployer, clientAffordabilityData } from 'app/ts/models/broker-classes';
import { ApiService } from 'app/ts/ApiService';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BankEmployerDetailsResult } from 'app/ts/models/broker-interfaces';
import { DataSharingService } from 'app/data-sharing-service';

@Component({
  selector: 'app-employer-details',
  templateUrl: './employer-details.component.html',
  styleUrls: ['./employer-details.component.scss', '../../forms.shared.scss']
})
export class EmployerDetailsComponent implements OnInit {

  // salaryDates: string[] = [
  //   "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
  //   "12", "13", "14", "15", "16", "17", "18", "19", "20", "21",
  //   "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"
  // ];
  

  employerDetails = new EmployerDetails();
  employerFind = new FindEmployer();

  empDetails: FormGroup;
  currentRoute: string;
  dateParts: any[];
  clientId: number;
  result = {} as BankEmployerDetailsResult;
  ClientAffordability: clientAffordabilityData;
  EditProfile:boolean = false;
  clientInfo: any;
  clientEmpoloyment = false;
  employementType = [
    {
        "id": 316,
        "value": "Semi - skilled Worker",
        "score": 0
    },
    {
        "id": 317,
        "value": "Skilled Worker",
        "score": 0
    },
    {
        "id": 318,
        "value": "Junior Management",
        "score": 0
    },
    {
        "id": 319,
        "value": "Senior Management",
        "score": 0
    },
    {
        "id": 320,
        "value": "Professional",
        "score": 0
    },
    {
        "id": 321,
        "value": "Executive Management",
        "score": 0
    }
]

salaryType = [
  {
      "id": 422,
      "value": "Fortnight",
      "score": 0
  },
  {
      "id": 424,
      "value": "Weekly",
      "score": 0
  },
  {
      "id": 423,
      "value": "Monthly",
      "score": 0
  }
]



employmentSector = [
  {
      "id": 300,
      "value": "Agriculture, Forestry or Fishing",
      "score": 0
  },
  {
      "id": 301,
      "value": "Mining",
      "score": 0
  },
  {
      "id": 302,
      "value": "Manufacturing",
      "score": 0
  },
  {
      "id": 303,
      "value": "Construction",
      "score": 0
  },
  {
      "id": 304,
      "value": "Security",
      "score": 0
  },
  {
      "id": 305,
      "value": "Transport",
      "score": 0
  },
  {
      "id": 306,
      "value": "Financial Services",
      "score": 0
  },
  {
      "id": 307,
      "value": "Government or Public Services",
      "score": 0
  },
  {
      "id": 308,
      "value": "Information Technology",
      "score": 0
  },
  {
      "id": 309,
      "value": "Personal Services",
      "score": 0
  },
  {
      "id": 310,
      "value": "Retail Services",
      "score": 0
  }
]


salaryDates = [
  { "id": 1, "value": "1" },
  { "id": 2, "value": "2" },
  { "id": 3, "value": "3" },
  { "id": 4, "value": "4" },
  { "id": 5, "value": "5" },
  { "id": 6, "value": "6" },
  { "id": 7, "value": "7" },
  { "id": 8, "value": "8" },
  { "id": 9, "value": "9" },
  { "id": 10, "value": "10" },
  { "id": 11, "value": "11" },
  { "id": 12, "value": "12" },
  { "id": 13, "value": "13" },
  { "id": 14, "value": "14" },
  { "id": 15, "value": "15" },
  { "id": 16, "value": "16" },
  { "id": 17, "value": "17" },
  { "id": 18, "value": "18" },
  { "id": 19, "value": "19" },
  { "id": 20, "value": "20" },
  { "id": 21, "value": "21" },
  { "id": 22, "value": "22" },
  { "id": 23, "value": "23" },
  { "id": 24, "value": "24" },
  { "id": 25, "value": "25" },
  { "id": 26, "value": "26" },
  { "id": 27, "value": "27" },
  { "id": 28, "value": "28" },
  { "id": 29, "value": "29" },
  { "id": 30, "value": "30" },
  { "id": 31, "value": "31" }
];





  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private dataSharingService: DataSharingService,
  ) {
    this.setEmployerDetailsForm();
  }

  @Output() updateStepEvent = new EventEmitter<number>();
  @Output() previousStepEvent = new EventEmitter<number>();
  @Output() sendEmployerDetailsResult = new EventEmitter<BankEmployerDetailsResult>();
  @Output() employerForm = new EventEmitter<boolean>();

  ngOnInit(): void {

    this.empDetails.valueChanges.subscribe(() => {
      if (this.empDetails.valid) {
        this.employerForm.emit(true);
      } else {
        this.employerForm.emit(false);
      }
    })

    this.paramsChanged();

  }

  ngAfterViewChecked(): void {
    this.ClientAffordability = JSON.parse(sessionStorage.getItem("clientAffordability"));
    if(this.ClientAffordability != null)
    {
       this.empDetails.controls['gross_salary'].setValue(this.ClientAffordability.GrossSalary);
       this.empDetails.controls['net_salary'].setValue(this.ClientAffordability.NetSalary);
    }

    let salDate = JSON.parse(localStorage.getItem("clientDetailsData"));

    if (salDate !== null) {
      this.empDetails.controls['employer_Salary_Date'].setValue(salDate.salaryDate)
    } else {
      salDate = JSON.parse(sessionStorage.getItem("ClientInfo"));
      if (salDate.employment != null) {
      this.empDetails.controls['employer_Salary_Date'].setValue(salDate.employement[0].employer_Salary_Date)
      }
    }



    
    // this.dataSharingService.ClientInformation$.subscribe(details => {
    //   if(details != null && !this.EditProfile && !this.clientEmpoloyment)
    //   {
    //     const employerDetails = details.employement[details.employement.length -1];
    //     let d = employerDetails.employment_Start_Date.split("/");
    //     let startDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);

    //     this.empDetails.controls.employer_Name.patchValue(employerDetails.employer_Name);
    //     this.empDetails.controls.employer_Contact_Number.patchValue(employerDetails.employer_Contact_Number);
    //     this.empDetails.controls.employee_Number.patchValue(employerDetails.employee_Number);
    //     this.empDetails.controls.employment_Start_Date.patchValue(employerDetails.employment_Start_Date);
    //     this.empDetails.controls.employer_Salary_Date.patchValue(parseInt(employerDetails.employer_Salary_Date));
    //     this.empDetails.controls.salary_Type_cd_Salary_Type.patchValue(parseInt(employerDetails.salary_Type_cd_Salary_Type));
    //     this.empDetails.controls.employerSector_cd_Employer_Sector.patchValue(parseInt(employerDetails.employerSector_cd_Employer_Sector));
    //     this.empDetails.controls.employmentType_cd_Employment_Type.patchValue(parseInt(employerDetails.employmentType_cd_Employment_Type));

    //     this.clientEmpoloyment = true;
    //   }
    // });

  }

  paramsChanged() {
    this.route.params.subscribe(params => {
      if (this.clientId != params['clientId']) {
        this.clientId = +params['clientId'];
        sessionStorage.setItem('searchClientID', this.clientId.toString());

        if (this.clientId != 0) {
          this.populateSearchEmployer();
        }else {
          this.setEmployerDetailsForm();
        }
      }
    });
    this.populateSearchEmployer();
  }

  populateSearchEmployer() {

    var clientIdSaved = sessionStorage.getItem('_searchClientID');

    if (clientIdSaved != '0' && clientIdSaved != null) {
      this.employerFind = JSON.parse(sessionStorage.getItem('EmployerDetailsResult'));

      this.employerDetails.clientId = this.employerFind.ClientId;
      this.employerDetails.employer_Name = this.employerFind.Employer_Name;
      this.employerDetails.employer_Contact_Number = this.employerFind.Employer_Contact_Number;
      this.employerDetails.employee_Number = this.employerFind.Employee_Number;
      this.employerDetails.employment_Start_Date = this.employerFind.Employment_Start_Date;
      this.employerDetails.gross_salary = this.employerFind.Gross_Salary;
      this.employerDetails.net_salary = this.employerFind.Net_Salary;
      this.employerDetails.employer_Salary_Date = this.employerFind.Employer_Salary_Date;

      let d = this.employerFind.Employment_Start_Date.split("/");
      let startDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);

      this.empDetails.controls.employer_Name.patchValue(this.employerDetails.employer_Name);
      this.empDetails.controls.employer_Contact_Number.patchValue(this.employerDetails.employer_Contact_Number);
      this.empDetails.controls.employee_Number.patchValue(this.employerDetails.employee_Number);
      this.empDetails.controls.employment_Start_Date.patchValue(startDate);
      this.empDetails.controls.gross_salary.patchValue(this.employerDetails.gross_salary);
      this.empDetails.controls.net_salary.patchValue(this.employerDetails.net_salary);
      this.empDetails.controls.employer_Salary_Date.setValue(this.salaryDates[parseInt(this.employerDetails.employer_Salary_Date) - 1]);

      var loanId = parseInt(sessionStorage.getItem('loanStatusId'));

      sessionStorage.setItem('searchClientID', '');




      if (loanId == 300) {
        this.empDetails.disable();
      }
      else 
      {
        this.empDetails.enable();
      }

    }
    else
    {
      this.clientInfo = JSON.parse(sessionStorage.getItem('ClientInfo'));
      if(this.clientInfo != null)
      {
        this.empDetails.enable();
        this.EditProfile = true;
        sessionStorage.removeItem('EmployerDetailsResult');
  
        this.empDetails.controls.employer_Name.patchValue('');
        this.empDetails.controls.employer_Contact_Number.patchValue('');
        this.empDetails.controls.employee_Number.patchValue(0);
        // this.empDetails.controls.employment_Start_Date.patchValue(startDate);
        this.empDetails.controls.gross_salary.patchValue(0);
        this.empDetails.controls.net_salary.patchValue(0);
        this.empDetails.controls.employer_Salary_Date.setValue(1);
  
        this.clientInfo = JSON.parse(sessionStorage.getItem('ClientInfo'));
        const employment = this.clientInfo.employement[0];
        this.empDetails.controls.employer_Name.patchValue(employment.employer_Name);
        this.empDetails.controls.employer_Contact_Number.patchValue(employment.employer_Contact_Number);
        this.empDetails.controls.employee_Number.patchValue(employment.employee_Number);
        this.empDetails.controls.employment_Start_Date.patchValue(employment.employment_Start_Date);
        this.empDetails.controls.employer_Salary_Date.patchValue(parseInt(employment.employer_Salary_Date));
        this.empDetails.controls.salary_Type_cd_Salary_Type.patchValue(parseInt(employment.salary_Type_cd_Salary_Type));
        this.empDetails.controls.employerSector_cd_Employer_Sector.patchValue(parseInt(employment.employerSector_cd_Employer_Sector));
        this.empDetails.controls.employmentType_cd_Employment_Type.patchValue(parseInt(employment.employmentType_cd_Employment_Type));
      }


    }

  }

  setEmployerDetailsForm() {
    this.empDetails = this.formBuilder.group({
      employer_Name: ['', Validators.required],
      employer_Contact_Number: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      employee_Number: [0, Validators.required],
      employment_Start_Date: ['', [Validators.required]],
      employer_Salary_Date: ['', Validators.required],
      gross_salary: [0, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(10), Validators.min(0)])],
      net_salary: [0, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(10), Validators.min(0)])],
      employmentType_cd_Employment_Type: ['', Validators.required],
      employerSector_cd_Employer_Sector: ['', Validators.required],
      salary_Type_cd_Salary_Type: ['', Validators.required],

    });
  }

  startDateValidator(control) {
    const selectedDate = new Date(control.value);
    const today = new Date();

    // Calculate the first day of the month three months ago
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    threeMonthsAgo.setDate(1);

    const isValid = selectedDate < today && selectedDate <= threeMonthsAgo;

    return isValid ? null : { startDateOutOfRange: true };
  }
  save() {
    if (this.empDetails.valid) {
     
      this.employerDetails.clientId = Number(sessionStorage.getItem('clientId'));
      this.employerDetails.employer_Name = this.empDetails.controls['employer_Name'].value;
      this.employerDetails.employer_Contact_Number = this.empDetails.controls['employer_Contact_Number'].value;
      this.employerDetails.employee_Number = this.empDetails.controls['employee_Number'].value;
      this.employerDetails.employment_Start_Date = this.empDetails.controls['employment_Start_Date'].value;
      this.employerDetails.gross_salary = this.empDetails.controls['gross_salary'].value;
      this.employerDetails.net_salary = this.empDetails.controls['net_salary'].value;
      this.employerDetails.employer_Salary_Date = this.empDetails.controls['employer_Salary_Date'].value;
      this.employerDetails.employerSector_cd_Employer_sector = this.empDetails.controls['employerSector_cd_Employer_Sector'].value;
      this.employerDetails.employmentType_cd_Employment_Type = this.empDetails.controls['employmentType_cd_Employment_Type'].value;
      this.employerDetails.salary_Type_cd_Salary_Type = this.empDetails.controls['salary_Type_cd_Salary_Type'].value;


      
      if(this.clientInfo != null){
        if( this.clientInfo.employement != null)
        this.employerDetails.UpdateDetails = true;
      }

      
      this.api.saveEmployerDetails(this.employerDetails).subscribe((data: any) => {
        this.openSnackBar("Employee details saved!", 'success-snackbar');

        //todo: add error handling

        this.updateStepEvent.emit();
        // this.result.message = data.message;
        // this.result.saved = data.message == "Ok" ? true : false;
      
        // this.sendEmployerDetailsResult.emit(this.result);
      });
    }
    else 
    {
      this.openSnackBar("Please make sure the Employee details are entered correctly", 'error-snackbar');
    }
  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  back(): void {
    this.previousStepEvent.emit();
  }

}
