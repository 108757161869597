import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-batch-upload',
  templateUrl: './batch-upload.component.html',
  styleUrls: ['./batch-upload.component.scss']
})
export class BatchUploadComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }

}
