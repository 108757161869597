import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../ApiService';
import { LoansToApprove } from '../models/broker-interfaces';

@Injectable({
  providedIn: 'root'
})
export class PendingApprovalLoansService {
  private readonly initalPendingLoanCount: number = 0;
  private readonly initalPendingLoanList = [] as LoansToApprove[];


  pendingLoanCountSubject$ = new BehaviorSubject(this.initalPendingLoanCount);
  pendingLoanCount$ = this.pendingLoanCountSubject$.asObservable();
  pendingLoanListSubject$ = new BehaviorSubject(this.initalPendingLoanList);
  pendingLoanList$ = this.pendingLoanListSubject$.asObservable();

  constructor(private api: ApiService) { 
    // this.pendingLoanCount$.subscribe((x: number) => {
    //   if (x !== undefined || x !== null) {
       
    //   }
    // });
  }


  updatePendingLoanCount(newValues: number) {
    if (this.pendingLoanCountSubject$.value != newValues)
      this.pendingLoanCountSubject$.next(newValues);
  }


  updatePendingLoanList(staffId: number) {
    this.api.getLoansToApprove(staffId).subscribe((data => {
      if(data != undefined){
        this.pendingLoanListSubject$.next(data);
      
        localStorage.setItem("pendingLoanCount",JSON.stringify(data.length == undefined ? 0 : data.length));
        // this.updatePendingLoanCount(data.length);        
      }
    
    }))
  }

  get pendingLoanCount() {
    return this.pendingLoanCountSubject$.value;
  }

  get pendingLoanList() {
    return this.pendingLoanListSubject$.value;
  }

}
