<div class="container-fluid">
    <br>
    <div class="row topSection">
        <div class="col-md-8">
            <div class="container-position">
                <h1 class="quick-loan-font">PAYDAY <span class="highlight">LOANS</span></h1>
                <br>
                <div class="display-font desktop-only">
                    <p>Expect approval in minutes and a hassle-free online application process only with Lndr.</p>
                </div>
                <div class="display-font-mobile mobile-only">
                    <p>Expect approval in minutes and a hassle-free online application process only with Lndr.</p>
                </div>
            </div>
        </div>
        <div class="desktop-only">
            <img src="/assets/img/30day/guy.png" alt="amplifin-logo" style="width: 33%; height: auto; position: absolute;" class="image-position pt-4 mt-4 desktop-only">
        </div>
        <div class="col-md-4 pt-4 mt-4 test">
            <div class="col-md-11 col-12 rightSection">
                <div style="text-align: center;" class="display-font-small">
                    <p>Choose your loan amount, select your salary date, and proceed with your application.</p>
                </div>
                <div class="capture-card">
                    <div [formGroup]="personalDetailsForm">
                        <div class="existingClient-container" *ngIf="!this.existingClientHideFields">
                            <label for="consentCheckbox" style="font-size: 16px;">
                                Existing Client?
                            </label>
                            <input type="checkbox" (change)="existingClientTick()" id="existingClientCheckbox" formControlName="existingClientCheckbox">
                        </div>
                        <div class="row pl-4 pr-4 remove-mobile-padding">
                            <div class="col-12 center-container slider-container" style="font-size: 12px;">
                                <label class="ml-4 pl-1" for="loanAmount" style="font-size: 10px;">Loan Amount</label>
                                <input type="range" #loanAmountSlider value="500" [min]="minLoan" [max]="maxLoan" step="10" id="loan-amount" name="loanAmount" 
                                (input)="updateLoanAmount($event);" (input)="updateSlider($event)" formControlName="loanamount" style="--value: 0%">
                                <div class="mt-2 slider-thumb lnAmount">R {{ format(personalDetailsForm.get('loanamount').value) }}</div>
                            </div>
                        </div>
                        <div class="row pl-4 pr-4 pt-4 remove-mobile-padding">
                            <div class="col-12 center-container slider-container" style="font-size: 12px;">
                                <label class="ml-4 pl-1" for="salaryDate" style="font-size: 10px;">Salary Date</label>
                                <input type="range" #salaryDateSlider value="1" min="1" max="31" step="1" id="salary-date" name="salaryDate" 
                                (input)="updateSlider($event);" (input)="updateSalaryDate($event)" formControlName="salaryDate">
                                <div class="mt-2 slider-thumb slDate">{{ getDayWithSuffix(personalDetailsForm.get('salaryDate').value) }}</div>
                            </div>
                        </div>
                        <div class="row pl-4 pr-4 pt-4 remove-mobile-padding details" *ngIf="!this.existingClientHideFields">
                            <div class="col-6 center-container pr-3 capture-font-very-small">
                                <div class="inputCont"  style="display: flex; flex-direction: column;">
                                    <input class="custom-textbox pl-4" type="text" placeholder="Name" formControlName="name">
                                    <div *ngIf="personalDetailsForm.get('name').invalid && personalDetailsForm.get('name').touched" class="error-message">
                                        Name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 center-container pr-3 capture-font-very-small">
                                <div style="display: flex; flex-direction: column;">
                                    <input class="custom-textbox pl-4" type="text" placeholder="Surname" formControlName="surname">
                                    <div *ngIf="personalDetailsForm.get('surname').invalid && personalDetailsForm.get('surname').touched" class="error-message">
                                        Surname is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pl-4 pr-4 pt-4 remove-mobile-padding details" *ngIf="!this.existingClientHideFields">
                            <div class="col-6 center-container pr-3 capture-font-very-small">
                                <div style="display: flex; flex-direction: column;">
                                    <input class="custom-textbox pl-4" type="text" minlength="13" maxlength="13" placeholder="ID Number" formControlName="idNumber">
                                    <div *ngIf="this.idValid === false" class="error-message">
                                        ID Number is invalid.
                                    </div>
                                    <div *ngIf="personalDetailsForm.get('idNumber').invalid && personalDetailsForm.get('idNumber').touched" class="error-message">
                                        ID Number is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 center-container pr-3 capture-font-very-small">
                                <div style="display: flex; flex-direction: column;">
                                    <input class="custom-textbox pl-4" type="text" minlength="10" maxlength="10" placeholder="Mobile Number" formControlName="cellNumber">
                                    <div *ngIf="personalDetailsForm.get('cellNumber').invalid && personalDetailsForm.get('cellNumber').touched" class="error-message">
                                        Mobile Number is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pl-4 pr-4 pt-4 remove-mobile-padding">
                            <div class="col-12 center-container capture-font-very-small">
                                <div class="custom-textbox pt-1">
                                    <div class="row">
                                        <span class="col-md-8 col-7 capture-font-small">Loan Repayment</span>
                                        <span class="col-md-4 col-5 text-end capture-font-small">R {{format(personalDetailsForm.get('loanamount').value + totalInterestAndFees)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pl-4 pr-4 pt-4 remove-mobile-padding">
                            <div class="col-12 center-container">
                                <div class="custom-large-textbox">
                                    <div class="row pl-2 pt-3">
                                        <span class="col-md-8 col-8">Loan Amount</span>
                                        <span class="col-md-4 col-4">R {{format(personalDetailsForm.get('loanamount').value)}}</span>
                                    </div>
                                    <div class="row pl-2">
                                        <span class="col-md-8 col-8">Installment Date</span>
                                        <span class="col-md-4 col-4">
                                            <div>{{ getDayWithSuffix(personalDetailsForm.get('salaryDate').value) }}</div>
                                        </span>
                                    </div>
                                    <div class="row pl-2">
                                        <span class="col-md-8 col-8">Total interest and fees</span>
                                        <span class="col-md-4 col-4">R {{format(this.totalInterestAndFees)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="text-align: center;" class="pt-3">
                            <div class="checkbox-container">
                                <label for="consentCheckbox" style="font-size: 12px;">
                                    I hereby authorise Lndr (Pty) to conduct a full credit check on my profile.
                                </label>
                                <input type="checkbox" id="consentCheckbox" formControlName="creditCheckConsent">
                            </div>
                            <div *ngIf="personalDetailsForm.get('creditCheckConsent').invalid && personalDetailsForm.get('creditCheckConsent').touched" class="error-message">
                                You must authorize a credit check.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row bottomSection">
        <div class="col-md-8"></div>
        <div class="col-md-4 bottomContent">
            <div class="pt-2" style="text-align: center;">
                <p class="display-font-very-small">
                    The monthly repayment must only be used as an estimate and will be confirmed upon the approval of the loan.
                </p>
            </div>
            <div class="pt-2 pb-4 mobile-padding">
                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    <div style="align-items: center;">
                        <button mat-fab class="custom-button" (click)="submit()" style="box-shadow: none;" type="submit">Apply Today!</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="popup" *ngIf="showApprovalPopup">
        <div class="popup-content">
            <img class="mt-3" src="/assets/img/30day/approval.png">
            <p class="mt-3">
                {{ approvalMessage }}
            </p>
            <button type="button" class="btn mt-3" *ngIf="!existingClient" (click)="gotoUpdateClient()">Get your Loan</button>
            <button type="button" class="btn mt-3 okBtn" *ngIf="existingClient" (click)="existingClientNavigation()">Ok</button>
        </div>
    </div>
    <div class="popup" *ngIf="showDeclinePopup">
        <div class="popup-content">
            <img class="mt-3" src="/assets/img/30day/declined.png">
            <p class="mt-3">{{ declineMessage }}</p>
            <button type="button" (click)="visitWebsite()" class="btn mt-3">Contact Lndr</button>
        </div>
    </div>
    <div class="loading-popup" *ngIf="loading">
        <div class="loading-spinner">
            <div class="loading-spinner-content"></div>
        </div>
    </div>
</div>