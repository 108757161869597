<div class="container-fluid">
    <div class="form-position">
        <form [formGroup]="consentDetails" novalidate>
            <div class="card-body col-md-12">
                <div class="row">
                    <div class="card-body col-md-6">
                        <div class="row">
                            <div class="card-body col-md-4">
                                <label>Popia Consent:</label>
                            </div>
                            <div class="card-body col-md-4">
                                <mat-checkbox #chkPopiaConsent formControlName="popiaConsent" labelPosition="before" (change)="checked($event)">
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="card-body col-md-6">
                        <div class="row">
                            <div class="card-body col-md-4">
                                <label>Marketing Consent:</label>
                            </div>
                            <div class="card-body col-md-4">
                                <mat-checkbox #chkmarketingConsent formControlName="marketingConsent" (change)="checked($event)">
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-padding">
                <br>
                <button mat-fab class="form-button-right" style="box-shadow: none;" type="submit" (click)="save()" [disabled]="!canProceed"><i class="fa fa-arrow-right"></i></button>
            </div>
        </form>
    </div>
</div>