<div class="container-fluid">
    <div class="row mainDiv justify-content-center align-items-center">
        <div class="col-md-6 col-12 text-center">
            <div class="card primary-card capture-card p-4">
                <div class="icon my-4">
                    <img src="/assets/img/30day/icon2.png" alt="Completion Icon" style="width: 50px; height: 50px;">
                </div>
                <h1>Application completed</h1>
                <p>Thank you for choosing Lndr</p>
                <p>Visit our website for more information.</p>
                <div class="lndrButton">
                    <button (click)="visitWebsite()" class="website-button">lndr.co.za</button>
                </div>
            </div>
        </div>
        
        <div class="col-md-6 col-12 d-flex justify-content-center align-items-center ">
            <div style="display: grid; align-items: center; grid-template-columns: 50% 50%;">
                <div class="desktop-only half-loop top-loop"></div>
                <div class="desktop-only half-loop bottom-loop"></div>
            </div>
            <img class="icon-size desktop-only" style="width: 100%; max-width: 650px; height: auto; z-index: 99;" src="/assets/img/30day/laptop.png">
        </div>
    </div>
</div>



<!-- <div class="row">
    <div class="container-fluid">
        <div class="row mainDiv">
            <div class="col-md-6 col-12">
                <div class="card primary-card capture-card">
                    
                </div>
                
            </div>
            
            <div class="flowIcons desktop-only" style="z-index: 1;">
                <img class="icon-size" style="width: 550px; height: 400px; z-index: 99;" src="/assets/img/30day/laptop.png">
            </div>
            <div class="upsideDown desktop-only" style="z-index: 5;">
                
            </div>
        </div>
       
        <div>
        
    </div>
        
    </div>
</div> -->







<!-- <div class="container-fluid">
    <div class="form-position">
        <form>
            <div class="text-center"> 
                <div class=" p-4">
                    <h3 class="card-title"><b>Successfully captured.</b></h3>
                </div>
                <div class="row-padding">
                    <p style="display:inline-block;">Congratulations, you have successfully submitted your application.<br> One of our sales consultants will be in touch with you shortly to discuss your loan options and help you complete your application.</p>
                </div>
            </div>
        </form>
    </div>
</div> -->
