<div class="loan-agreement">

    <form [formGroup]="loanForm">
        <div class="card-body col-md-12">
            <div class="row">
                <div class="col-md-4">
                    <h4>Welcome to Lndr</h4>
                    <p>
                        By submitting a loan application to LNDR, you acknowledge and agree to the following terms and conditions. The submission of an application does not guarantee approval, and approval is subject to meeting eligibility criteria. Interest rates, fees, and repayment terms will be disclosed in the loan agreement. Timely and accurate information submission is essential.
                    </p>
                    <p>
                        A credit check may be conducted to assess creditworthiness. All provided information is treated with confidentiality. Default may result in legal action, and borrowers are encouraged to contact Lndr Pty for alternative arrangements in case of financial difficulties. Lndr Pty may modify these terms, and any changes will be communicated in advance
                    </p>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="standard">
                        <mat-label class="mat-label-margin">Loan Amount</mat-label>
                        <span matPrefix>R </span>
                        <input matInput class="mat-textbox" input name="name" min="500" input type="number" formControlName="loanamount" autocomplete="nope">
                        <mat-error>
                            Please enter a loan amount between R500 - R120 000
                        </mat-error>
                    </mat-form-field>
                    <div class="monthlyPayment">
                        <mat-label class="mat-label-margin">MONTHLY PAYMENT</mat-label>
                    </div>
                   
                </div>
                <div class="col-md-4" >
                    <mat-form-field>
                        <mat-select formControlName="loanterm" placeholder="Loan Term*" style="margin-top: 10px;" (ngModelChange)="onLoanTermSelected($event)">
                          <mat-option *ngFor="let product of Products" class="loanterm" [value]="product.id">{{ product.maxNumberOfRepayments }} Months</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="monthlyPayment">
                        <mat-label class="mat-label-margin">{{ monthlyPayment | currency: 'R' }} per month</mat-label>
                    </div>


                </div>  
            </div>

            </div>

      <div class="row row-padding">
        <br>
        <button mat-fab class="form-button-right" style="box-shadow: none;" type="submit" (click)="save()" [disabled]="!loanForm.valid"><i class="fa fa-arrow-right" ></i></button>
    </div>
    </form>

  