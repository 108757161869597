import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataSharingService } from 'app/data-sharing-service';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { ApiService } from 'app/ts/ApiService';
import { clientAffordabilityData } from 'app/ts/models/broker-classes';
import { max } from 'moment';

@Component({
  selector: 'app-client-affordability',
  templateUrl: './client-affordability.component.html',
  styleUrls: ['./client-affordability.component.scss','../../forms.shared.scss']

  
})
export class ClientAffordabilityComponent implements OnInit {
  form: FormGroup;
  @Output() updateStepEvent = new EventEmitter<number>();
  @Output() clientAffordabilityDataEvent = new EventEmitter<any>();
  clientAffordabilityData: any;
  monthlyCreditLifePremium:number;
  monthlyLoanInstallment:number;
  LNDR_Loans_Compliant: boolean = false;
  NCR_Loans_Compliant: boolean = false;
  @Output() formSubmitted = new EventEmitter<FormGroup>();
  clientProfile: any;
  clientId: string;
  LoanApplication: any;


  constructor(private fb: FormBuilder,    private snackbar: MatSnackBar,private api: ApiService,private dataSharingService: DataSharingService) { }

  ngOnInit(): void {
    
    var clientDetails = JSON.parse(localStorage.getItem("clientDetailsData"));

    this.dataSharingService.loanApplication$.subscribe(loanApp => {
      this.LoanApplication = loanApp;


      this.form = this.fb.group({
        medicalExpenses: [0],
        housingExpenses: [0],
        educationExpenses: [0],
        consumablesExpenses: [0],
        waterExpenses: [0],
        transportExpenses: [0],
        otherExpenses: [0],
        totalExpenses: [0],
        ncrExpenses: [0],
        ncrMinLivingExpenses: [0],
        grossSalary:[0],
        netSalary:[0],
        disposableIncome:[0],
        minRequiredTakeHome:[0],
        maxNCRAllowedInstl: [0],
        maxInstallmentAllowed:[0],
        grossSalaryAsPerPayslip:[0],
        loanAmount: [0]//this.LoanApplication == null ? 0 : this.LoanApplication.amount,
  
      });
      this.form.controls.loanAmount.patchValue(clientDetails.loanamount);
    });
    

    if (clientDetails !== null) {
      this.form.controls.loanAmount.patchValue(parseFloat(clientDetails.loanamount).toFixed(2));
    } else {


      const clientDetails = JSON.parse(sessionStorage.getItem("ClientInfo"));

      // Get the last loan
      const lastLoan = clientDetails.loans[clientDetails.loans.length - 1];

      // Get the last expense
      const lastExpense = clientDetails.expenses[clientDetails.expenses.length - 1];

      // Patch the values with the last elements
      this.form.controls.loanAmount.patchValue(Number(lastLoan.principal));
      this.form.controls.consumablesExpenses.patchValue(Number(lastExpense.consumables));
      this.form.controls.grossSalary.patchValue(Number(lastExpense.grossSalary));
      this.form.controls.waterExpenses.patchValue(Number(lastExpense.municipal));
      this.form.controls.netSalary.patchValue(Number(lastExpense.netSalary));
      this.form.controls.transportExpenses.patchValue(Number(lastExpense.transport));
      this.form.controls.medicalExpenses.patchValue(Number(lastExpense.medical));
      this.form.controls.otherExpenses.patchValue(Number(lastExpense.otherExpenses));
      this.form.controls.housingExpenses.patchValue(Number(lastExpense.housing));
      this.form.controls.educationExpenses.patchValue(Number(lastExpense.education));

      this.calculateTotalExpenses();

    }

    
  }

  ngAfterViewInit() {
    
  }

  onSubmit(){

  }


  save(){
    if(true)
    {

      this.clientId = sessionStorage.getItem('clientId');
      this.clientAffordabilityData = this.form.value;
      const sessionData = JSON.parse(sessionStorage.getItem("ClientInfo"));

      const clientAffordability : clientAffordabilityData = {
        ClientId: Number(this.clientId),
        Id: sessionData?.expenses?.length > 0 ? sessionData.expenses[0].id : 0,
        GrossSalary: this.clientAffordabilityData.grossSalary,
        NetSalary: this.clientAffordabilityData.netSalary,
        Housing: this.clientAffordabilityData.housingExpenses,
        Transport: this.clientAffordabilityData.transportExpenses,
        Education: this.clientAffordabilityData.educationExpenses,
        OtherExpenses: this.clientAffordabilityData.otherExpenses,
        Medical: this.clientAffordabilityData.medicalExpenses,
        Consumables: this.clientAffordabilityData.consumablesExpenses,
        Municipal: this.clientAffordabilityData.waterExpenses,
        NcrExpenses: this.clientAffordabilityData.ncrExpenses,
      };

      this.api.saveClientAffordability(clientAffordability, Number(this.clientId)).subscribe((data: any) => {
        this.openSnackBar("Client affordability details saved!", 'success-snackbar');
        sessionStorage.setItem("clientAffordability", JSON.stringify(clientAffordability));
        this.updateStepEvent.emit();

        //todo: add error handling

      });

      
    
      
    }else{
      //this.openSnackBar("This client does not qualify for a loan", 'error-snackbar');
    }
  }

   calculateTotalExpenses() {
    const medicalExpensesControl = this.form.controls['medicalExpenses'];
    const housingExpensesControl = this.form.controls['housingExpenses'];
    const educationExpensesControl = this.form.controls['educationExpenses'];
    const consumablesExpensesControl = this.form.controls['consumablesExpenses'];
    const waterExpensesControl = this.form.controls['waterExpenses'];
    const transportExpensesControl = this.form.controls['transportExpenses'];
    const otherExpensesControl = this.form.controls['otherExpenses'];
    const totalExpensesControl = this.form.controls['totalExpenses'];
    const ncrExpensesControl = this.form.controls['ncrExpenses'];



    const total =  medicalExpensesControl.value + housingExpensesControl.value + educationExpensesControl.value + consumablesExpensesControl.value + waterExpensesControl.value + transportExpensesControl.value + otherExpensesControl.value + ncrExpensesControl.value;
    totalExpensesControl.setValue(total.toFixed(2));
    this.calculateDisposableIncome();
  }

  calculateMinNCRLivingExpenses(newVal:number)
  {
        var val = 0;
        if (newVal > 0 && newVal < 800.01) {
          val = (0 + ((newVal - 0) * 0.0675))
      }
      if (newVal >= 800.01 && newVal < 6250.01) {
          val = (800 + ((newVal - 800) * 0.0675))
      }
      if (newVal >= 6250.01 && newVal < 25000.01) {
          val = (1167.88 + ((newVal - 6250.01) * 0.09))
      }
      if (newVal >= 25000.01 && newVal < 50000.01) {
          val = (2855.00 + ((newVal - 25000.00) * 0.082))
      }
      if (newVal >= 50000.01) {
          val = (4905.38 + ((newVal - 50000.38) * 0.0675))
      }

      const ncrMinLivingExpensesControl = this.form.controls['ncrMinLivingExpenses'];
      ncrMinLivingExpensesControl.setValue(val.toFixed(2));

      this.calculateDisposableIncome();
  }

  calculateDisposableIncome()
  {
    const ncrExpensesControl = this.form.controls['ncrExpenses'];
    const netSalaryControl = this.form.controls['netSalary'];
    const totalExpensesControl = this.form.controls['totalExpenses'];
    const ncrMinLivingExpensesControl = this.form.controls['ncrMinLivingExpenses'];
    const disposableIncomeControl = this.form.controls['disposableIncome'];
    const medicalExpensesControl = this.form.controls['medicalExpenses'];
    const housingExpensesControl = this.form.controls['housingExpenses'];
    const educationExpensesControl = this.form.controls['educationExpenses'];
    const consumablesExpensesControl = this.form.controls['consumablesExpenses'];
    const waterExpensesControl = this.form.controls['waterExpenses'];
    const transportExpensesControl = this.form.controls['transportExpenses'];
    const otherExpensesControl = this.form.controls['otherExpenses'];
    const maxNCRAllowedInstlControl = this.form.controls['maxNCRAllowedInstl'];
    const maxInstallmentAllowedInstlControl = this.form.controls['maxInstallmentAllowed'];
    const minRequiredTakeHomeControl = this.form.controls['minRequiredTakeHome'];

    var avarageNetSalary = netSalaryControl.value;
    const totalExpenses =  medicalExpensesControl.value + housingExpensesControl.value + educationExpensesControl.value + consumablesExpensesControl.value + waterExpensesControl.value + transportExpensesControl.value + otherExpensesControl.value + ncrExpensesControl.value;
    var maxExpenses = Math.max(totalExpenses , ncrMinLivingExpensesControl.value)
    var B29 = ncrExpensesControl.value;
    var B46 = (maxExpenses + B29);
    var disposalbeIncome = avarageNetSalary - B46;

    disposableIncomeControl.setValue(disposalbeIncome.toFixed(2));
    maxNCRAllowedInstlControl.setValue(disposalbeIncome.toFixed(2));
    maxInstallmentAllowedInstlControl.setValue((disposalbeIncome - minRequiredTakeHomeControl.value).toFixed(2))
    this.calculateTakeHome();
    this.calculateLoanAmount();
  }

  calculateTakeHome()
  {

    const disposableIncomeControl = this.form.controls['disposableIncome'];
    const minRequiredTakeHomeControl = this.form.controls['minRequiredTakeHome'];
    const takeAway = 60/100 * disposableIncomeControl.value
    minRequiredTakeHomeControl.setValue(takeAway.toFixed(2));
  }

  calculateLoanAmount()
  {
    const loanAmountControl = this.form.controls['loanAmount'];
    const maxInstallmentAllowedontrol = this.form.controls['maxInstallmentAllowed'];
    const maxNCRAllowedInstlControl = this.form.controls['maxNCRAllowedInstl'];


    this.monthlyCreditLifePremium = loanAmountControl.value/1000 * 5.5;
    this.monthlyLoanInstallment = 1125.55;

    const propose_access_new_intsl = this.monthlyCreditLifePremium + this.monthlyLoanInstallment;
    
    if(propose_access_new_intsl >= maxInstallmentAllowedontrol.value)
    {
      this.LNDR_Loans_Compliant = false;
    }else{
      this.LNDR_Loans_Compliant = true;
    }
    
    if(propose_access_new_intsl >= maxNCRAllowedInstlControl.value)
    {
      this.NCR_Loans_Compliant = false;
    }else{
      this.NCR_Loans_Compliant = true;
    }

  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
