import { Component, OnInit } from '@angular/core';

import { ApiService } from 'app/ts/ApiService';
import { ActivatedRoute, Router } from '@angular/router';
import { OtpLogin, PasswordDetails, UserCredentials } from 'app/ts/models/user-credentials';
import { Authentication } from 'app/ts/models/broker-interfaces';

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { PendingApprovalLoansService } from 'app/ts/services/pending-approval-loans.service';
import { ProductSelectService } from 'app/ts/services/product-select.service';
import { MatDialog } from '@angular/material/dialog';
import { OtpComponent } from '../otp/otp.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  passwordDetails: PasswordDetails;
  showDetails: boolean;
  credentials = new OtpLogin();
  authResponse: number;
  error: string;
  staffId: string;
  productId: string;
  loanAmount: string;
  existingClientNewLoan:boolean = false;
  salaryDate: number;
  loading = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private snackbar: MatSnackBar,
    private _pendingLoansService: PendingApprovalLoansService,
    private _productSelectService: ProductSelectService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
  }


  ngOnInit() {
    this.showDetails = false;
    this.getStaffId();

    const state = history.state as { loanamount: string, salarydate: number, mobilenumber: string };
    
    if (state && state.loanamount && state.mobilenumber) {
      this.credentials.mobilenumber = state.mobilenumber;
      this.loanAmount = state.loanamount;
      this.salaryDate = state.salarydate;
      this.existingClientNewLoan = true;
      this.signIn();
    }
        
        
    
    
  }

  signIn(): void {
    this.loading = true;
    if (this.credentials.mobilenumber === undefined || this.credentials.mobilenumber.length !== 10) {
      this.openSnackBar(
        "Please provide a valid Cell Number",
        "error-snackbar"
      );
      this.loading = false;
      return;
    }
  
    this.api.generateLoginOtp(this.credentials).subscribe(
      (data: any) => {
        if(data == "User not found. Incorrect credentials.")
        {
          this.openSnackBar("User not found. Incorrect credentials.", "error-snackbar");
          this.loading = false;
          return;
        }
        var x = data;
        this.credentials.id = data;
        this.loading = false;
        this.openDialog(data);
      },
      (error: any) => {
        // Handle API error here
        if (error.error && error.error.message) {
          this.openSnackBar(error.error.message, "error-snackbar");
          this.loading = false;
        } else {
          this.openSnackBar("An error occurred. Please try again later.", "error-snackbar");
          this.loading = false;
        }
      }
    );
  }
  

  getStaffId(): void {
 
    this.route.queryParams.subscribe(params => {
      this.staffId = params.staffId;
      this.productId = params.productId;
      this.loanAmount = params.loanAmount;
    });

    // const currentURL = window.location.href;

    // // Extract the number from the URL using regular expressions
    // const match = currentURL.match(/\/(\d+)$/);

    // if (match && match[1]) {
    //   const extractedNumber = parseInt(match[1], 10); // Parse the number as an integer
    //   sessionStorage.setItem('staffId', extractedNumber.toString());
    //   console.log(`Extracted number: ${extractedNumber}`);
    // } else {
    //   console.log('Number not found in the URL.');
    // }
  }

  openDialog(id: number, invalid: boolean = false): void {
    localStorage.clear();
    const dialogRef = this.dialog.open(OtpComponent, {
      width: '350px',
      data: { otp: this.credentials.otp, OtpType: id, InvalidOtp: invalid, },
      disableClose: false
    });

    dialogRef.componentInstance.submitResendOTP.subscribe((res) => {
      this.api.generateLoginOtp(this.credentials).subscribe((data: any) => {
        var x = data;
        this.credentials.id = data;
        //this.openDialog(data);
      })
    })

    dialogRef.afterClosed().subscribe(result => {
      this.loading = true;
      this.credentials.otp = result;
      //this.isSpinnerDisplayed = true;

      if (result === undefined) return;

      if (this.authResponse != 0) {

        this.api.validateLoginOtp(this.credentials).subscribe((data: any) => {
          if (data == true) {

            sessionStorage.setItem('authenticated', 'true');
            const currentDate = new Date();
            const futureDate = new Date(currentDate.getTime() + 15 * 60 * 1000);

            this.getClientInfo(this.credentials.mobilenumber,data);

            sessionStorage.setItem('sessionExpiresAt', futureDate.toString());
            
          }
          else {
            this.error = "Could not validate the OTP";
            this.openSnackBar(
              this.error,
              "error-snackbar"
            );
            this.loading = false;
          }
        });

      }
    });
  }

  getClientInfo(mobileNumber: string , data: any) {
    this.api.GetFullClientInfo(mobileNumber).subscribe((data: any) => {
      if(data != null)
      {
        var json = JSON.stringify(data);
        sessionStorage.setItem('ClientInfo', json);
        sessionStorage.setItem('clientId', data.client.id.toString());

        this.loading = false;
        if (this.existingClientNewLoan) {
          this.router.navigate(['broker/update-client/'], { state: { data: this.existingClientNewLoan, loanAmount: Number(this.loanAmount), salaryDate: this.salaryDate } }); 
        } else {
          this.router.navigate(['broker/update-client/'] );
        }

      }else{
        this.router.navigate(['broker/update-client/']);
      }

    })
  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  trimSpaces() {
    this.credentials.mobilenumber = this.credentials.mobilenumber.trim();
  }

  closeAllPopups() {
    this.loading = false;
  }
}
