import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'app/ts/ApiService';
import { columnHeaders, Notes } from 'app/ts/models/broker-interfaces';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})


export class NotesComponent implements OnInit, AfterViewInit {

  @Input() loanId: number = 0;

  notes: Notes[];
  displayedColumns: string[];
  columns: columnHeaders[];
  dataSource = new MatTableDataSource([]);

  constructor(private api: ApiService) { }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    this.api.getNotes(this.loanId).subscribe((data: any) => {
      this.displayedColumns = [
        'id',
        'clientId',
        'loanId',
        'note',
        'createdbyId',
        'createdDate',
        'lastmodifiedbyId',
        'lastmodifiedDate'
      ];
  
      this.columns = [
        { DisplayName: 'Id', DbName: 'id' },
        { DisplayName: 'ClientId', DbName: 'clientId' },
        { DisplayName: 'LoanId', DbName: 'loanId' },
        { DisplayName: 'Note', DbName: 'note' },
        { DisplayName: 'CreatedbyId', DbName: 'createdbyId' },
        { DisplayName: 'CreatedDate', DbName: 'createdDate' },
        { DisplayName: 'LastmodifiedbyId', DbName: 'lastmodifiedbyId' },
        { DisplayName: 'LastmodifiedDate', DbName: 'lastmodifiedDate' },
        
      ]
  
      this.dataSource.data = data;
    })

    
  }

}

