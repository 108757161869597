<div class="container-fluid">
    <div class="row flex">
        <div class="card col-md-4" style="height: calc(100vh - 320px)">
            <div class="row p-1 flex">
                <h3 class="card-title"><b>About Us</b></h3>
            </div>
            <div class="card-body col-md-12">
                <span>LNDR is the leading online financial platform that offers personal financial
                    services.</span><br />
                <!-- <span><b>NCR Number:</b> NCRCP21</span><br />
                <span><b>FSP NUMBER:</b> 14740</span> -->
            </div>
        </div>
        <div class="card col-md-5" style="height: calc(100vh - 320px); margin-left:10px;">
            <div class="row p-1 flex">
                <h3 class="card-title"><b>Address</b></h3>
            </div>
            <div>
                <div class="mapouter mx-auto">
                    <div class="gmap_canvas"><iframe width="450" height="300" id="gmap_canvas" src="https://maps.google.com/maps?q=Fintech%20Campus&t=k&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br>
                        <style>
                            .mapouter {
                                position: relative;
                                text-align: right;
                                height: 450px;
                                width: 400px;
                            }
                        </style>
                        <style>
                            .gmap_canvas {
                                overflow: hidden;
                                background: none !important;
                                height: 450px;
                                width: 400px;
                            }
                        </style>
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-md-3" style="height: calc(100vh - 320px); margin-left:10px;">
            <div class="row p-1 flex">
                <h3 class="card-title"><b>Contact Information</b></h3>
            </div>
            <div class="row p-1">
                <a style="color: #39AF96 !important" href="mailto:awie@accessloan.credit">
                    Support: <i class="fa-solid fa-envelope fa-2xl"></i> awie@accessloan.credit
                </a>
            </div>
            <div class="row p-1">
                <a style="color: #39AF96 !important" href="">
                  Telephone: <i class="fa-solid fa-phone fa-2xl"></i>012 012 5222
                </a>
            </div>
        </div>

        <div class="row powered">
            <div class="card col-md-12 ml-1" style="height: max-content;">
                <div class="card-body  ">

                    <div class="row flex">
                        <div class="col-md-2 ">
                            <img style="width: 100%; height: 80px;" mat-card-image src="/assets/svg/debicheck.svg">
                        </div>
                        <div class="col-md-2 ">
                            <img style="width: 100%; height: 80px;" mat-card-image src="/assets/svg/compuscan.svg">
                        </div>
                        <div class="col-md-2 ">
                            <img style="width: 100%; height: 80px;" mat-card-image src="/assets/svg/experian.svg">
                        </div>
                        <div class="col-md-2 ">
                            <img style="width: 100%; height: 80px;" mat-card-image src="/assets/img/ncr.png">
                        </div>
                        <div class="col-md-2 ">
                            <img style="width: 100%; height: auto;" mat-card-image src="/assets/svg/sacrra.svg">
                        </div>
                        <div class="col-md-2 ">
                            <img style="width: 100%; height: 80px;" mat-card-image src="/assets/svg/letsEncrypt.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>