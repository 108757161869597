import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { SaveStaffId } from 'app/ts/models/broker-classes';
import { ApiService } from 'app/ts/ApiService';
import { BatchRecords, Staff } from 'app/ts/models/broker-interfaces';
@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss', '../../forms.shared.scss']
})
export class BatchComponent implements OnInit {
  clientId: number;
  batch_upload = new SaveStaffId();
  batchResults: BatchRecords[];
  staff: Staff[];
  selectedStaff: Staff[];
  hideStaffId: boolean;
  hideResults: boolean;
  
  countries: Record<string, string>[];
  filteredCountries: Record<string, string>[] = [];


  constructor(
    private api: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) { }

  batchUploadDetails: FormGroup;
  public inputTyped = (source: string, text: string) => console.log('SingleDemoComponent.inputTyped', source, text);
  ngOnInit() {
    this.hideStaffId = !Boolean(sessionStorage.getItem('isSuper'))
    let staffID = Number(sessionStorage.getItem('staffId'));
    this.batch_upload.staff_id = staffID;
    this.batchUploadDetails = this.formBuilder.group({
      staff_id: [staffID, []],
    });
    this.hideResults = true;

    this.api.getStaff().subscribe((data: Staff[]) => {
      this.staff = data;
      this.selectedStaff = this.staff; 
    });
    
  }

  public changeStaff(){
    sessionStorage.setItem('batchStaffId',this.batch_upload.staff_id.toString());
  }

onKey(value: string) { 
this.selectedStaff = this.search(value);

}

search(value: string) { 

  return this.staff.filter(item => 
    Object.keys(item).some(k => item[k] != null && 
    item[k].toString().toLowerCase()
    .includes(value.toLowerCase()))
    );
}

  batchResultsHandler(batchRecords: BatchRecords[]) {
    this.batchResults = batchRecords;

    this.hideResults = false;
    this.cdr.detectChanges();
  }

  change(event: { value: number; }) {
    this.batch_upload = new SaveStaffId();
  }

  download() {

  }

}
