import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  get isLoggedIn(): boolean {
    const user = sessionStorage.getItem('authenticated')
    const now = new Date();
    const expires = new Date(sessionStorage.getItem('sessionExpiresAt'))
    if(user !== null)
    {
      if(now >= expires)
      {
        return false
      }

      return true
    }
    else
    {
      return false;
    }
  }
}
