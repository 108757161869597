import { Component, EventEmitter, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PersonalDetailsComponent } from 'app/forms/broker-portal/personal-details/personal-details.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-client-update',
  templateUrl: './client-update.component.html',
  styleUrls: ['./client-update.component.scss']
})
export class ClientUpdateComponent implements OnInit {
  selectedIndex: number = 0;
  clientId: number;
  // router: any;
  route: any;
  @ViewChild('clientDetails') clientDetails: PersonalDetailsComponent;
  formDataEvent: EventEmitter<any> = new EventEmitter<any>();
  receivedData;
  updateClient: any;
  newLoan: any;
  existingClient: any;
  interestRate:any;
  notOlderThan90days:any;
  olderThan90Days:any;
  tabletGrid: boolean = false;

  // selectedIndex = 0;

  tabNameMapping = {
    0: 'personal-details',
    1: 'client-address',
    2: 'client-affordability',
    3: 'bank-details',
    4: 'employer-details',
    5: 'upload-documents'
  };
  
  constructor(private router: Router,
    private location: Location
  ) { 
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras.state?.data;
    this.updateUrl(0);
    if (data) {
      this.existingClient = data.existing;
      this.updateClient = data.return;
      this.newLoan = data.message === "new loan";
      this.interestRate = data.interest;

      if (data.message === "update client") {
        this.updateClient = true;
        this.formDataEvent = this.updateClient;
      }

      if (data.message === "new loan") {
        this.newLoan = true;
        this.handleNewLoan();
      }
    }
}

onTabChange(event: MatTabChangeEvent) {
  const tabIndex = event.index;
  this.updateUrl(tabIndex);
}

updateUrl(tabIndex: number) {
  const tabName = this.tabNameMapping[tabIndex];
  const url = `/broker/${tabName}`;
  this.location.go(url);
}

private handleNewLoan() {
    const clientInfo = JSON.parse(sessionStorage.getItem("ClientInfo"));

    if (clientInfo.documents.length > 0) {
        this.processDocuments(clientInfo);
    }
}

private processDocuments(clientInfo: any) {
    const currentDate = new Date();
    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(currentDate.getDate() - 90);

    const { notOlderThan90days, olderThan90Days } = this.checkDocumentDates(clientInfo.documents, ninetyDaysAgo);

    const activeLoans = this.getActiveLoans(clientInfo);

    if (activeLoans.length > 0) {
        this.handleActiveLoans(clientInfo, activeLoans, ninetyDaysAgo);
    } else {
        this.handleNoActiveLoans(clientInfo, ninetyDaysAgo);
    }
}

// private checkDocumentDates(documents: any[], ninetyDaysAgo: Date) {
//     this.notOlderThan90days = false;
//     this.olderThan90Days = false;

//     documents.forEach(doc => {
//         const docs = doc.filter(doc => doc.description === "bankstatement" || doc.description === "id" || doc.description === "payslip" || doc.description === "additional");
//         const docDateStr = doc.fileName.substr(11, 10); // Extract the date portion of the filename
//         const docDate = new Date(docDateStr.replace(/-/g, '/')); // Convert to Date object

//         if (docDate >= ninetyDaysAgo) {
//             this.notOlderThan90days = true;
//         } else {
//             this.olderThan90Days = true;
//         }
//     });
    
//     return { notOlderThan90days: this.notOlderThan90days, olderThan90Days: this.olderThan90Days };
// }

private checkDocumentDates(documents: any[], ninetyDaysAgo: Date) {
  this.notOlderThan90days = false;
  this.olderThan90Days = false;

  // Filter documents based on the description criteria
  const filteredDocs = documents.filter(doc => 
      doc.description === "bankstatement" || 
      doc.description === "id" || 
      doc.description === "payslip" || 
      doc.description === "additional"
  );

  filteredDocs.forEach(doc => {
      const docDateStr = doc.fileName.match(/\d{4}\/\d{2}\/\d{2}/)[0]; // Extract the date portion of the filename
      const docDate = new Date(docDateStr.replace(/\//g, '-')); // Convert to Date object

      if (docDate >= ninetyDaysAgo) {
          this.notOlderThan90days = true;
      } else {
          this.olderThan90Days = true;
      }
  });
  
  return { notOlderThan90days: this.notOlderThan90days, olderThan90Days: this.olderThan90Days };
}

private getActiveLoans(clientInfo: any) {
    return clientInfo.loans.filter(loan => 
        [600, 502, 501, 500, 400, 704, 706, 707].includes(loan.loanStatus)
    );
}

private handleActiveLoans(clientInfo: any, activeLoans: any[], ninetyDaysAgo: Date) {
    const lastActiveLoan = this.getLastActiveLoan(activeLoans);

    if (new Date(lastActiveLoan.disbursedOnDate) >= ninetyDaysAgo) {
        this.removeBankStatements(clientInfo);
    } else {
        this.clearDocuments(clientInfo);
    }
}

private handleNoActiveLoans(clientInfo: any, ninetyDaysAgo: Date) {
    this.clearDocuments(clientInfo);
}

private getLastActiveLoan(activeLoans: any[]) {
    return activeLoans.reduce((latest, loan) => {
        const loanDate = new Date(loan.disbursedOnDate);
        return loanDate > new Date(latest.disbursedOnDate) ? loan : latest;
    });
}

private removeBankStatements(clientInfo: any) {
    clientInfo.documents = clientInfo.documents.filter(doc => doc.description !== "bankstatement");
    sessionStorage.setItem("ClientInfo", JSON.stringify(clientInfo));
}

private clearDocuments(clientInfo: any) {
    clientInfo.documents = [];
    sessionStorage.setItem("ClientInfo", JSON.stringify(clientInfo));
}

  ngOnInit(): void {
    this.checkTabletGrid();
  }

  onFormSubmitted(formData: FormGroup) {
    // Pass the form data to PersonalDetailsComponent
    //this.clientDetails.receiveFormData(formData);
    this.formDataEvent.emit(formData);
  }

  public previousStep() {
    this.selectedIndex -= 1;
  }

  updateStep() {
    
    if (this.selectedIndex == 5) {
      this.reload();
    }
    else {
      this.selectedIndex += 1;
    } 
  }

  onChange() {
    this.clientId = Number(sessionStorage.getItem("clientId"));
  }

  reload() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./'], { relativeTo: this.route });
  }


  //
  selectTab(index) {
    this.selectedIndex = index; // Index of the Client Address tab
  }

  checkTabletGrid() {
    if (window.matchMedia(`(min-width: 850px)`).matches) {

      this.tabletGrid = false;
    }
    else this.tabletGrid = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkTabletGrid()
  }

}
