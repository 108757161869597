import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/ts/ApiService';
import { Products, ProductsDropDown } from 'app/ts/models/broker-interfaces';
import { LoanApplication } from 'app/ts/models/loan-classes';
import { ProductSelectService } from 'app/ts/services/product-select.service';
import { json } from 'express';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  productCategories: string[] = [];
  Products: Products[] = [];
  productSelected: string;
  loanApplication$ = this._productSelect.loanApplication;
  loanApp: LoanApplication;
  productList$ = this._productSelect.productList;

  constructor(public _productSelect: ProductSelectService) {

    var staffId = sessionStorage.getItem('staffId');
    //if(staffId == '630' || staffId == '607' || staffId == '650')
    //{
//
    //  this._productSelect.productList$.subscribe((x: any) => {
    //    if (x.length !== 0 && x !== undefined) {
    //      this.Products = [];
    //      this.Products = x;
    //    } else {
    //      
    //      
    //      this._productSelect.getStaffProductList();
    //    }
    //  });
  //
    //  this._productSelect.loanApplication$.subscribe((x: LoanApplication) => {
    //    if (x !== undefined && x !== null) {
    //      this.setProductCategories();
    //    }
    //  });
    //  
    //}

  }

  ngOnInit() {

    this.setProductCategories();
  }

  onProductChange(): void {
    this._productSelect.updateSelectedProductCategory(this.productSelected);
  }


  setProductCategories() {
    this.loanApp = JSON.parse(localStorage.getItem("loanAppState"));
    this.Products = JSON.parse(localStorage.getItem("productList"));
    this.productCategories = [];
    this.productSelected = this.loanApp.selectedProduct;

    function onlyUniqueCategories(value, index, self) {
      return self.findIndex(v => v.productCategory === value.productCategory) === index;
    }

    var uniqueCategories = this.Products.filter(onlyUniqueCategories);
    
    uniqueCategories.forEach(element => {
      this.productCategories.push(element.productCategory);
    });
  }
}
