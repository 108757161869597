import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'app/ts/ApiService';
import { BatchRecords } from 'app/ts/models/broker-interfaces';

class batchFileRequest {
    BatchFile: string;
    token: string;
    staffId: number
}


@Component({
    selector: 'app-batch-upload-document',
    templateUrl: './batch-upload-document.component.html',
    styleUrls: ['./batch-upload-document.component.scss', '../../forms.shared.scss']
})
export class BatchUploadDocumentComponent {
    @ViewChild("fileDropBatchFile", { static: false }) fileDropBatchFile: ElementRef;
    batchFiles: any[] = [];
    progress = 0;
    form: FormGroup;
    //batchrecords: BatchRecords[];
    isLoading = false;

    @Output() batchRecords: EventEmitter<any> = new EventEmitter<any>();

    constructor(private api: ApiService, public formBuilder: FormBuilder) {
        this.form = this.formBuilder.group({
            file: []
        })
    }

    onBatchFileDropped($event) {
        this.prepareBatchFilesList($event);
    }

    batchFileBrowseHandler(files) {
        this.prepareBatchFilesList(files);
    }

    // toggleLoading = () => {
    //     this.isLoading = true;
    // }

    /**
   * Delete file from files list
   * @param index (File index)
   */
    deleteBatchFile(index: number) {
        if (this.batchFiles[index].progress < 100) {
            return;
        }
        this.batchFiles.splice(index, 1);
    }

    /**
   * Simulate the upload process
   */
    uploadFilesSimulator(index: number, fileArray: any[]) {
        setTimeout(() => {
            if (index === fileArray.length) {
                return;
            } else {
                const progressInterval = setInterval(() => {
                    if (fileArray[index].progress === 100) {
                        clearInterval(progressInterval);
                        this.uploadFilesSimulator(index + 1, fileArray);
                    } else {
                        fileArray[index].progress += 5;
                    }
                }, 200);
            }
        }, 1000);
    }

    /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
    prepareBatchFilesList(files: Array<any>) {
        for (const item of files) {
            // var formData: any = new FormData();
            // formData.append("batch", item);

            // this.api.uploadBatchDocument(formData).subscribe((data: BatchRecords[])=>{
            //     this.dataSource = data;
            // });

            var staffId = Number(sessionStorage.getItem("batchStaffId"));
            this.isLoading = true;

            let newRequest: batchFileRequest

            this.convertToBase64(item).then(base64String => {
                newRequest = {
                    BatchFile: base64String,
                    token: 'erkjhg39847fyui3hf943yf83jfeesdhf98324f923iwejfij9%^#$^%$^%%$&%&*^$&$HTF%R&^HT&^%RG*^Rhrh9%^BR954rv8b%Rrv756',
                    staffId: staffId
                }

                this.api.uploadBatchDocument(newRequest).subscribe
                    (
                        data => {
                            //this.batchRecords.push(data);
                            this.isLoading = false;
                            this.batchRecords.emit(data);
                        }
                    );
            });


            item.progress = 0;
            this.batchFiles.push(item);
        }
        this.fileDropBatchFile.nativeElement.value = "";
        this.uploadFilesSimulator(0, this.batchFiles);
    }

    public convertToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = reader.result as string;
                let strippedBase64String = base64String.split(";")[1];
                strippedBase64String = strippedBase64String.replace("base64,", "");
                resolve(strippedBase64String);
            };
            reader.onerror = error => {
                reject(error);
            };
        });
    }


    /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) {
            return "0 Bytes";
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    save(): void {

    }

}