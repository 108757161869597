<form [formGroup]="renewPass" novalidate>
    <div class="row">
        <div class="col-12">
            <h3>Password Expired</h3>
            <h5>Please reset your password</h5>
        </div>
        <div class="col-12">
            <mat-form-field appearance="standard">
                <mat-label>Password</mat-label>
                <input matInput #password name="password" required type="{{showDetails ? 'text' : 'password'}}" formControlName="password">
                <mat-icon matSuffix (click)="showDetails = !showDetails">{{showDetails ? 'visibility_off' : 'visibility'}}
                </mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="standard">
                <mat-label>Confirm Password</mat-label>
                <input matInput #password name="password" required type="{{showDetails ? 'text' : 'password'}}" formControlName="repeatPassword">
                <mat-icon matSuffix (click)="showDetails = !showDetails">{{showDetails ? 'visibility_off' : 'visibility'}}
                </mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 py-0 my-1">
            <button type="submit" class="button renew-button flex card card-body mat-textbox" (click)="renew()">
              <h3 class="renew-button-text mx-auto">
                  <b>Renew Password</b>
              </h3>
          </button>
        </div>

    </div>
</form>