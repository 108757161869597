import { Component, OnInit, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

declare interface menuItemInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  description: string;
}

//when class is bg-blue,  add white icon. 
export const MENUCARDS: menuItemInfo[] = [
  { path: '/investments', title: 'Investments', icon: '', class: '', description: 'Looking to save for your retirement? Thanks to Aluma you will alwys get more out of your investment.' },

];

@Component({
  selector: 'app-broker-dashboard',
  templateUrl: './broker-dashboard.component.html',
  styleUrls: ['./broker-dashboard.component.scss']
})
export class BrokerDashboardComponent implements OnInit {
  menuItemsAluma: any[];
  staffId: number;
  isLoading: boolean;
  interval;
  constructor(private router: Router, private route: ActivatedRoute) { }
 

  ngOnInit() {

    //Set this to true to show loading screen
    this.isLoading = true;
    this.menuItemsAluma = MENUCARDS.filter(menuCard => menuCard);

    this.interval = setInterval(() => {
      this.isLoading = false;
    }, 4000);

    // this.route.queryParams.subscribe(params => {
    //   this.staffId = params['staffId'];
    // })
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (sessionStorage.getItem("loadingMain") == "false") {
      this.isLoading = false;
    }
  }

}
