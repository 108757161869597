<div class="container-fluid">
    <div class="row">
        <!-- <div *ngIf="isLoading" class="col-lg-12 col-md-12 col-sm-12 full-screen">
        <div class=" card" class="loading_card">
            <div class="card-body">
              <img class="icon_margin logo_center" *ngIf="isLoading" src="/assets/img/uloans/ubank-logo.svg">
              <br />
                <h3 class="loading_font"><strong>Loading...</strong></h3>
            </div>
        </div>
      </div> -->
        <div class="col-lg-6 col-md-6 col-sm-6">
            <div class=" card">
                <div class="card-header">
                    <h3 class="card-title"><b>Credit Check</b></h3>
                </div>
                <div *ngIf="!isLoading" class="card-body">
                    <app-bar-chart-x></app-bar-chart-x>
                </div>
                <div *ngIf="isLoading" class="card-body">
                    <div class="loading_icon">
                        <img class="icon_margin" style="height: 150px;" src="/assets/img/uloans/loading_green.svg">
                    </div>
                </div>
                <div class="card-footer">
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
            <div class=" card">
                <div class="card-header">
                    <h3 class="card-title"><b>Sales</b></h3>
                </div>
                <div *ngIf="isLoading" class="card-body">
                    <div class="loading_icon">
                        <img class="icon_margin" style="height: 150px;" src="/assets/img/uloans/loading_green.svg">
                    </div>
                </div>
                <div *ngIf="!isLoading" class="card-body">
                    <app-line-chart></app-line-chart>
                </div>
                <div class="card-footer">
                </div>
            </div>
        </div>
    </div>
</div>