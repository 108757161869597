import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { BatchUploadDocumentComponent } from './batch-upload-document/batch-upload-document.component';
import { BatchListComponent } from 'app/components/broker-portal/batch-list/batch-list.component';
import { BatchComponent } from './batch-upload/batch.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { EmployerDetailsComponent } from './employer-details/employer-details.component';
import { BankDetailsComponent } from './bank-details/bank-details.component';
import { BrokerReportsComponent } from 'app/forms/broker-portal/broker-reports/broker-reports.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ChartsModule } from 'ng2-charts';
import { MatDialogModule } from '@angular/material/dialog';
import { ChartistModule } from "ng-chartist";
import { ClientCaptureFinalComponent } from './client-capture-final/client-capture-final.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { MatSortModule } from '@angular/material/sort';
import { ConsentComponent } from 'app/forms/broker-portal/consent/consent.component';
import { ProductSelectComponent } from './product-select/product-select.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CurrencyFormatterModule } from 'app/components/currency-formatter/currency-formatter.component.module';
import { MatSliderModule } from '@angular/material/slider';
import { LoadLoansComponent } from './load-loans/load-loans.component';
import { FindClientComponent } from './find-client/find-client.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CreditCheckComponent } from './credit-check/credit-check.component';
import { ProductsComponent } from './products/products.component';
import { LoanApplicationComponent } from './loan-application/loan-application.component';
import { MatListModule } from '@angular/material/list';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatIconModule,
    MatStepperModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatTableExporterModule,
    ChartsModule,
    MatDialogModule,
    ChartistModule,
    CurrencyFormatterModule,
    TabsModule.forRoot(),
    MatSliderModule,
    FileUploadModule,
    MatExpansionModule,
    MatListModule,
  ],
  declarations: [
    EmployerDetailsComponent,
    BankDetailsComponent,
    PersonalDetailsComponent,
    ConsentComponent,
    UploadDocumentsComponent,
    BrokerReportsComponent,
    BatchComponent,
    BatchUploadDocumentComponent,
    BatchListComponent,
    ClientCaptureFinalComponent,
    AboutUsComponent,
    ProductSelectComponent,
    LoadLoansComponent,
    FindClientComponent,
    CreditCheckComponent,
    LoadLoansComponent,
    ProductSelectComponent,
    ProductsComponent,
  

  ],
  exports: [
    EmployerDetailsComponent,
    BankDetailsComponent,
    ConsentComponent,
    PersonalDetailsComponent,
    UploadDocumentsComponent,
    BrokerReportsComponent,
    BatchComponent,
    BatchUploadDocumentComponent,
    BatchListComponent,
    ClientCaptureFinalComponent,
    ProductSelectComponent,
    FileUploadModule,
    LoadLoansComponent,
    FindClientComponent,
    CreditCheckComponent,
    LoadLoansComponent,
    ProductSelectComponent,
    ProductsComponent,
  ],
  providers: []

})


export class BrokerFormsModule { }
