import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ValidatorFn, AbstractControl } from '@angular/forms';
import { SaveClientForCallback,  BankingDetails, EmployerDetails, Consent } from 'app/ts/models/broker-classes';
import { Router } from '@angular/router';
import { ApiService } from 'app/ts/ApiService';
import { FindClient } from 'app/ts/models/broker-interfaces';
  
@Component({
  selector: 'app-find-client',
  templateUrl: './find-client.component.html',
  styleUrls: ['./find-client.component.scss', '../../forms.shared.scss']
})
export class FindClientComponent implements OnInit {

  searchIdNumber: string;
  clientFind = {} as FindClient;
  bankingDetails = new BankingDetails();
  employerDetails = new EmployerDetails();
  consent = new Consent();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
  ) {
    this.searchClient = this.formBuilder.group({
      idNumber: ['', [Validators.required]],
    });
  }

  searchClient: FormGroup;

  ngOnInit(): void {
  }

  findClient() {
    this.searchIdNumber = this.searchClient.controls['idNumber'].value;
    sessionStorage.setItem('searchClientID', this.searchIdNumber);
    sessionStorage.setItem('searchBankDetails', '1');
    sessionStorage.setItem('searchEmployerDetails', '1');

    this.api.getClient(this.searchIdNumber).subscribe((response: FindClient) => {
      this.clientFind = response;

      sessionStorage.setItem('ClientResult', JSON.stringify(this.clientFind));
      sessionStorage.setItem('clientId', this.clientFind.ClientId.toString());

      //var bankDetailsFind = sessionStorage.getItem('searchBankDetails');
      //var clientId = sessionStorage.getItem('clientId');

      this.api.getBankDetails(this.clientFind.ClientId.toString()).subscribe((response: BankingDetails) => {
        this.bankingDetails = response;

        sessionStorage.setItem('BankDetailsResult', JSON.stringify(this.bankingDetails));

        this.api.getEmployerDetails(this.clientFind.ClientId.toString()).subscribe((response: EmployerDetails) => {
          this.employerDetails = response;
  
          sessionStorage.setItem('EmployerDetailsResult', JSON.stringify(this.employerDetails));

          this.api.getConsent(this.clientFind.ClientId.toString()).subscribe((response: Consent) => {
            this.consent = response;
    
            sessionStorage.setItem('ConsentResult', JSON.stringify(this.consent));

            this.api.getAcknowledgements(this.clientFind.ClientId.toString()).subscribe((response: Consent) => {
              this.consent = response;
      
              sessionStorage.setItem('AcknowledgementResult', JSON.stringify(this.consent));
    
              //this.router.navigate(['broker/client-capture']);
              this.router.navigate(['broker/client-capture', this.clientFind.ClientId]);
      
            });
    
          });
  
        });

      });

    });
    
  }

}
