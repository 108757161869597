<div class="container-fluid">
    <div class="form-position">
        <form [formGroup]="batchUploadDetails" novalidate>
            <div class="card primary-card" style="height: max-content;">
                    <div class="row p-4">
                        <h3 class="card-title ml-3"><b>Batch</b> Uploader</h3>
                    </div>
                    <div class="card-body col-md-4 ml-3" [hidden]="hideStaffId">

                        <mat-form-field appearance="fill">
                            <mat-label class="mat-label-margin">Staff</mat-label>
                            <mat-select (selectionChange)="changeStaff()" [(ngModel)]="batch_upload.staff_id" formControlName="staff_id">
                                <mat-form-field appearance="fill">
                                    <mat-icon matPrefix><i class="fa fa-search"></i></mat-icon>
                                    <input matInput (keyup)="onKey($event.target.value)">
                                </mat-form-field>
                                <mat-option *ngFor="let employee of selectedStaff" [value]="employee.id">
                                    {{employee.staffName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="card-body col-md-12">
                        <app-batch-upload-document (batchRecords)="batchResultsHandler($event)">
                        </app-batch-upload-document>
                    </div>
            </div>
            <div class="card primary-card" style="height: max-content;" [hidden]="hideResults">
                <div>
                    <div class="row p-4 pb-0">
                        <h3 class="card-title ml-4"><b>Batch</b> Results</h3>
                    </div>
                    <app-batch-list [batchResults]=batchResults></app-batch-list>
                </div>
            </div>
        </form>
    </div>
</div>