<div class="logo sidebar-logo-backgroud">
    <a class="simple-text">
        <div class="logo-img">
            <!-- <img src="/assets/svg/lndr-logo-reverse.svg" /> -->
        </div>
    </a>
</div>
<div class="sidebar-wrapper">
    <div *ngIf="isMobileMenu()">
        <form class="navbar-form">
            <span class="bmd-form-group">
                <div class="input-group no-border">
                    <input type="text" value="" class="form-control" placeholder="Search...">
                    <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                        <i class="material-icons">search</i>
                        <div class="ripple-container"></div>
                    </button>
                </div>
            </span>
        </form>
    </div>
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item " [ngStyle]="{'display': (menuItem.isVisible) ? 'block': 'none'}">

            <div style="width:75%; position: relative;">
                <a class="nav-link text-left" [routerLink]="(menuItem.title == 'Client') ? [] : [menuItem.path]" (click)="toggleClient(menuItem)" [ngStyle]="{'margin-left': (menuItem.subItem) ? '40px': '0px'}">
                    <p><i height="40px" width="40px" class="material-icons" >{{menuItem.icon}}</i>{{menuItem.title}}
                        <span *ngIf="menuItem.title == 'Pending Approval'">({{loanCount}})
                    </span>
                    <span class="badge badge-danger" *ngIf="menuItem.title == 'Loans' && pendingLoans">1</span>
                    </p>
                </a>
            </div>


            <div *ngIf="menuItem.title == 'Pending Approval'" style="width:5%;position:relative;top:20px;">
                <i (click)="refreshPendingList()" *ngIf="menuItem.title == 'Pending Approval'" height="40px" width="40px" style="cursor:pointer;" class="material-icons">refresh</i>
            </div>

        </li>

        <!-- <li routerLinkActive="active" *ngFor="let menuItem of menuItems" (click)="toggleDetails(p1)" class="{{menuItem.class}} nav-item">
          <a class="nav-link" [routerLink]="[menuItem.path]">
              <p><i height="40px" width="40px" class="material-icons">{{menuItem.icon}}</i>{{menuItem.title}}</p>
              <span *ngIf="p1.path !== ''" menuClose (click)="openPage(p1)">{{p1.title}}</span>
              <mat-icon float-right *ngIf="p1.subPages != null" [name]="p1.icon" item-end></mat-icon>
              <div *ngIf="p1.showDetails &&p1.subPages != null">
                <ul>
                <li>
                  <span style="border-bottom: 1px solid #1f1c1c;background: #dcd5d5" ion-item *ngFor="let subP of p1.subPages">
                    <span (click)="openPage(subP)"  menuClose>{{subP.title}}</span>
                  </span>
                </li>
              </ul>
              </div>
          </a>
      </li> -->
    </ul>


    <div class="powered">
        <div>
            <div class="row p-1 flex">
                <!-- <h2 class="poweredHeader">Powered by </h2> -->
                <img mat-card-image class="poweredImage" src="/assets/img/lndr/powered by lndr_logo_white_210901.svg">
                <mat-toolbar class="version">
                    <span>Version: {{version}}</span>
                </mat-toolbar>
            </div>
        </div>
    </div>
</div>